import { SgvJson } from "@financial/arch";
import { SingleRequisitionStatus } from "@financial/domain";

export class FlasksChart {
  constructor(
    public readonly productName: string = null,
    public readonly amount: number = 0,
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(data: any): FlasksChart {
    return data ? SgvJson.from.simple(data, FlasksChart, {
      status: data.status ? SingleRequisitionStatus.get(data.status) : null
    }) : null;
  }

  get name(): string {
    return this.productName;
  }

  get value(): number {
    return this.amount;
  }
}