import { ItemByClient } from './../../../../../../../libs/domain/src/lib/invoices/items-by-client/item-by-client';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BillableRepository } from 'libs/domain/src/lib/billables';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-clients-with-items',
  templateUrl: './clients-with-items.component.html',
  styleUrls: ['./clients-with-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientsWithItemsComponent implements OnInit {

  hasItems = true;

  private _items: ItemByClient[] = [];

  constructor(
    private repository: BillableRepository,
    private router: Router,
    private ref: ChangeDetectorRef
  ) { }

  get items() {
    return this._items;
  }

  async ngOnInit() {
    this._items = await lastValueFrom(this.repository.findAllGroupByClient());
    this.hasItems = this.items.length > 0;
    this.ref.markForCheck();
  }

  async showBillables(id: string) {
    await this.router.navigate([`/billables/clients/${id}`], {
      replaceUrl: false,
    });
  }

  trackByFn(index: any, entity: ItemByClient): ItemByClient {
    return entity;
  }

}
