import { DomainEntity, SgvId, SgvJson } from '@financial/arch';
import { EceosOperatable } from '../eceos-operatables';

export class ServiceSummary implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    public readonly name = '',
    public readonly code = '',
    public readonly value = 0
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): ServiceSummary {
    return json ? SgvJson.from.simple(json, ServiceSummary) : null;
  }
}

export class Service implements DomainEntity {
  public eceosOperatableId: string = '';
  constructor(
    readonly id: string = SgvId.gen(),
    public name: string = '',
    public code: string = '',
    public value: number = 0,
    public eceosOperatable: EceosOperatable = null,
    public readonly version = 0,
    public readonly isActive = true
  ) {
    this.eceosOperatableId = eceosOperatable?.id ?? null;
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): Service {
    return json ? SgvJson.from.simple(json, Service) : null;
  }
}
