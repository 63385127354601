import { JsonEntity } from '@financial/arch';

export const REGEX_EXP_VALID_MAIL = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export class MailContact implements JsonEntity {
  constructor(public value: string) {}

  get isValid() {
    return this.value && this.value !== '' && this.value.match(REGEX_EXP_VALID_MAIL);
  }

  toJson(): any {
    return {
      type: 'EMAIL',
      detail: this.value
    };
  }

  static fromJson(json: any): MailContact {
    return json && json.detail ? new MailContact(json.detail.trim()) : null;
  }
}
