import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InvoiceDetails } from './../../../../../../../../libs/domain/src/lib/invoices/invoice-details';

@Component({
  selector: 'app-invoice-details-dialog-billable-content',
  templateUrl: './invoice-details-dialog-billable-content.component.html',
  styleUrls: ['./invoice-details-dialog-billable-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceDetailsDialogBillableContentComponent {

  @Input() entity: InvoiceDetails

}
