import { DomainEntity, SgvId, SgvJson } from '@financial/arch';
import { NumberMask } from '@financial/common-utils';
import { Address } from '../core/address';
import {
  Documents,
  LegalEntityDocuments,
  NaturalPersonDocuments
} from '../core/documents';
import { MailContact } from '../core/mail-contact';
import { PhoneContact } from '../core/phone-contact';
import { EceosClient } from '../eceos-clients';

export class ClientSummary implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    public readonly name = '',
    public readonly code = '',
    public readonly cnp = '',
    public readonly emails: MailContact[] = [],
    public readonly phones: PhoneContact[] = [],
    public readonly cliforCode: string = '',
    public readonly corporateName: string = ''
  ) { }

  get phone(): string {
    return this.phones.length > 0 ? this.phones[this.phones.length - 1].formattedValue : null;
  }

  get email(): string {
    return this.emails.length > 0 ? this.emails[this.emails.length - 1].value : null;
  }

  get isLegalEntity(): boolean {
    return this.cnp.length > 11;
  }

  get formattedName(): string {
    return this.isLegalEntity 
      ? `${this.name} - ${this.corporateName}` 
      : this.name;
  }

  get maskedCnp(): string {
    return this.isLegalEntity 
      ? LegalEntityDocuments.MASK.apply(this.cnp) 
      : NaturalPersonDocuments.MASK.apply(this.cnp);
  }
  
  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): ClientSummary {
    return json ? SgvJson.from.simple(json, ClientSummary, {
      emails: json.contacts
        ? json.contacts
          .filter((contact: any) => contact && contact.detail && contact.detail !== '' && contact.type === 'EMAIL')
          .map((contact: any) => new MailContact(contact.detail.trim()))
        : [],
      phones: json.contacts
        ? json.contacts
          .filter((contact: any) => contact && contact.detail && contact.detail !== '' && contact.type === 'TELEFONE')
          .map((contact: any) => new PhoneContact(contact.detail.replace(/\D/g, '').trim()))
        : []
    }) : null
  }
}

export class Client implements DomainEntity {
  public eceosClientId: string = '';
  constructor(
    readonly id: string = SgvId.gen(),
    public name: string = '',
    public code: string = '',
    public documents: Documents = new NaturalPersonDocuments(),
    public address: Address = new Address(),
    public emails: MailContact[] = [],
    public phones: PhoneContact[] = [],
    public eceosClient: EceosClient = null,
    public readonly version = 0,
    public readonly isActive = true,
    public cliforCode: string = '',
    public generateBankSlip: boolean = true
  ) { 
    this.eceosClientId = eceosClient?.id ?? null;
  }

  get formattedName(): string {
    return this.isLegalEntity
      ? `${this.name} - ${this.documents.corporateName}` 
      : this.name;
  }

  get isNaturalPerson(): boolean {
    return this.documents && this.documents instanceof NaturalPersonDocuments;
  }

  get isLegalEntity(): boolean {
    return this.documents && this.documents instanceof LegalEntityDocuments;
  }

  lastPhoneNumber(phones: PhoneContact[]) {
    return phones.length > 0 ? phones[phones.length - 1].formattedValue : null;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      address: this.address ? this.address.toJson() : null,
      documents: this.documents ? this.documents.toJson() : null,
      contacts: [
        ...this.emails
          .map(c => c.value)
          .filter(c => c && c !== '')
          .map(c => ({
            type: 'EMAIL',
            detail: c
          })),
        ...this.phones
          .map(c => c.value)
          .filter(c => c && c !== '')
          .map(c => ({
            type: 'TELEFONE',
            detail: c
          }))
      ]
    });
  }

  static naturalPerson(): Client {
    const entity = new Client();
    entity.documents = new NaturalPersonDocuments();
    return entity;
  }

  static legalEntity(): Client {
    const entity = new Client();
    entity.documents = new LegalEntityDocuments();
    return entity;
  }

  static fromJson(json: any): Client {
    return SgvJson.from.simple(json, Client, {
      address: json.address ? Address.fromJson(json.address) : null,
      documents: json.documents ? Documents.fromJson(json.documents) : null,
      registrationDate: json.registrationDate ? new Date(json.registrationDate) : null,
      birth: json.birth ? new Date(json.birth) : null,
      emails: json.contacts
        ? json.contacts
          .filter((c: any) => c && c.detail && c.detail !== '')
          .filter((c: any) => c.type === 'EMAIL' || c.type === 'EMAIL_NFE')
          .map((c: any) => new MailContact(c.detail.trim()))
        : [],
      phones: json.contacts
        ? json.contacts
          .filter((c: any) => c && c.detail && c.detail !== '')
          .filter((c: any) => c.type === 'TELEFONE' || c.type === 'CELULAR')
          .map((c: any) => new PhoneContact(c.detail.replace(/\D/g, '').trim()))
        : []
    });
  }
}
