import { ClientsWithOverdueInvoices } from './clients-with-overdue-or-partially-paid-invoices/clients-with-overdue-or-partially-paid-invoices.component';
import { ClientsWithItemsComponent } from './clients-with-items/clients-with-items.component';
import { ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ChangeAlertGuard } from '@financial/arch';
import { FinancialGuard } from '../../core/guards/financial/financial.guard';
import { InvoiceActionsComponent } from './invoice-actions/invoice-actions.component';
import { InvoicesComponent } from './invoices.component';
import { ItemsClientComponent } from './items-client/items-client.component';
import { InvoicesReleasedTableComponent } from './invoices-released-table/invoices-released-table.component';
import { OverdueInvoicesTableComponent } from './overdue-invoices-table/overdue-invoices-table.component';
import { InvoicesExportTableComponent } from './invoices-export-table/invoices-export-table.component';

const BASE_PATH = 'invoices';
const BASE_BILLABLES_PATH = 'billables'
const BASE_CLIENTS_PATH = 'clients'
const BASE_ID_PATH = ':id'

export const InvoicesRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild([
  { path: BASE_PATH, redirectTo: `${BASE_PATH}/`, pathMatch: 'full' },
  {
    path: `${BASE_PATH}/released`,
    component: InvoicesReleasedTableComponent,
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    canActivate: [FinancialGuard],
    canDeactivate: [ChangeAlertGuard]
  },
  {
    path: `${BASE_PATH}/export`,
    component: InvoicesExportTableComponent,
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    canActivate: [FinancialGuard],
    canDeactivate: [ChangeAlertGuard]
  },
  {
    path: `${BASE_PATH}/${BASE_CLIENTS_PATH}`,
    component: ClientsWithOverdueInvoices,
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    canActivate: [FinancialGuard],
    canDeactivate: [ChangeAlertGuard]
  },
  {
    path: `${BASE_PATH}/${BASE_CLIENTS_PATH}/${BASE_ID_PATH}`,
    component: OverdueInvoicesTableComponent,
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    canActivate: [FinancialGuard],
    canDeactivate: [ChangeAlertGuard]
  },
  {
    path: `${BASE_BILLABLES_PATH}/${BASE_CLIENTS_PATH}`,
    component: ClientsWithItemsComponent,
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    canActivate: [FinancialGuard],
    canDeactivate: [ChangeAlertGuard]
  },
  {
    path: `${BASE_BILLABLES_PATH}/${BASE_CLIENTS_PATH}/${BASE_ID_PATH}`,
    component: ItemsClientComponent,
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    canActivate: [FinancialGuard],
    canDeactivate: [ChangeAlertGuard]
  },
  {
    path: `${BASE_PATH}/${BASE_ID_PATH}`,
    component: InvoicesComponent,
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    canActivate: [FinancialGuard],
    canDeactivate: [ChangeAlertGuard]
  },
  {
    path: `${BASE_PATH}/${BASE_ID_PATH}/actions`,
    component: InvoiceActionsComponent,
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    canActivate: [FinancialGuard],
    canDeactivate: [ChangeAlertGuard]
  }
]);
