export class AnalysisStatus {

  public static PROCESSED = new AnalysisStatus("PROCESSED");
  public static NOT_PROCESSED = new AnalysisStatus("NOT_PROCESSED");
  public static NOT_REPROCESSED = new AnalysisStatus("REPROCESS");
  public static REPROCESSED = new AnalysisStatus("REPROCESSED");

  public constructor(
    public readonly name: string
  ){}

  public static values(): AnalysisStatus[] {
    return [
      AnalysisStatus.PROCESSED,
      AnalysisStatus.NOT_PROCESSED,
      AnalysisStatus.NOT_REPROCESSED,
      AnalysisStatus.REPROCESSED
    ];
  }

  public static get(name: string): AnalysisStatus {
    const analysisStatus = AnalysisStatus.values().find(v => v.name === name);
    if(!analysisStatus) {
      const err = new Error(`${name} Status is not mapped!`);
      console.error(err);
      throw err;
    }
    return analysisStatus;
  }
}
