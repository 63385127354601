import { SgvJson } from '@financial/arch';
import { InvoiceStatus } from '../../../invoices/invoice-status';

export class InvoicesChart {

  constructor(
    public readonly status: InvoiceStatus = InvoiceStatus.OPEN,
    public readonly value: number = 0,
    public readonly percentage: number = 0,
  ) { }

  get name(): string {
    return this.status.label;
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(data: any): InvoicesChart {
    return data ? SgvJson.from.simple(data, InvoicesChart, {
      status: data.status ? InvoiceStatus.get(data.status) : null
    }) : null;
  }
}
