import { Injectable } from '@angular/core';
import { Actives, ApiService, Filter, InterceptorConfig, PageData, RepositoryImpl, RequestMetadata, RequestMetadataUtils, SortOrder, UrlSearchParamsBuilder } from '@financial/arch';
import { retryExceptOnClientError } from '@financial/common-utils';
import { map, Observable, retryWhen } from 'rxjs';
import { ItemByClient } from './../invoices/items-by-client/item-by-client';
import { Billable } from './billable';

@Injectable({ providedIn: 'root' })
export class BillableRepository extends RepositoryImpl<Billable, Billable> {
  constructor(api: ApiService) {
    super(api.root.path('billables'), Billable, Billable);
  }

  findAllGroupByClient(metadata: RequestMetadata = { autoCatch: InterceptorConfig.NO_INTERCEPT }): Observable<ItemByClient[]> {
    return this.api
      .path('clients')
      .getJson<any[]>({ metadata: metadata })
      .pipe(
        map((r) => r.map((j: any) => ItemByClient.fromJson(j))),
        retryWhen(retryExceptOnClientError())
      )
  }

  size(
    query: string = '',
    filters: Filter[] = [],
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.NO_INTERCEPT }): Observable<number> {
    const search = new UrlSearchParamsBuilder()
      .query(query)
      .filters(filters)
      .build()
    return this.api
      .path("size")
      .getJson({ params: search, metadata: metadata });
  }

  override page(
    pageData: PageData,
    query: string = '',
    filters: Filter[] = [],
    sortOrders: SortOrder[] = [],
    actives = Actives.TRUE,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<Billable[]> {
    const search = new UrlSearchParamsBuilder()
      .page(pageData)
      .query(query)
      .filters(filters)
      .sortOrders(sortOrders)
      .actives(actives)
      .build();
    return this.api
      .getJson<any[]>({
        params: search,
        metadata: RequestMetadataUtils.merge(metadata, this.metadata)
      })
      .pipe(
        map((billables) => billables.map((billable: any) => Billable.fromJson(billable))),
        retryWhen(retryExceptOnClientError())
      );
  }
}
