<fieldset>
	<legend>Cliente</legend>
	<section class="inline-infos">
		<mat-form-field thin>
			<mat-label>Nome Fantasia</mat-label>
			<input matInput [value]="entity.client.name" readonly />
		</mat-form-field>
	</section>
	<section *ngIf="entity.client.isLegalEntity" class="inline-infos">
		<mat-form-field thin>
			<mat-label>Razão Social</mat-label>
			<input matInput [value]="entity.client.name" readonly />
		</mat-form-field>
	</section>
	<section class="inline-infos">
		<mat-form-field *ngIf="entity.client.isNaturalPerson" thin>
			<mat-label>CPF</mat-label>
			<input matInput [value]="entity.client.documents.maskedCnp" readonly />
		</mat-form-field>
		<mat-form-field *ngIf="entity.client.isLegalEntity" class="short-field" thin>
			<mat-label>CNPJ</mat-label>
			<input matInput [value]="entity.client.documents.maskedCnp" readonly />
		</mat-form-field>
		<mat-form-field *ngIf="entity.client.isLegalEntity" class="short-field" thin>
			<mat-label>Inscrição</mat-label>
			<input matInput [value]="ie" readonly />
		</mat-form-field>
		<mat-form-field *ngIf="phoneNumber" thin>
			<mat-label>Telefone</mat-label>
			<input matInput [value]="phoneNumber" readonly />
		</mat-form-field>
	</section>
	<section class="inline-infos">
		<mat-form-field thin>
			<mat-label>Endereço</mat-label>
			<input matInput [value]="entity.client.address.street" readonly />
		</mat-form-field>
		<mat-form-field class="short-field" thin>
			<mat-label>Número</mat-label>
			<input matInput [value]="entity.client.address.number" readonly />
		</mat-form-field>
	</section>
	<section class="inline-infos">
		<mat-form-field class="short-field" thin>
			<mat-label>CEP</mat-label>
			<input matInput [value]="entity.client.address.zipCode" readonly />
		</mat-form-field>
		<mat-form-field thin>
			<mat-label>Cidade</mat-label>
			<input matInput [value]="entity.client.address.city" readonly />
		</mat-form-field>
		<mat-form-field class="short-field" thin>
			<mat-label>Estado</mat-label>
			<input matInput [value]="entity.client.address.uf" readonly />
		</mat-form-field>
	</section>
</fieldset>