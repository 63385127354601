import { EceosCategory } from 'libs/domain/src/lib/eceos-categories/eceos-category';
import { DomainEntity, SgvId, SgvJson } from "@financial/arch";
import { Client, ClientSummary } from "../clients";
import { Item } from "./item";
import { InvoiceStatus } from "./invoice-status";
import { EceosBankSlip, EceosBankSlipStatus } from './eceosBankSlip';
import { NumberMask } from '@financial/common-utils';

export class InvoiceSummary implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    public readonly client: ClientSummary = null,
    public readonly recipient = '',
    public readonly date: Date = null,
    public readonly expiration: Date = null,
    public readonly total: number = 0,
    public readonly status: InvoiceStatus = InvoiceStatus.PENDING,
    public readonly items: Item[] = []
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this, {
      client: this.client ? this.client.toJson() : null,
      items: SgvJson.to.array(this.items),
      invoiceStatus: this.status.name
    })
  }

  static fromJson(json: any): InvoiceSummary {
    return SgvJson.from.simple(json, InvoiceSummary, {
      client: json.client ? ClientSummary.fromJson(json.client) : null,
      items: json.items ? SgvJson.from.array(json.items, Item.fromJson) : null,
      expiration: json.expiration ? new Date(json.expiration) : null,
      status: json.status ? InvoiceStatus.get(json.status) : null
    })
  }

}

export class Invoice implements DomainEntity {
  constructor(
    readonly id: string = SgvId.gen(),
    public client: ClientSummary = new ClientSummary(),
    public recipient: string = "",
    public reference: Date = null,
    public date: Date = null,
    public items: Item[] = [],
    public expiration: Date = null,
    public number: number = 0,
    public total: number = 0,
    public name: string = "",
    public status: InvoiceStatus = InvoiceStatus.PENDING,
    public readonly version = 0,
    public readonly isActive = true,
    public eceosCategory: EceosCategory = null,
    public eceosBankSlip: EceosBankSlip = null
  ) { }

  get clientName(): string {
    return this.client.formattedName;
  }

  get statusLabel(): string {
    return this.status.label;
  }

  get eceosBankSlipStatusLabel(): string {
    return this.eceosBankSlip.status.label
  }

  get cnp(): string {
    return this.client.cnp;
  }

  get cnpMask(): string {
    return this.cnp.length > 11 ? '99.999.999/9999-99' : '999.999.999-99';
  }

  get formattedCnp(): string {
    return new NumberMask(this.cnpMask).apply(this.cnp);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      client: this.client ? this.client.toJson() : null,
      items: this.items ? SgvJson.to.array(this.items) : null,
      status: this.status ? this.status.name : null,
      eceosCategory: this.eceosCategory ? this.eceosCategory.toJson() : null
    })
  }

  static fromJson(json: any): Invoice {
    return SgvJson.from.simple(json, Invoice, {
      client: json.client ? ClientSummary.fromJson(json.client) : null,
      reference: json.reference ? new Date(json.reference) : null,
      items: json.items ? SgvJson.from.array(json.items, Item.fromJson) : null,
      expiration: json.expiration ? new Date(json.expiration) : null,
      status: json.status ? InvoiceStatus.get(json.status) : null,
      eceosCategory: json.eceosCategory ? EceosCategory.fromJson(json.eceosCategory) : null,
      eceosBankSlip: json.eceosBankSlip ? EceosBankSlip.fromJson(json.eceosBankSlip) : null
    })
  }
}
