import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Functionality, RefreshablePage } from '@financial/arch';
import {
  EntityCrudComponent,
  EntityCrudType,
  FilterDescription,
  FilterType,
  SortDescription
} from '@financial/common-components';
import {
  Product,
  PRODUCTS,
  ProductsRepository,
} from '@financial/domain';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent extends RefreshablePage implements OnInit {
  @ViewChild(EntityCrudComponent, { static: true }) crud: EntityCrudComponent;

  entityTypes: EntityCrudType[] = [{ 
    creator: () => new Product(), 
    title: 'Produto',
    showHelpButton: false
  }];

  functionality: Functionality = PRODUCTS;

  allowedFilters = [
    new FilterDescription('name', 'Nome', FilterType.TEXTUAL)
  ];

  allowedSorts = [
    new SortDescription('name', 'Nome')
  ];

  constructor(
    public repository: ProductsRepository,
    private router: Router,
  ) {
    super();
   }

  ngOnInit(): void {
  }

  hasUnsavedState() {
    return this.crud.hasUnsavedState();
  }
}
