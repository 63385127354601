import { ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ChangeAlertGuard } from '@financial/arch';
import { FinancialGuard } from '../../core/guards/financial/financial.guard';
import { ProductsComponent } from './products.component';

const BASE_PATH = 'products';

export const ProductsRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild([
  { path: BASE_PATH, redirectTo: `${BASE_PATH}/`, pathMatch: 'full' },
  {
    path: `${BASE_PATH}/:id`,
    component: ProductsComponent,
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    canActivate: [FinancialGuard],
    canDeactivate: [ChangeAlertGuard]
  }
]);
