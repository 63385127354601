<fieldset class="fieldset-line">
  <legend>TOTAL</legend>
  <table class="table-in-grid">
    <tr>
      <th class="name"></th>
      <th class="description"></th>
      <th class="amount"></th>
      <th class="value"></th>
      <th class="total"></th>
    </tr>
    <ng-container *ngIf="isInvoiceDetails">
      <tr>
        <td></td>
        <td>(=) Sub. Total Fatura</td>
        <td></td>
        <td></td>
        <td class="total">{{ entity.totalWithoutDiscount | currency: 'BRL'}}</td>
      </tr>
      <tr>
        <td></td>
        <td>(+) Encargo(s) Fatura</td>
        <td></td>
        <td></td>
        <td class="total">{{ 0 | currency: 'BRL'}}</td>
      </tr>
      <tr>
        <td></td>
        <td>(-) Crédito(s) Fatura</td>
        <td></td>
        <td></td>
        <td class="total">{{ 0 | currency: 'BRL'}}</td>
      </tr>
    </ng-container>
    <tr>
      <td></td>
      <td>(-) Desconto(s) Fatura</td>
      <td></td>
      <td></td>
      <td class="total">{{ entity.discount | currency: 'BRL'}}</td>
    </tr>
    <tr>
      <td></td>
      <td>(=) Saldo Total Fatura</td>
      <td></td>
      <td></td>
      <td class="total">{{ entity.total | currency: 'BRL'}}</td>
    </tr>
  </table>
</fieldset>