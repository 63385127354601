import { DomainEntity, SgvId, SgvJson } from "@financial/arch";

export class EceosCategory implements DomainEntity {
  constructor(
    readonly id: string = SgvId.gen(),
    public name: string = '',
    public type: string = '' 
  ) { }

  toJson() {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): EceosCategory {
    return json ? SgvJson.from.simple(json, EceosCategory) : null;
  }
}