import { DomainEntity, SgvId, SgvJson } from '@financial/arch';
import { AnalysisStatus } from './analysis-status';

export class AnalysisSummary implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    public protocol: string = "",
    public status: AnalysisStatus = AnalysisStatus.NOT_PROCESSED,
    public signed: boolean = false,
    public entryDate: Date = null
  ) { }

  get name() {
    return this.protocol;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      status: this.status ? this.status.name : null
    })
  }

  static fromJson(json: any): AnalysisSummary {
    return json ?
      SgvJson.from.simple(json, AnalysisSummary, {
        status: json.status ? AnalysisStatus.get(json.status) : null,
        entryDate: json.entryDate ? new Date(json.entryDate) : null,
      })
      : null
  }

}

export class Analysis implements DomainEntity {
  constructor(
    readonly id: string = SgvId.gen(),
    public protocol: string = "",
    public clientCode: string = "",
    public entryDate: Date = null,
    public collectionDate: Date = null,
    public analysisDate: Date = null,
    public processingDate: Date = null,
    public physicochemical: number = 0,
    public ureicNitrogen: number = 0,
    public casein: number = 0,
    public cpp: number = 0,
    public cryoscopy: number = 0,
    public microbiologicalInhibitor: number = 0,
    public serviceType: string = "",
    public username: string = "",
    public observation: string = "",
    public line: string = "",
    public status: AnalysisStatus = AnalysisStatus.NOT_PROCESSED,
    public signed: boolean = false,
    public readonly isActive = true
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this, {
      status: this.status ? this.status.name : null
    })
  }

  static fromJson(json: any): Analysis {
    return json ?
      SgvJson.from.simple(json, Analysis, {
        entryDate: json.entryDate ? new Date(json.entryDate) : null,
        collectionDate: json.collectionDate ? new Date(json.collectionDate) : null,
        analysisDate: json.analysisDate ? new Date(json.analysisDate) : null,
        processingDate: json.processingDate ? new Date(json.processingDate) : null
      })
      : null
  }
}
