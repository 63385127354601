import { Pipe, PipeTransform } from "@angular/core";
import { throwError } from "rxjs";

@Pipe({ name: 'percentage' })
export class PercentagePipe implements PipeTransform {
    transform(value: number, fixedValue: number = 2): string {
        if (value) {
            return `${value.toFixed(fixedValue)}%`;
        }

        throwError[`The value ${value} is not valid number`];
    }
}