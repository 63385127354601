import { OverdueOrPartiallyPaidInvoicesByClient } from './../../../../../../../libs/domain/src/lib/invoices/items-by-client/overdue-or-partially-paid-invoices-by-client';
import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { InvoicesRepository } from '@financial/domain';

@Component({
  selector: 'app-clients-with-financial-agreement',
  templateUrl: './clients-with-overdue-or-partially-paid-invoices.component.html',
  styleUrls: ['./clients-with-overdue-or-partially-paid-invoices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientsWithOverdueInvoices implements OnInit {

  private _hasItems = true;

  private _items: OverdueOrPartiallyPaidInvoicesByClient[] = [];

  constructor(
    private router: Router,
    private changeRef: ChangeDetectorRef,
    private repository: InvoicesRepository
  ) { }

  get items() {
    return this._items;
  }

  get hasItems() {
    return this._hasItems;
  }

  async ngOnInit() {
    this._items = await lastValueFrom(this.repository.findAllGroupByClient());
    this._hasItems = this.items.length > 0;
    this.changeRef.markForCheck();
  }

  trackByFn(index: any, entity: OverdueOrPartiallyPaidInvoicesByClient): OverdueOrPartiallyPaidInvoicesByClient {
    return entity;
  }

  async openInvoicesTable(id: string) {
    await this.router.navigate([`/invoices/clients/${id}`], {
      replaceUrl: false
    })
  }

}
