export class InvoiceStatus {

  public static PENDING = new InvoiceStatus("PENDING", "PENDENTE");
  public static RELEASED = new InvoiceStatus("RELEASED", "LIBERADO PELO FINANCEIRO");
  public static OPEN = new InvoiceStatus("OPEN", "EM ABERTO");
  public static FINALIZED = new InvoiceStatus("FINALIZED", "FINALIZADA");
  public static OVERDUE = new InvoiceStatus("OVERDUE", "EM ATRASO");
  public static PARTIALLY_PAYED = new InvoiceStatus("PARTIALLY_PAYED", "PAGO PARCIALMENTE");
  public static FINANCIAL_AGREEMENT = new InvoiceStatus("FINANCIAL_AGREEMENT", "EM ACORDO FINANCEIRO");
  public static CANCELED = new InvoiceStatus("CANCELED", "CANCELADA");
  public static FINALIZED_BY_FINANCIAL_AGREEMENT = new InvoiceStatus("FINALIZED_BY_FINANCIAL_AGREEMENT", "FINALIZADA POR ACORDO FINANCEIRO");

  public constructor(
    public readonly name: string,
    public readonly label: string
  ) { }

  public static values(): InvoiceStatus[] {
    return [
      InvoiceStatus.PENDING,
      InvoiceStatus.RELEASED,
      InvoiceStatus.OPEN,
      InvoiceStatus.FINALIZED,
      InvoiceStatus.OVERDUE,
      InvoiceStatus.PARTIALLY_PAYED,
      InvoiceStatus.FINANCIAL_AGREEMENT,
      InvoiceStatus.CANCELED,
      InvoiceStatus.FINALIZED_BY_FINANCIAL_AGREEMENT
    ];
  }

  public static get(name: string): InvoiceStatus {
    const invoiceStatus = InvoiceStatus.values().find(v => v.name === name);
    if (!invoiceStatus) {
      const err = new Error(`${name} Status is not mapped!`);
      console.error(err);
      throw err;
    }
    return invoiceStatus;
  }
}
