import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {
  Address,
} from '@financial/domain';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FinancialValidators } from '@financial/common-utils';

@Component({
  selector: 'app-address-form',
  templateUrl: './address.component-form.html',
  styleUrls: ['./address.component-form.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: AddressFormComponent,
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressFormComponent implements OnInit, ControlValueAccessor, Validator {
  private _required = true;

  private _value: Address = null;

  private registerOnChangeSubscription: Subscription = null;

  private onTouchedListener: any = null;

  valueChange = new EventEmitter<Address>();

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private ref: ChangeDetectorRef,
  ) { }

  validate(control: AbstractControl): ValidationErrors {
    return this.form && this.form.valid ? null : { invalid: true };
  }

  ngOnInit() {
    this.buildForm();
  }

  get value() {
    return this._value;
  }

  set value(value: Address) {
    if (this._value !== value) {
      this._value = value;
      this.publishValuetoForm();
    }
  }

  get required(): boolean {
    return this._required;
  }

  @Input()
  set required(required: boolean) {
    if (this._required !== required) {
      this._required = required;
      this.buildForm();
    }
  }

  writeValue(obj: Address): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    if (this.registerOnChangeSubscription) {
      this.registerOnChangeSubscription.unsubscribe();
    }
    this.registerOnChangeSubscription = this.valueChange.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListener = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled && this.form.enabled) {
      this.form.disable();
    } else if (!isDisabled && this.form.disabled) {
      this.form.enable();
    }
  }

  private buildForm() {
    this.form = null;
    this.form = this.fb.group({
      zipCode: ['', FinancialValidators.requiredIf(() => this.required)],
      city: ['', FinancialValidators.requiredIf(() => this.required)],
      uf: ['', FinancialValidators.requiredIf(() => this.required)],
      street: ['', FinancialValidators.requiredIf(() => this.required)],
      number: ['', FinancialValidators.requiredIf(() => this.required)]
    });

    this.addFormValueChange();
  }

  private publishValuetoForm() {
    if (this.value == null) {
      this.form.reset();
    }
    this.form.setValue({
      zipCode: this.value.zipCode,
      city: this.value.city,
      uf: this.value.uf,
      street: this.value.street,
      number: this.value.number,
    });
    this.addFormValueChange();
  }

  private addFormValueChange() {
    this.form.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        map((v) => new Address(
          v.zipCode,
          v.city,
          v.uf,
          v.street,
          v.number
        )))
      .subscribe(value => this.valueChange.emit((this._value = value)));
    this.ref.markForCheck();
  }
}

