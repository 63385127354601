import { SharedModule } from './../../shared/shared.module';
import { ServiceRoutes } from './services.routes';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicesComponent } from './services.component';
import { ServicesDetailsComponent } from './services-details/services-details.component';

@NgModule({
  declarations: [ServicesComponent, ServicesDetailsComponent],
  imports: [CommonModule, SharedModule, ServiceRoutes]
})
export class ServicesModule {}
