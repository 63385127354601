<app-layout pageTitle="Seleção de Faturas" [showMenu]="false" [showBack]="true">
  <mat-form-field thin [formGroup]="form">
    <mat-label>Data de expiração</mat-label>
    <input (dateInput)="getDateValue($event)" formControlName="expiration" matInput
      [matDatepicker]="picker" [matDatepickerFilter]="onlyFutureDaysDateFilter" required>
    <mat-datepicker-toggle matSuffix [for]="picker">
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <mat-card>
    <app-search-box
      #searchBox
      backIcon="arrow_back"
      class="searchBox"
      placeholder="Buscar"
      (searchDebounce)="search($event)"
      flex
    ></app-search-box>
    <table mat-table [dataSource]="invoices">
      <ng-container *ngFor="let col of columns; trackBy: trackByColumns" [matColumnDef]="col.name">
        <ng-container *ngIf="verifyColumnName(col, 0)">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="toggleAll($event)" [checked]="selectAllOptionsIsChecked()"
              [indeterminate]="isIndeterminateState()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
              (change)="toggleRow($event, row)" [checked]="isChecked(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <th mat-header-cell *matHeaderCellDef>{{ col.label }}</th>
        <td mat-cell *matCellDef="let element">{{ element[col.name] }}</td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="table-row"
        *matRowDef="let row; columns: displayedColumns">
      </tr>
    </table>
    <section>
      <mat-paginator #pagingBar [pageSizeOptions]="pageSizeOptions" showFirstLastButtons [length]="totalOfInvoices"
        (page)="paginate($event)">
      </mat-paginator>
    </section>
    <button class="floatDefault" mat-fab (click)="generateNewBillables()">
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </mat-card>
</app-layout>