<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form">
  <mat-tab-group #group>
      <mat-tab label="Dados Gerais" [swipeTab]="group">
        <div class="container-sections">
          <mat-form-field class="field">
            <mat-label>Nome</mat-label>
            <input matInput formControlName="name" />
          </mat-form-field>
          <section class="assays">
            <mat-accordion>
              <button *ngIf="form.enabled" mat-raised-button mat-button color="accent" class="new" (click)="addAssayControl()">
                Novo Grupo de Ensaios
              </button>
              <ng-container formArrayName="assays" *ngIf="!isAssaysEmpty">
                <ng-container *ngFor="let assay of assaysFormArrayControls; let i = index; trackBy: trackByControlValueId">
                  <div [formGroupName]="i">
                    <mat-expansion-panel thin class="assay">
                      <mat-expansion-panel-header>
                        <mat-panel-title> {{ i + 1 }} - {{panelTitle(assay)}} </mat-panel-title>
                      </mat-expansion-panel-header>
                      <section class="field">
                        <app-data-selector label="Serviço" [repository]="serviceRepository" formControlName="service">
                        </app-data-selector>
                      </section>
                      <section class="field">
                        <mat-form-field>
                          <mat-label>Tipo de analise</mat-label>
                          <mat-select formControlName="analysisType" [compareWith]="compareAnalysisType">
                            <mat-option *ngFor="let analysis of analysisTypes; trackBy: trackByAnalysisTypes" [value]="analysis">
                              {{ analysis.label }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </section>
                      <mat-action-row *ngIf="form.enabled">
                        <button type="button" mat-button color="warn" (click)="onItemRemove(assay.value)">
                          Remover Item
                        </button>
                      </mat-action-row>
                    </mat-expansion-panel>
                  </div>
                </ng-container>
              </ng-container>
            </mat-accordion>
          </section>
        </div>
    </mat-tab>
  </mat-tab-group>
</form>
