import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgePipe } from './age.pipe';
import { DateIntervalPipe } from './date-interval.pipe';
import { ReversePipe } from './reverse.pipe';
import { SafeHtml } from './safe-html.pipe';
import { TruncatePipe } from './truncate.pipe';
import { PercentagePipe } from './percentage.pipe';

const all = [AgePipe, ReversePipe, DateIntervalPipe, SafeHtml, TruncatePipe, PercentagePipe];
@NgModule({
  imports: [CommonModule],
  declarations: [...all],
  exports: [...all]
})
export class PipesModule {}
