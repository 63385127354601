<section *ngIf="item.billable.isBillableAnalysis">
  <ng-container *ngFor="let serviceItem of item.serviceItems; trackBy: trackByItem">
    <section class="inline-infos">
      <mat-form-field thin>
        <mat-label>Descrição</mat-label>
        <input matInput [value]="serviceItem.service.name" readonly />
      </mat-form-field>
      <mat-form-field thin class="numbers">
        <mat-label>Quantidade</mat-label>
        <input matInput [value]="serviceItem.amount" readonly />
      </mat-form-field>
      <mat-form-field thin class="numbers">
        <mat-label>Valor</mat-label>
        <input matInput [value]="serviceItem.serviceValue | currency: 'BRL'" readonly />
      </mat-form-field>
      <mat-form-field thin class="numbers">
        <mat-label>Total</mat-label>
        <input matInput [value]="serviceItem.totalServiceValue | currency: 'BRL'" readonly />
      </mat-form-field>
    </section>
  </ng-container>
</section>

<section *ngIf="item.billable.isBillableSingleRequisition">
  <ng-container *ngFor="let productItem of item.productItems; trackBy: trackByItem" >
    <section class="inline-infos">
      <mat-form-field thin>
        <mat-label>Descrição</mat-label>
        <input matInput [value]="productItem.product.formattedName" readonly />
      </mat-form-field>
      <mat-form-field thin class="numbers">
        <mat-label>Quantidade</mat-label>
        <input matInput [value]="productItem.amount" readonly />
      </mat-form-field>
      <mat-form-field thin class="numbers">
        <mat-label>Valor</mat-label>
        <input matInput [value]="productItem.product.value | currency: 'BRL'" readonly />
      </mat-form-field>
      <mat-form-field thin class="numbers">
        <mat-label>Total</mat-label>
        <input matInput [value]="productItem.totalProductValue | currency: 'BRL'" readonly />
      </mat-form-field>
    </section>
  </ng-container>
</section> 

<section *ngIf="item.billable.isBillableAgreement">
  <section class="inline-infos">
    <mat-form-field thin>
      <mat-label>Referente a Fatura</mat-label>
      <input matInput [value]="billableAgreement.invoiceNumber" readonly />
    </mat-form-field>
    <mat-form-field thin class="numbers">
      <mat-label>Valor</mat-label>
      <input matInput [value]="billableAgreement.value | currency: 'BRL'" readonly />
    </mat-form-field>
  </section>
</section>