export class SingleRequisitionStatus {

  public static PROCESSED = new SingleRequisitionStatus("PROCESSED");
  public static NOT_PROCESSED = new SingleRequisitionStatus("NOT_PROCESSED");

  public constructor(
    public readonly name: string
  ){}

  public static values(): SingleRequisitionStatus[] {
    return [
      SingleRequisitionStatus.PROCESSED,
      SingleRequisitionStatus.NOT_PROCESSED,
    ];
  }

  public static get(name: string): SingleRequisitionStatus {
    const singleRequisitionStatus = SingleRequisitionStatus.values().find(v => v.name === name);
    if(!singleRequisitionStatus) {
      const err = new Error(`${name} Status is not mapped!`);
      console.error(err);
      throw err;
    }
    return singleRequisitionStatus;
  }
}
