import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DefaultSharedModule } from '@financial/default-shared';
import { LayoutFinancialModule } from './financial/layout-financial.module';

const MODULES = [LayoutFinancialModule];

@NgModule({
  imports: [CommonModule, DefaultSharedModule, ...MODULES],
  declarations: [],
  exports: MODULES
})
export class LayoutModule {}
