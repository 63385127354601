import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Functionality, PageData, RefreshablePage } from '@financial/arch';
import { EntityCrudComponent, EntityCrudType } from '@financial/common-components';
import { BILLABLES, ClientSummary, ClientsRepository, Product } from '@financial/domain';
import { AnalysesRepository, AnalysisSummary } from 'libs/domain/src/lib/analysis';
import { Billable } from 'libs/domain/src/lib/billables';
import { lastValueFrom } from 'rxjs';
import { EntityListPerspective } from './../../../../../../libs/common-components/src/lib/entity-list-perspective/entity-list-perspective';
import { EnumFilterDescription, RefFilterDescription } from './../../../../../../libs/common-components/src/lib/entity-list-perspective/filter-description';
import { BillableStatus } from './../../../../../../libs/domain/src/lib/billables/billable-status';
import { BillableRepository } from './../../../../../../libs/domain/src/lib/billables/billables.repository';
import { ProductsRepository } from './../../../../../../libs/domain/src/lib/products/products.repository';
import { BillablesDetailsComponent } from './billables-details/billables-details.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-billables',
  templateUrl: './billables.component.html',
  styleUrls: ['./billables.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillablesComponent extends RefreshablePage implements OnInit {

  readonly columns: TableColumn[] = [
    { name: 'clientName', label: 'Cliente' },
    { name: 'protocol', label: 'Nº Protocolo' },
    { name: 'requisitionNumber', label: 'Nº Requisição' },
    { name: 'statusLabel', label: 'Status' },
    { name: 'name', label: 'Nome' },
    { name: 'date', label: 'Data de Entrada' },
    { name: 'total', label: 'Valor total' },
    { name: 'clientToBillName', label: "Faturar Para"}
  ];

  readonly pageSizeOptions = [10, 20, 50, 100];

  opened = false;

  readonly allowedFilters = [
    new RefFilterDescription<Product>('product:id', 'Nome do produto', this.productsRepository),
    new RefFilterDescription<AnalysisSummary>('analysis:id', 'Número de protocolo', this.analysesRepository),
    new EnumFilterDescription<BillableStatus>('status', 'Status', BillableStatus.values()),
    new RefFilterDescription<ClientSummary>('client:id', 'Cliente', this.clientsRepository)
  ];

  allowedSorts = [];

  entityTypes: EntityCrudType[] = [];
  
  private page = new PageData(0, 10);

  private currentPage = this.page;

  private _displayedColumns = this.columns.map(v => v.name);

  private _billables: Billable[] = [];

  private _totalOfBillables: number;

  private _perspective: EntityListPerspective;

  private _query = "";

  private _noBillableFound = false;

  private _pageIndex: number = this.page.first;

  private _pageSize: number = this.page.rows;

  private _showActives = false; 

  constructor(
    public repository: BillableRepository,
    private clientsRepository: ClientsRepository,
    private analysesRepository: AnalysesRepository,
    private productsRepository: ProductsRepository,
    private changeRef: ChangeDetectorRef,
    private router: Router,
    private dialog: MatDialog
  ) {
    super();
  }

  get billables() {
    return this._billables;
  }

  get displayedColumns() {
    return this._displayedColumns;
  }

  get totalOfBillables() {
    return this._totalOfBillables;
  }

  get noBillableFound() {
    return this._noBillableFound;
  }

  get showActives(): boolean {
    return this._showActives;
  }

  async ngOnInit() {
    this._totalOfBillables = await lastValueFrom(this.repository.size());
    this._billables = await lastValueFrom(this.repository.page(this.page));
    this.changeRef.markForCheck();
  }

  async loadTableBillables() {
    this._totalOfBillables = await lastValueFrom(this.repository.size(this._query, this._perspective.filters));
    this._billables = await lastValueFrom(this.repository.page(this.currentPage, this._query, this._perspective.filters));
    this._noBillableFound = this._totalOfBillables <= 0;
    this.changeRef.markForCheck();
  }

  paginate(pagingEvent: PageEvent) {
    pagingEvent.pageIndex = pagingEvent.pageIndex * pagingEvent.pageSize;
    this._pageIndex = pagingEvent.pageIndex;
    this._pageSize = pagingEvent.pageSize;
    this.currentPage = new PageData(this._pageIndex, this._pageSize);
    this.loadTableBillables();
    this.changeRef.markForCheck();
  }

  hasUnsavedState(): boolean {
    return false;
  }

  onPrintClick() {
    window.print();
  }

  isPipeNotNeeded(column: TableColumn): boolean {
    return !this.verifyColumnName(column, 5) && !this.verifyColumnName(column, 6);
  }

  verifyColumnName(column: TableColumn, index: number): boolean {
    return (column?.name === this.columns[index]?.name);
  }

  trackByColumn(index: any, entity: TableColumn) {
    return entity.name;
  }

  openBillableDetailsDialog(billable: Billable): void {
    this.router.navigate([`billables/${billable.id}`], { replaceUrl: false });
    const dialogRef = this.dialog.open(BillablesDetailsComponent, {
      panelClass: 'crud-dialog-container',
      data: billable
    });
    dialogRef.afterClosed().subscribe(
      result => {
        this.router.navigate(['billables'], { replaceUrl: false });
        this.loadTableAfterNavigate();
      }
    );
  }

  async filterChange(event: EntityListPerspective) {
    this._perspective = event;
    this.loadTableBillables();
  }
  
  private loadTableAfterNavigate(): void {
    let pagingEvent: PageEvent = new PageEvent();
    pagingEvent.pageIndex = this._pageIndex / this.currentPage.rows;
    pagingEvent.pageSize = this._pageSize;
    this.paginate(pagingEvent)
  }
}

interface TableColumn {
  name: string,
  label: string
}