import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddressPipe } from './address/address.pipe';
import { DicionaryActionPipe } from './dicionary-action/dicionary-action.pipe';

const PIPES = [
  AddressPipe,
  DicionaryActionPipe
];

@NgModule({
  imports: [CommonModule],
  providers: [CurrencyPipe],
  declarations: PIPES,
  exports: PIPES
})
export class PipesModule {}
