<mat-card>
  <mat-card-content>
    <mat-card-title>{{ title }}</mat-card-title>
    <section class="main">
      <app-date-range-picker [(interval)]="interval"></app-date-range-picker>
    </section>
    <section class="chart-container">
      <canvas [id]="chartId"></canvas>
      <section class="chart-text" *ngIf="hasData">
        <ng-container *ngIf="isDoughnut">
          <h2>{{ selectedDashboard.value | currency: 'BRL' }}</h2>
          <small>{{ selectedDashboard.name }}</small>
          <p>{{ selectedDashboard.percentage | percentage }}</p>
        </ng-container>
      </section>
    </section>
  </mat-card-content>
</mat-card>