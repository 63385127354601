import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-cancel-bankslip-dialog',
  templateUrl: './cancel-bankslip-dialog.component.html',
  styleUrls: ['./cancel-bankslip-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CancelBankslipDialogComponent {

  form: FormGroup;

  private _justification = '';

  constructor(
    private dialogRef: MatDialogRef<CancelBankslipDialogComponent>,
    private fb: FormBuilder,
    private snackbar: MatSnackBar
  ) {
    this.form = fb.group({
      justification: [this._justification, [Validators.required]]
    })
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onYesClick() {
    if (this.form.controls['justification'].untouched || this.form.invalid) {
      this.snackbar.open("Uma justificativa deve ser inserida para cancelar boleto", null, { duration: 4000 })
    } else {
      this.dialogRef.close({ data: this.form.controls['justification'].value });
    }
  }
}
