import { DomainEntity, SgvId, SgvJson } from '@financial/arch';
import { Assay } from './assay';

export class Setting implements DomainEntity {
  constructor(
    readonly id: string = SgvId.gen(),
    public name = '',
    public assays: Assay[] = [],
    public readonly version = 0,
    public readonly isActive = true
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this, {
      assays: SgvJson.to.array(this.assays)
    });
  }

  static fromJson(json: any): Setting {
    return SgvJson.from.simple(json, Setting, {
      assays: json.assays ? SgvJson.from.array(json.assays, Assay.fromJson) : null
    });
  }

}
