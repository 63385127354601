import { DomainEntity, SgvId, SgvJson } from "@financial/arch";


export class EceosOperatable implements DomainEntity {
    constructor(
        readonly id: string = SgvId.gen(),
        public name: string = '',
        public type: string = ''
    ) { }

    toJson() {
        return SgvJson.to.simple(this);
    }

    static fromJson(json: any): EceosOperatable {
        return json ? SgvJson.from.simple(json, EceosOperatable) : null
    }
}