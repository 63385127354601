<app-layout pageTitle="Faturáveis" [showSearch]="false" class="hidden-on-print">
  <section actions>
    <button mat-icon-button (click)="onPrintClick()">
      <mat-icon>print</mat-icon>
    </button>
    <button mat-icon-button (click)="opened = !opened">
      <mat-icon svgIcon="filter"></mat-icon>
    </button>
  </section>
  <mat-sidenav-container>
    <mat-sidenav #sidenav mode="side" (opened)="perspective" position="end" [opened]="opened">
      <section drawerRight>
        <entity-list-perspective 
          #perspective 
          [allowedFilters]="allowedFilters" 
          [allowedSorts]="allowedSorts"
          [showActives]="showActives"
          (valueChange)="filterChange($event)">
        </entity-list-perspective>
      </section>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-card>
        <table mat-table [dataSource]="billables">
          <ng-container [matColumnDef]="column.name" *ngFor="let column of columns; trackBy: trackByColumn">
            <th mat-header-cell *matHeaderCellDef>{{ column.label }}</th>
        <ng-container *ngIf="isPipeNotNeeded(column)">
            <td mat-cell *matCellDef="let element">{{ element[column.name] }}</td>
          </ng-container>
        <ng-container *ngIf="verifyColumnName(column, 5)">
          <td mat-cell *matCellDef="let element">{{ element[column.name] | date: 'shortDate'}}</td>
        </ng-container>
        <ng-container *ngIf="verifyColumnName(column, 6)">
          <td mat-cell *matCellDef="let element">{{ element[column.name] | currency: 'BRL'}}</td>
        </ng-container>
      </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="openBillableDetailsDialog(row)"></tr>
        </table>
        <section class="no-billables" *ngIf="noBillableFound">
          Nenhum faturável encontrado.
        </section>
        <section>
          <mat-paginator #pagingBar [pageSizeOptions]="pageSizeOptions" showFirstLastButtons [length]="totalOfBillables"
            (page)="paginate($event)">
          </mat-paginator>
        </section>
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-container>
</app-layout>

<section class="show-only-on-print">
  <print-header title="Faturáveis"></print-header>
  <table mat-table [dataSource]="billables">
    <ng-container [matColumnDef]="column.name" *ngFor="let column of columns; trackBy: trackByColumn">
      <th mat-header-cell *matHeaderCellDef>{{ column.label }}</th>
      <ng-container *ngIf="isPipeNotNeeded(column)">
        <td mat-cell *matCellDef="let element">{{ element[column.name] }}</td>
      </ng-container>
      <ng-container *ngIf="verifyColumnName(column, 5)">
        <td mat-cell *matCellDef="let element">{{ element[column.name] | date: 'shortDate'}}</td>
      </ng-container>
      <ng-container *ngIf="verifyColumnName(column, 6)">
        <td mat-cell *matCellDef="let element">{{ element[column.name] | currency: 'BRL'}}</td>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let emprow; columns: displayedColumns"></tr>
  </table>
</section>