import { Component, OnInit, ViewChild } from '@angular/core';
import { Functionality } from '@financial/arch';
import { EntityCrudComponent, EntityCrudType } from '@financial/common-components';
import { Setting, SETTINGS, SettingsRepository } from '@financial/domain';
import { Assay } from 'libs/domain/src/lib/settings/assay';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  @ViewChild(EntityCrudComponent, { static: true }) crud: EntityCrudComponent;

  entityTypes: EntityCrudType[] = [{ 
    title: 'Configurações', 
    creator: () => new Setting(), 
    showHelpButton: false 
  }];

  fabOpen = true;

  functionality: Functionality = SETTINGS;

  constructor(public repository: SettingsRepository) {}

  ngOnInit() {}

  hasUnsavedState() {
    return this.crud.hasUnsavedState();
  }

  trackByAssays(_: any, entity: Assay) {
    return entity
  }
}
