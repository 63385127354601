import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent, EntityCrudState } from '@financial/common-components';
import {
  AnalysisType,
  ProductsRepository,
  ServiceRepository,
  Setting,
  SettingsRepository
} from '@financial/domain';
import { Assay } from 'libs/domain/src/lib/settings/assay';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
@Component({
  selector: 'app-settings-details',
  templateUrl: './settings-details.component.html',
  styleUrls: ['./settings-details.component.scss']
})
export class SettingsDetailsComponent implements OnInit {
  form: FormGroup;
  hasChargingFlow = false;
  @Output() entityChange = new EventEmitter<Setting>();
  @Output() submit = new EventEmitter<Setting>();
  @Output() validChange = new EventEmitter<boolean>();
  @Output() dirtyChange = new EventEmitter<boolean>();
  @Output() serviceChange = new EventEmitter<Setting>();
  analysisTypes: AnalysisType[] = [];
  allComplete: boolean = false;
  private _entity: Setting;
  private _state: EntityCrudState;
  private _valid = true;
  private _dirty = false;

  constructor(
    private formBuilder: FormBuilder,
    private ref: ChangeDetectorRef,
    public settingRepository: SettingsRepository,
    public productRepository: ProductsRepository,
    public serviceRepository: ServiceRepository,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
  ) {}

  @Input()
  set entity(entity: Setting) {
    if (this.entity !== entity) {
      this._entity = entity;
      this.createFormFromEntity();
    }
  }

  get entity() {
    return this._entity;
  }

  @Input()
  set state(value: EntityCrudState) {
    this._state = value;
    this.syncFromState();
  }

  get state(): EntityCrudState {
    return this._state;
  }

  get assays() : FormArray {
    return this.form.get('assays') as FormArray;
  }

  get assaysFormArrayControls(): AbstractControl[] {
    return this.assays.controls;
  }

  get isAssaysEmpty() {
    return this.assaysFormArrayControls.length == 0;
  }

  ngOnInit() {
    this.analysisTypes = AnalysisType.values()
  }

  addFormValueChange(mapper: (f: any) => Setting) {
    this.form.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(300), map(mapper))
      .subscribe((value) => {
        this.entityChange.emit((this._entity = value));
      });
  }

  onSubmit() {
    this.submit.emit(this.entity);
  }

  private assayControl(assay: Assay) {
    return this.formBuilder.group({
      service: [assay.service, Validators.required],
      analysisType: [assay.analysisType, Validators.required]
    })
  }

  private createFormFromEntity(detectChanges: boolean = true) {
    this.form = null;
    if (detectChanges && !this.ref['destroyed']) {
      this.ref.detectChanges();
    }
    if (this.entity) {
      this.form = this.formBuilder.group({
        name: [this.entity.name, Validators.required],
        assays: this.formBuilder.array(
          this.entity.assays.map((assay) => this.assayControl(assay)),
          Validators.required
        )
      });
      this.addFormValueChange(
        (v: Setting) => new Setting(
          this.entity.id,
          v.name,
          v.assays.map((it) => new Assay(it.service, it.analysisType))
        )
      );
    }
    if (this.form) {
      this.form.statusChanges.subscribe((s: any) => {
        if (this.form.dirty !== this._dirty) {
          this.dirtyChange.emit((this._dirty = this.form.dirty));
        }
        if (this.form.valid !== this._valid) {
          this.validChange.emit((this._valid = this.form.valid));
        }
      });
    }
    this.syncFromState();
  }

  private syncFromState() {
    if (this.form) {
      if (this._state === EntityCrudState.INSERT || this._state === EntityCrudState.EDIT) {
        this.form.enable();
      } else {
        this.form.disable();
      }
    }
  }

  private async navigateToRegister(servie: Setting) {
    this.serviceChange.emit(servie);
  }

  private showMessage(message: string) {
    this.snackbar.open(message, null, { duration: 2000 });
  }

  compareAnalysisType(option: AnalysisType, value: AnalysisType) {
    return option?.name === value?.name
  }

  addAssayControl() {
    if (this.assays.length < 6) {
      this.assays.push(this.assayControl(new Assay()))
    }
  }

  panelTitle(control: AbstractControl) {
    return (control.value.service?.name && control.value.analysisType?.name)
      ? `${control.value.analysisType?.label} - ${control.value.service?.name}`
      : 'Novo'
  }

  onItemRemove(item: Assay) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { title: 'Atenção', content: 'Tem certeza de que deseja remover este item?' }
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const index = this.assaysFormArrayControls.map((c) => c.value).indexOf(item);
          if (index >= 0) {
            this.assays.removeAt(index);
            this.showMessage(`Item removido!`);
          }
        }
      });
  }

  trackByControlValueId(_: any, control: AbstractControl) {
    return control.value.id;
  }

  trackByAnalysisTypes(_: any, value: AnalysisType) {
    return value.name
  }

}
