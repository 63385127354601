import { SgvJson } from '@financial/arch';
import { ClientSummary } from '../../clients';

export class ItemByClient {
  constructor(
    public readonly client: ClientSummary = null,
    public readonly billableAmount: number = 0,
    public readonly productAmount: number = 0,
    public readonly serviceAmount: number = 0,
    public readonly productValue: number = 0,
    public readonly serviceValue: number = 0,
    public readonly processedDate: Date = null
  ) { }

  static fromJson(json: any): ItemByClient {
    return json
      ? SgvJson.from.simple(json, ItemByClient, {
        client: json.client ? ClientSummary.fromJson(json.client) : null,
        processedDate: json.processedDate ? new Date(json.processedDate) : null
      })
      : null
  }
}
