import { Injectable } from '@angular/core';
import { ApiPath, ApiService, TemporalReadonlyRepositoryImpl } from '@financial/arch';
import { InvoicesChart } from '@financial/domain';

@Injectable({ providedIn: 'root' })
export class InvoicesChartRepository extends TemporalReadonlyRepositoryImpl<InvoicesChart, InvoicesChart>{
  private apiPath: ApiPath;

  constructor(api: ApiService) {
    super(
      api.root
        .path('invoices')
        .path('reports')
        .path('chartData'),
      InvoicesChart,
      InvoicesChart
    );
  }
}