import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { DiscountsDetailsComponent } from './discounts-details/discounts-details.component';
import { DiscountsComponent } from "./discounts.component";
import { DiscountRoutes } from "./discounts.routes";


@NgModule({
  imports: [CommonModule, SharedModule, DiscountRoutes],
  declarations: [DiscountsComponent, DiscountsDetailsComponent]
})
export class DiscountModule { }
