import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnDestroy
} from '@angular/core';
import {
  DateInterval,
  PageData,
  TemporalReadonlyRepository,
  OnlineStatusService
} from '@financial/arch';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { DateRange } from '../date-range-picker/date-range';
import { EntityCardDirective } from '../entity-cards/entity-card.directive';
import { EntityListPerspective } from '../entity-list-perspective/entity-list-perspective';
import { LoadQueue } from '../shared/load-helper';
import { untilDestroyed } from 'ngx-take-until-destroy';

interface QueryData {
  interval: DateInterval;
  summary: boolean;
  searchTerm: string;
  perspective: EntityListPerspective;
}

const EMPTY: QueryData = {
  interval: DateInterval.ofToday(),
  summary: true,
  searchTerm: '',
  perspective: null
};

const DEFAULT_PAGE_SIZE = 25;

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ep-temporal-entity-cards',
  templateUrl: './temporal-entity-cards.component.html',
  styleUrls: ['./temporal-entity-cards.component.scss']
})
export class TemporalEntityCardsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() repository: TemporalReadonlyRepository<any, any>;

  @Input() withTime = false;

  @Input() intervalOptions = DateRange.values();

  @Output() entitySelect = new EventEmitter<any>();

  @Output() loadFail = new EventEmitter<Error>();

  @ViewChild('cards') cards: ElementRef;

  @ContentChild(EntityCardDirective, { static: true })
  cardTemplate: EntityCardDirective<any>;

  filteredData: any[] = [];

  filteredTotal = 0;

  loadQueue = new LoadQueue();

  private currentPage = new PageData(0, DEFAULT_PAGE_SIZE);

  private loadSubject = new BehaviorSubject<QueryData>(EMPTY);

  constructor(
    public onlineService: OnlineStatusService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.onlineService.$online
      .pipe(
        untilDestroyed(this),
        filter((v) => v)
      )
      .subscribe((v) => this.refresh());
  }

  get interval() {
    return this.loadSubject.value.interval;
  }

  @Input()
  set interval(interval: DateInterval) {
    if (this.interval !== interval) {
      this.loadSubject.next({
        interval: interval,
        summary: this.summary,
        perspective: this.perspective,
        searchTerm: this.searchTerm
      });
    }
  }

  get loading() {
    return !this.loadQueue.empty;
  }

  get isFiltered() {
    return this.searchTerm && this.searchTerm.trim() !== '';
  }

  get summary() {
    return this.loadSubject.value.summary;
  }

  @Input()
  set summary(value: boolean) {
    if (this.summary !== value) {
      this.loadSubject.next({
        interval: this.interval,
        summary: value,
        perspective: this.perspective,
        searchTerm: this.searchTerm
      });
    }
  }

  get perspective() {
    return this.loadSubject.value.perspective;
  }

  @Input()
  set perspective(value: EntityListPerspective) {
    if (!this.perspective || !this.perspective.isEqual(value)) {
      this.loadSubject.next({
        interval: this.interval,
        summary: this.summary,
        perspective: value,
        searchTerm: this.searchTerm
      });
    }
  }

  get searchTerm() {
    return this.loadSubject.value.searchTerm;
  }

  set searchTerm(value: string) {
    if (this.searchTerm !== value) {
      this.loadSubject.next({
        interval: this.interval,
        summary: this.summary,
        perspective: this.perspective,
        searchTerm: value
      });
    }
  }

  get hasData(): boolean {
    return this.filteredTotal > 0;
  }

  ngOnInit(): void {
    this.loadFail.pipe(filter(Boolean)).subscribe((e: any) => {
      this.filteredTotal = 0;
      this.filteredData = [];
      console.error(e);
    });
  }

  ngAfterViewInit() {
    this.loadSubject
      .pipe(
        filter((v) => Boolean(v.perspective) && Boolean(this.repository)),
        debounceTime(300),
        distinctUntilChanged(
          (v1, v2) =>
            v1.interval === v2.interval &&
            v1.searchTerm === v2.searchTerm &&
            v1.summary === v2.summary &&
            (v1.perspective === v2.perspective || v1.perspective.isEqual(v2.perspective))
        )
      )
      .subscribe((v) => this.refresh(v));
  }

  ngOnDestroy() {}

  onEntityClick(event: any) {
    this.entitySelect.emit(event.row);
  }

  search(searchTerm: string): void {
    this.searchTerm = searchTerm;
  }

  invalidate() {
    this.refresh();
  }

  trackByFn(entity: any) {
    return entity.id || entity;
  }

  onScrollDown(event: any) {
    this.loadNextPage();
  }

  async loadNextPage() {
    this.runNetworkOp(
      async () =>
        (this.filteredData = [
          ...this.filteredData,
          ...(await this.fetchPage(this.currentPage.next, this.loadSubject.value))
        ])
    );
  }

  async refresh(v: QueryData = this.loadSubject.value) {
    this.runNetworkOp(async () => {
      if (this.cards) {
        this.cards.nativeElement.scrollTo(0, 0);
      }
      const [total, data] = await Promise.all([
        lastValueFrom(this.repository
          .count(v.interval, v.searchTerm, v.perspective.filters, v.perspective.actives)),
        this.fetchPage(new PageData(0, this.currentPage.rows), v)
      ]);
      this.filteredTotal = total;
      this.filteredData = data;
    });
  }

  private async fetchPage(page: PageData, v: QueryData = this.loadSubject.value): Promise<any[]> {
    const result = await lastValueFrom((v.summary
      ? this.repository.page(
          v.interval,
          page,
          v.searchTerm,
          v.perspective.filters,
          v.perspective.sorts,
          v.perspective.actives
        )
      : this.repository.pageFull(
          v.interval,
          page,
          v.searchTerm,
          v.perspective.filters,
          v.perspective.sorts,
          v.perspective.actives
        )
    ));
    this.currentPage = page;
    return result;
  }

  private async runNetworkOp<T>(action: () => Promise<T>): Promise<T> {
    const load = this.loadQueue.enqueue();
    try {
      // this.changeDetector.detectChanges();
      const result = await action();
      this.loadFail.emit(null);
      return result;
    } catch (e) {
      this.loadFail.emit(e);
    } finally {
      this.loadQueue.markFinished(load);
      this.changeDetector.markForCheck();
    }
  }
}
