import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Filter, FilterOp, PageData } from '@financial/arch';
import { InvoiceSummary, InvoicesRepository } from '@financial/domain';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-regenerate-bank-slip-dialog',
  templateUrl: './regenerate-bank-slip-dialog.component.html',
  styleUrls: ['./regenerate-bank-slip-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegenerateBankSlipDialogComponent implements OnInit {

  readonly columns = [
    { name: 'number', label: 'Número da Fatura' },
    { name: 'clientName', label: 'Nome do Cliente' },
    { name: 'total', label: 'Valor total' }
  ];

  readonly pageSizeOptions = [5, 10];

  dataSource = new MatTableDataSource(this.columns);

  private _invoicesIds: string[] = [];

  private page = new PageData(0, 5);

  private currentPage = this.page;

  private readonly _displayedColumns = this.columns.map(value => value.name);

  private _invoices: InvoiceSummary[] = [];

  private _bankSlipWithError: string = 'bankSlipWithError';

  private _filters: Filter[] = [new Filter(this._bankSlipWithError, FilterOp.EQ, true)];

  private _totalOfInvoices: number = 0;

  private _query = "";

  constructor(
    public repository: InvoicesRepository,
    private dialogRef: MatDialogRef<RegenerateBankSlipDialogComponent>,
    private changeRef: ChangeDetectorRef,
  ) { }

  get displayedColumns() {
    return this._displayedColumns;
  }

  get invoices() {
    return this._invoices;
  }

  get totalOfInvoices() {
    return this._totalOfInvoices;
  }

  ngOnInit() {
    this.loadInvoicesWithError();
  }

  async loadInvoicesWithError(): Promise<void> {
    this._totalOfInvoices = await lastValueFrom(this.repository.size(this._query, this._filters));
    this._invoices = (await lastValueFrom(this.repository.page(this.currentPage, this._query, this._filters)));
    this.changeRef.markForCheck();
  }

  onNoClick() {
    this.dialogRef.close();
  }

  async onYesClick() {
    this.invoices.forEach(invoice => {
      this._invoicesIds.push(invoice.id);
    });
    await lastValueFrom(this.repository.reprocessBankSlips(this._invoicesIds));
    this.dialogRef.close({ data: true });
  }

  trackByColumn(index: any, entity: TableColumn) {
    return entity.name;
  }

  isPipeNotNeeded(column: TableColumn): boolean {
    return !this.verifyColumnName(column, 2) && !this.verifyColumnName(column, 3);
  }

  verifyColumnName(column: TableColumn, index: number): boolean {
    return (column?.name === this.columns[index]?.name);
  }

  async paginate(pagingEvent: PageEvent) {
    pagingEvent.pageIndex = pagingEvent.pageIndex * pagingEvent.pageSize;
    this.currentPage = new PageData(pagingEvent.pageIndex, pagingEvent.pageSize);
    this.loadInvoicesWithError();
    this.changeRef.markForCheck();
  }
}

interface TableColumn {
  name: string,
  label: string
}