<section class="container">
  <mat-icon svgIcon="assets:logo_cpa_black"></mat-icon>
  <div class="title">
    <h3>CENTRO DE PESQUISA EM ALIMENTOS</h3>
    <h3>ESCOLA DE VETERNÁRIA E ZOOTECNIA</h3>
    <h3>UNIVERSIDADE FEDERAL DE GOIÁS</h3>
  </div>
  <mat-icon svgIcon="assets:logo_ufg_black"></mat-icon>
</section>
<section>
  <h2>{{ title }}</h2>
</section>