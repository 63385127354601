import { EventEmitter, Inject, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { financialToken } from '@financial/arch';
import { distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { ErrorResponse, HttpErrorHandler } from './http-error-handler';

export const INACTIVE_PATH = 'inactivePath';

const DEFAULT_INACTIVE_PATH = '/inactive';

const INACTIVE_VIOLATION = 'InactiveEnterpriseViolation';

@Injectable({ providedIn: 'root' })
export class InactiveEnterpriseErrorHandler extends HttpErrorHandler {
  readonly inactivePath: string;

  error$: EventEmitter<ErrorResponse> = new EventEmitter();

  constructor(
    @Inject(INACTIVE_PATH) @Optional() inactivePath: string,
    private authService: AuthService,
    private router: Router
  ) {
    super();
    this.inactivePath = inactivePath || DEFAULT_INACTIVE_PATH;
    this.error$.pipe(distinctUntilChanged()).subscribe((error) => this.handleError(error));
  }

  matches(error: ErrorResponse): boolean {
    const urlWithoutParams = '/'.concat(
      this.router
        .parseUrl(this.router.url)
        .root.children['primary']
        ?.segments
        ?.map((it) => it.path)
        ?.join('/')
    );

    return (
      error?.error?.error?.error == INACTIVE_VIOLATION && urlWithoutParams != this.inactivePath
    );
  }

  handle(error: ErrorResponse): void {
    this.error$.next(error);
  }

  private handleError(error: ErrorResponse) {
    const token = this.authService.hasToken
      ? financialToken.decode(this.authService.currentToken)
      : null;
    this.authService.publishLogout();
    this.router.navigate([this.inactivePath], {
      queryParams: token ? { tenant: token.tenantId } : {}
    });
  }
}
