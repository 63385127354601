<section [formGroup]="form">
  <mat-form-field class="zipCode auto-width" >
    <mat-label>CEP</mat-label>
    <input #numberInput matInput [maxLength]="9" formControlName="zipCode" kzMask="99999-999"/>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Município</mat-label> 
    <input matInput formControlName="city"/>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Estado</mat-label>
    <input matInput formControlName="uf" minlength="2" maxlength="2"/>
  </mat-form-field>
  <mat-form-field class="street">
    <mat-label>Rua</mat-label>
    <input matInput formControlName="street"/>
  </mat-form-field>
  <mat-form-field class="number">
    <mat-label>Número</mat-label> 
    <input #numberInput matInput formControlName="number" />
  </mat-form-field>
</section>
