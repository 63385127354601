import { ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';

export const FeaturesRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild([
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule)
  },
  {
    path: 'clients',
    loadChildren: () => import('./clients/clients.module').then((m) => m.ClientsModule)
  },
  {
    path: 'discounts',
    loadChildren: () => import('./discounts/discounts.module').then((m) => m.DiscountModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./products/products.module').then((m) => m.ProductsModule)
  },
  {
    path: 'services',
    loadChildren: () => import('./services/services.module').then((m) => m.ServicesModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule)
  },
  {
    path: 'invoices',
    loadChildren: () => import('./invoices/invoices.module').then((m) => m.InvoicesModule)
  },
  {
    path: 'billables',
    loadChildren: () => import('./billables/billables.module').then((m) => m.BillablesModule)
  }
]);
