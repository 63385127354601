import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ClientsDetailsComponent } from './clients-details/clients-details.component';
import { ClientsComponent } from './clients.component';
import { ClientsRoutes } from './clients.routes';
import { CliforComponent } from './clifor/clifor.component';

@NgModule({
  imports: [CommonModule, SharedModule, ClientsRoutes],
  declarations: [ClientsComponent, ClientsDetailsComponent, CliforComponent]
})
export class ClientsModule {}
