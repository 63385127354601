<mat-dialog-content>
  <h2 mat-dialog-title>Atualizar desconto?</h2>
  <form [formGroup]="form">
    <section>
      <mat-form-field>
        <mat-label>Desconto</mat-label>
        <input 
          matInput 
          formControlName="discount"
          [ngModel]="discountValue">
      </mat-form-field>
    </section>
    <mat-form-field>
      <mat-label>Justificativa</mat-label>
      <textarea 
        matInput
        formControlName="justification" 
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize" 
        cdkAutosizeMinRows="2"
        cdkAutosizeMaxRows="5">
      </textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button type="button" color="accent" (click)="onYesClick()">SIM</button>
  <button mat-button type="button" (click)="onNoClick()">NÃO</button>
</mat-dialog-actions>