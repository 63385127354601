<app-layout pageTitle="Seleção de Faturas" [showMenu]="false" [showBack]="true">
  <section class="loading" *ngIf="isLoadingGenerateDocumentsRequest">
    <section><mat-spinner strokeWidth="3"></mat-spinner></section>
    <section class="loading-label">Gerando fluxo de cobrança</section>
  </section>
  <section *ngIf="!isLoadingGenerateDocumentsRequest">
    <mat-form-field class="field" [formGroup]="form">
      <mat-label>Informe o próximo número da Nota Fiscal</mat-label>
      <input matInput formControlName="nfseNumber" (change)="setNFSENumber($event)">
    </mat-form-field>
    <mat-card>
      <app-search-box
        #searchBox
        backIcon="arrow_back"
        class="searchBox"
        placeholder="Buscar"
        (searchDebounce)="search($event)"
        flex
      ></app-search-box>
      <table mat-table [dataSource]="invoices">
        <ng-container *ngFor="let col of columns; trackBy: trackByColumns" [matColumnDef]="col.name">
          <ng-container *ngIf="verifyColumnName(col, 0)">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="toggleAll($event)" [checked]="selectAllOptionsIsChecked()"
                [indeterminate]="isIndeterminateState()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()"
                (change)="toggleRow($event, row)" [checked]="isChecked(row)">
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container *ngIf="isPipeNotNeeded(col)">
            <td mat-cell *matCellDef="let element">{{ element[col.name] }}</td>
          </ng-container>
          <ng-container *ngIf="verifyColumnName(col, 3)">
            <td mat-cell *matCellDef="let element">{{ element[col.name] | currency: 'BRL' }}</td>
          </ng-container>
          <ng-container *ngIf="verifyColumnName(col, 4)">
            <td mat-cell *matCellDef="let element">{{ element[col.name] | date: 'shortDate' }}</td>
          </ng-container>
          <th mat-header-cell *matHeaderCellDef>{{ col.label }}</th>
          <td mat-cell *matCellDef="let element">{{ element[col.name] }}</td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row class="table-row"
          *matRowDef="let row; columns: displayedColumns">
        </tr>
      </table>
      <section>
        <mat-paginator #pagingBar [pageSizeOptions]="pageSizeOptions" showFirstLastButtons [length]="totalOfInvoices"
          (page)="paginate($event)">
        </mat-paginator>
      </section>
      <button class="floatDefault" mat-fab (click)="generateDocuments()">
        <mat-icon>arrow_forward</mat-icon>
      </button>
    </mat-card>
  </section>
</app-layout>