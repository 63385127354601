import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@financial/arch';
import { Setting } from './settings';

@Injectable({ providedIn: 'root' })
export class SettingsRepository extends RepositoryImpl<Setting, Setting> {
  constructor(api: ApiService) {
    super(api.root.path('settings'), Setting, Setting);
  }
}
