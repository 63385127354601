<app-layout pageTitle="Configurações" [showSearch]="true" (searchChange)="list.search($event)">
  <section actions>
    <button
      mat-icon-button
      (click)="crud.drawerRight.toggle()"
      [matBadge]="perspective.value.length"
      [matBadgeHidden]="perspective.value.length == 0"
      matBadgeColor="warn"
      matBadgeSize="small"
    >
      <mat-icon svgIcon="filter"></mat-icon>
    </button>
  </section>
  <entity-crud
    #crud
    [functionality]="functionality"
    [repository]="repository"
    [entityTypes]="entityTypes"
    (entityChange)="list.invalidate()"
  >
    <entity-cards
      #list
      [repository]="repository"
      [perspective]="perspective.value"
      [summary]="false"
    >
      <mat-card *entityCard="let entity" (click)="crud.openEntity(entity.id)">
        <mat-card-header>
          <div matCardAvatar class="icon-avatar primary-bg product-by-lot-bg"><mat-icon>view_column</mat-icon></div>
            <div class="content">
              <mat-card-title>{{ entity.name }}</mat-card-title>
            </div>
        </mat-card-header>
        <mat-card-content>
          <section *ngFor="let assay of entity.assays; trackBy: trackByAssays">
            <mat-icon svgIcon="info"></mat-icon>
            {{ assay.analysisType.label }}
          </section>
        </mat-card-content>
      </mat-card>
    </entity-cards>
    <section drawerRight>
      <entity-list-perspective #perspective >
      </entity-list-perspective>
    </section>

    <app-settings-details
      *entityCrudDetailsContent="let crud"
      [state]="crud.state"
      [(entity)]="crud.entity"
      (submit)="crud.listeners.onSubmit($event)"
      (validChange)="crud.listeners.validityChange($event)"
      (dirtyChange)="crud.listeners.dirtyChange($event)"
    >
    </app-settings-details>
  </entity-crud>
</app-layout>
