<div class="bg fillPrimary">
  <img class="logo" src="/assets/images/logo_financial_white.svg" />
  <mat-card class="errorCard">
    <mat-card-title> <ng-content select=".title"></ng-content> </mat-card-title>
    <mat-card-content>
      <ng-content select=".content"></ng-content>
    </mat-card-content>
    <ng-content select=".actions"></ng-content>
  </mat-card>
</div>
