import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@financial/arch';
import { Service, ServiceSummary } from './service';

@Injectable({ providedIn: 'root' })
export class ServiceRepository extends RepositoryImpl<ServiceSummary, Service> {
  constructor(api: ApiService) {
    super(api.root.path('services'), ServiceSummary, Service);
  }
}
