import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonComponentsModule } from '@financial/common-components';
import { SharedThirdPartyModule } from '@financial/shared-third-party';

const EXPORTED_MODULES = [
  RouterModule,
  SharedThirdPartyModule,
  FormsModule,
  ReactiveFormsModule,
  CommonModule,
  CommonComponentsModule
];

@NgModule({
  imports: [EXPORTED_MODULES],
  exports: [EXPORTED_MODULES]
})
export class DefaultSharedModule {}
