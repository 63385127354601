import { DomainEntity, SgvId, SgvJson } from '@financial/arch';
import { PerClientDiscount } from '@financial/domain';
import { AnalysisSummary, Analysis } from '../analysis';
import { Client, ClientSummary } from './../clients/client';
import { BillableStatus } from './billable-status';
import { ProductItem } from './product-item';
import { ServiceItem } from './service-item';

export abstract class Billable implements DomainEntity {
  constructor(
    readonly id: string = SgvId.gen(),
    public client: ClientSummary = new ClientSummary(),
    public perClientDiscount: PerClientDiscount = null,
    public processedDate: Date = null,
    public total: number = 0,
    public discount: number = 0,
    public billableStatus: BillableStatus = null,
    public readonly isActive = true,
    public recipeNumber: number = 0,
    public clientToBill: ClientSummary = new ClientSummary()
  ) { }

  get totalBillableValue() {
    return this.total;
  }

  get statusLabel(): string {
    return this.billableStatus.label;
  }

  get clientName(): string {
    return this.client.name;
  }

  get clientToBillName(): string {
    return this.clientToBill.name;
  }

  abstract get protocol(): string;

  abstract get requisitionNumber(): string;

  abstract get name(): string;

  abstract get date(): Date;

  abstract get isBillableAnalysis(): boolean;

  abstract get isBillableSingleRequisition(): boolean;

  abstract get isBillableAgreement(): boolean;

  abstract toJson(): any;

  static fromJson(json: any): Billable {
    if (!(json || json.type)) {
      return null;
    }

    switch (json.type) {
      case 'billableAnalysis':
        return BillableAnalysis.fromJson(json);
      case 'billableSingleRequisition':
        return BillableSingleRequisition.fromJson(json);
      case 'billableAgreement':
        return BillableAgreement.fromJson(json);
      default:
        throw new Error('Billable type not mapped');
    }
  }
}

export class BillableAnalysis extends Billable {
  constructor(
    readonly id: string = SgvId.gen(),
    public client: ClientSummary = new ClientSummary(),
    public perClientDiscount: PerClientDiscount = null,
    public processedDate: Date = null,
    public total: number = 0,
    public discount: number = 0,
    public analysis: AnalysisSummary = new AnalysisSummary(),
    public serviceItems: ServiceItem[] = [],
    public billableStatus: BillableStatus = null,
    public signed: boolean = false,
    public readonly isActive = true
  ) {
    super(id, client, perClientDiscount, processedDate, total, discount)
  }

  get protocol(): string {
    return this.analysis.protocol;
  }

  get requisitionNumber(): string {
    return "";
  }

  get isBillableAnalysis(): boolean {
    return true;
  }

  get isBillableSingleRequisition(): boolean {
    return false;
  }

  get isBillableAgreement(): boolean {
    return false;
  }

  get name(): string {
    return this.serviceItems.map((it) => it.service.name).join(" / ")
  }

  get date(): Date {
    return this.analysis.entryDate;
  }

  toJson() {
    return SgvJson.to.simple(this, {
      type: 'billableAnalysis',
      client: this.client ? this.client.toJson() : null,
      analysis: this.analysis ? this.analysis.toJson() : null,
      serviceItems: SgvJson.to.array(this.serviceItems),
      status: this.billableStatus ? this.billableStatus.name : null,
      clientToBill: this.clientToBill ? this.clientToBill.toJson() : null
    })
  }

  static fromJson(json: any): BillableAnalysis {
    return json
      ? SgvJson.from.simple(json, BillableAnalysis, {
        client: json.client ? ClientSummary.fromJson(json.client) : null,
        analysis: json.analysis ? Analysis.fromJson(json.analysis) : null,
        serviceItems: SgvJson.from.array(json.serviceItems, ServiceItem.fromJson),
        billableStatus: json.status ? BillableStatus.get(json.status) : null,
        clientToBill: json.clientToBill ? Client.fromJson(json.clientToBill) : null
      })
      : null
  }

}

export class BillableSingleRequisition extends Billable {
  constructor(
    readonly id: string = SgvId.gen(),
    public client: ClientSummary = new ClientSummary(),
    public perClientDiscount: PerClientDiscount = null,
    public processedDate: Date = null,
    public total: number = 0,
    public discount: number = 0,
    public productItems: ProductItem[] = [],
    public billableStatus: BillableStatus = null,
    public requisition: number = 0,
  ) {
    super(id, client, perClientDiscount, processedDate, total, discount);
  }

  get protocol(): string {
    return "";
  }

  get requisitionNumber(): string {
    return this.requisition.toString();
  }

  get isBillableAnalysis(): boolean {
    return false;
  }

  get isBillableSingleRequisition(): boolean {
    return true;
  }

  get isBillableAgreement(): boolean {
    return false;
  }

  get name(): string {
    return this.productItems.map((it) => it.product.name).join(" / ")
  }

  get date(): Date {
    return this.processedDate;
  }

  toJson() {
    return SgvJson.to.simple(this, {
      type: 'billableSingleRequisition',
      client: this.client ? this.client.toJson() : null,
      productItems: this.productItems ? SgvJson.to.array(this.productItems) : null,
      status: this.billableStatus ? this.billableStatus.name : null,
      clientToBill: this.clientToBill ? this.clientToBill.toJson() : null
    })
  }

  static fromJson(json: any): BillableSingleRequisition {
    return json
      ? SgvJson.from.simple(json, BillableSingleRequisition, {
        client: json.client ? Client.fromJson(json.client) : null,
        productItems: json.productItems ? SgvJson.from.array(json.productItems, ProductItem.fromJson) : null,
        processedDate: json.processedDate ? new Date(json.processedDate) : null,
        billableStatus: json.status ? BillableStatus.get(json.status) : null,
        clientToBill: json.clientToBill ? Client.fromJson(json.clientToBill) : null
      })
      : null
  }
}

export class BillableAgreement extends Billable {

  constructor(
    readonly id: string = SgvId.gen(),
    public client: ClientSummary = new ClientSummary(),
    public perClientDiscount: PerClientDiscount = null,
    public processedDate: Date = null,
    public total: number = 0,
    public discount: number = 0,
    public invoiceNumber: number = 0,
    public value: number = 0,
    public billableStatus: BillableStatus = null
  ) {
    super(id, client, perClientDiscount, processedDate, total, discount);
  }

  get protocol(): string {
    return "";
  }

  get requisitionNumber(): string {
    return "";
  }

  get name(): string {
    return `Gerado a partir da fatura nº ${this.invoiceNumber}`;
  }

  get date(): Date {
    return this.processedDate;
  }

  get isBillableAnalysis(): boolean {
    return false;
  }

  get isBillableSingleRequisition(): boolean {
    return false;
  }

  get isBillableAgreement(): boolean {
    return true;
  }

  toJson() {
    return SgvJson.to.simple(this, {
      type: 'billableAgreement',
      client: this.client ? this.client.toJson() : null,
      status: this.billableStatus ? this.billableStatus.name : null,
      clientToBill: this.clientToBill ? this.clientToBill.toJson() : null
    })
  }

  static fromJson(json: any): BillableAgreement {
    return json
      ? SgvJson.from.simple(json, BillableAgreement, {
        client: json.client ? Client.fromJson(json.client) : null,
        processedDate: json.processedDate ? new Date(json.processedDate) : null,
        billableStatus: json.status ? BillableStatus.get(json.status) : null,
        clientToBill: json.clientToBill ? Client.fromJson(json.clientToBill) : null
      })
      : null
  }
}