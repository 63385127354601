<app-layout-base
  [fillPrimary]="fillPrimary"
  [pageTitle]="pageTitle"
  [showSearch]="showSearch"
  [searchPlaceholder]="searchPlaceholder"
  [showMenu]="showMenu"
  [showBack]="showBack"
  [homePath]="homePath"
  [onSideBackClick]="onSideBackClickFn"
  (searchChange)="searchChange.emit($event)"
>
  <ng-container menuToolbarActions>
     </ng-container>
  <ng-container menu>
    <app-main-menu
      *ngIf="!submenu"
      [@menuAnimation]
      [menu]="mainMenu"
      (submenuClick)="onSubmenuClick($event)"
    ></app-main-menu>
    <app-sub-menu *ngIf="submenu" [@menuAnimation] [menu]="submenu"></app-sub-menu>
  </ng-container>
  <ng-container actions>
    <ng-content select="[actions]"></ng-content>
  </ng-container>
  <ng-content></ng-content>
</app-layout-base>
