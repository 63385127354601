import { SgvJson } from '@financial/arch';
import { NumberMask } from '@financial/common-utils';

export abstract class Documents {
  abstract get cnp(): string;

  abstract get maskedCnp(): string;

  abstract get corporateName(): string;

  abstract toJson();

  abstract isNaturalPerson(): boolean;

  abstract isLegalPerson(): boolean;

  static fromJson(json: any): Documents {
    if (!json) {
      return null;
    }
    switch (json.type) {
      case 'naturalPerson':
        return NaturalPersonDocuments.fromJson(json);
      case 'legalEntity':
        return LegalEntityDocuments.fromJson(json);
      default:
        return null;
    }
  }
}

export class NaturalPersonDocuments extends Documents {
  
  get corporateName(): string {
    return "";
  }

  constructor(public cpf = '', public rg = '') {
    super();
  }

  get cnp() {
    return this.cpf;
  }

  get maskedCnp() {
    return NaturalPersonDocuments.MASK.apply(this.cpf);
  }

  static readonly MASK = new NumberMask('999.999.999-99');

  isNaturalPerson(): boolean {
    return true;
  }

  isLegalPerson(): boolean {
    return false;
  }

  toJson() {
    return SgvJson.to.simple(this, { type: 'naturalPerson' });
  }

  static fromJson(json: any): NaturalPersonDocuments {
    if (!json) {
      return null;
    }
    return SgvJson.from.simple(json, NaturalPersonDocuments);
  }
}

export class LegalEntityDocuments extends Documents {
  constructor(public cnpj = '', public corporateName = '', public ie = '', public im = '') {
    super();
  }

  get cnp() {
    return this.cnpj;
  }

  get maskedCnp() {
    return LegalEntityDocuments.MASK.apply(this.cnpj);
  }

  static readonly MASK = new NumberMask('99.999.999/9999-99');

  isNaturalPerson(): boolean {
    return false;
  }

  isLegalPerson(): boolean {
    return true;
  }

  toJson() {
    return SgvJson.to.simple(this, { type: 'legalEntity' });
  }

  static fromJson(json: any): LegalEntityDocuments {
    if (!json) {
      return null;
    }
    return SgvJson.from.simple(json, LegalEntityDocuments);
  }
}
