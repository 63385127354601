import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService, Filter, InterceptorConfig, PageData, RepositoryImpl, RequestMetadata, RequestMetadataUtils, UrlSearchParamsBuilder } from "@financial/arch";
import { retryExceptOnClientError } from '@financial/common-utils';
import { Invoice } from '@financial/domain';
import { map, Observable, retryWhen } from 'rxjs';
import { InvoiceDataClifor } from './clifor/InvoiceDataClifor';

@Injectable({ providedIn: 'root' })
export class ExportRepository extends RepositoryImpl<InvoiceDataClifor, Invoice> {
  
  private readonly CLIFOR = 'clifor';
  private readonly SINGLE = 'single';
  private readonly MOVEMENT = 'movement';

  constructor(api: ApiService) {
    super(api.root.path('export').path('txt'), InvoiceDataClifor, Invoice);
  }

  downloadClifors(): Observable<Blob> {
    return this.api.path(this.CLIFOR).path('all').getBlob();
  }

  downloadCliforBy(id: string): Observable<Blob> {
    return this.api.path(this.CLIFOR).path(this.SINGLE).path(id).getBlob();
  }

  downloadFinancialMovements(): Observable<Blob> {
    return this.api.path(this.MOVEMENT).path('all').getBlob();
  }

  downloadFinancialMovementBy(id: string): Observable<Blob> {
    return this.api.path(this.MOVEMENT).path(this.SINGLE).path(id).getBlob();
  }

  listCliforAndFinancialMovement(
    pageData: PageData,
    filters: Filter[] = [],
    dateInterval: DateInterval = null,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<InvoiceDataClifor[]> {
    let dateIntervalParams = new HttpParams();
    if (dateInterval) {
      dateIntervalParams = dateIntervalParams
        .append('intervalStart', dateInterval.intervalStart)
        .append('intervalEnd', dateInterval.intervalEnd)
        .append('intervalType', dateInterval.intervalType);
    }
    
    const params = new UrlSearchParamsBuilder(dateIntervalParams)
      .page(pageData)
      .filters(filters)
      .actives()
      .build();
      
    return this.api
      .path('data').getJson<InvoiceDataClifor[]>({
        params: params,
        metadata: RequestMetadataUtils.merge(metadata, this.metadata)
      })
      .pipe(
        map((invoiceDataClifors) =>
          invoiceDataClifors
            .map((invoiceDataClifor: InvoiceDataClifor) =>
              InvoiceDataClifor.fromJson(invoiceDataClifor)
            )
        ),
        retryWhen(retryExceptOnClientError())
      );
  }

}

interface DateInterval {
  intervalStart: string,
  intervalEnd: string,
  intervalType: string
}