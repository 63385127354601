import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'print-header',
  templateUrl: './print-header.component.html',
  styleUrls: ['./print-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintHeaderComponent {

  @Input() title: string;

  constructor() { }

}
