import { Item } from 'libs/domain/src/lib/invoices/item';
import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InvoiceDetails } from '@financial/domain';
import { Billable } from 'libs/domain/src/lib/billables';

@Component({
  selector: 'app-invoices-details',
  templateUrl: './invoice-details-dialog.component.html',
  styleUrls: ['./invoice-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceDetailsDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<InvoiceDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _entity: InvoiceDetails
  ) { }

  get entity() {
    return this._entity;
  }

  onPrintClick() {
    window.print();
  }

  onBackClick() {
    this.dialogRef.close();
  }

  trackByFn(index: any, entity: Item): Billable {
    return entity.billable;
  }

}
