import { DomainEntity, SgvId, SgvJson } from "@financial/arch";
import { Client, ClientSummary } from "../clients";

export abstract class Discount implements DomainEntity {

  static PER_CLIENT_TYPE = 'perClient';

  static PER_AMOUNT_TYPE = 'perAmount';
  
  constructor(
    readonly id: string = SgvId.gen(),
    public name: string = '',
    public value: number = 0,
    public expiration: Date = null,
    public readonly isActive = true,
    public isExpired: boolean = false
  ) { }

  abstract toJson(): any

  abstract get isPerClientDiscount(): boolean

  abstract get isPerAmountDiscount(): boolean

  static fromJson(json: any): Discount {
    if (!(json || json.type)) {
      return null;
    }

    switch (json.type) {
      case Discount.PER_CLIENT_TYPE:
        return PerClientDiscount.fromJson(json);
      case Discount.PER_AMOUNT_TYPE:
        return PerAmountDiscount.fromJson(json);
      default:
        throw new Error('Discount type not mapped');
    }
  }
}

export class PerClientDiscount extends Discount {

  constructor(
    readonly id: string = SgvId.gen(),
    public name: string = '',
    public value: number = 0,
    public expiration: Date = null,
    public client: ClientSummary = new ClientSummary(),
    public readonly isActive = true
  ) {
    super(id, name, value, expiration, isActive)
   }

  get isPerClientDiscount(): boolean {
    return true;
  }
  get isPerAmountDiscount(): boolean {
    return false;
  }

  toJson() {
   return SgvJson.to.simple(this, {
     type: PerClientDiscount.PER_CLIENT_TYPE,
     client: this.client ? this.client.toJson(): null
   })
  }

  static fromJson(json: any): PerClientDiscount {
    return json
    ? SgvJson.from.simple(json, PerClientDiscount, {
      client: json.client ? ClientSummary.fromJson(json.client) : null
    })
    : null
  }

}

export class PerAmountDiscount extends Discount {
  constructor(
    readonly id: string = SgvId.gen(),
    public name: string = '',
    public value: number = 0,
    public expiration: Date = null,
    public amount: number = 0,
    public readonly isActive = true
  ) {
    super(id, name, value, expiration, isActive)
   }

  get isPerClientDiscount(): boolean {
    return false;
  }
  get isPerAmountDiscount(): boolean {
    return true;
  }

  toJson(): any {
   return SgvJson.to.simple(this, {
     type: PerAmountDiscount.PER_AMOUNT_TYPE,
   })
  }

  static fromJson(json: any): PerAmountDiscount {
    return json ? SgvJson.from.simple(json, PerAmountDiscount) : null;
  }

}
