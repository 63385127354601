<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form">
  <mat-tab-group #group>
      <mat-tab label="Dados Gerais" [swipeTab]="group">
        <fieldset class="basicData first">
          <section>
            <mat-form-field class="name">
              <mat-label>Nome</mat-label> <input matInput formControlName="name" />
            </mat-form-field>
            <mat-form-field class="code">
              <mat-label>Código</mat-label> <input matInput formControlName="code" />
            </mat-form-field>
            <mat-form-field class="value">
              <mat-label>Valor</mat-label> <input matInput currencyMask formControlName="value" />
            </mat-form-field>
            <app-data-selector label="Serviço Eceos" [repository]="eceosOperatableRepository" formControlName="eceosOperatable">
            </app-data-selector>
          </section>
        </fieldset>
    </mat-tab>
  </mat-tab-group>
</form>
