import { Injectable } from '@angular/core';
import { ApiService, Filter, InterceptorConfig, RepositoryImpl, RequestMetadata, UrlSearchParamsBuilder } from '@financial/arch';
import { map, Observable, retryWhen } from 'rxjs';
import { retryExceptOnClientError } from './../../../../common-utils/src/lib/operators/custom-operators';
import { Invoice } from './invoice';
import { InvoiceActionRequest } from './invoice-action-request';
import { InvoiceDetails } from './invoice-details';
import { InvoiceFinancialAgreement } from './Invoice-financial-agreement';
import { InvoicePermissionsStatus } from './invoice-permissions-status';
import { InvoiceReport } from './invoice-report';
import { InvoicesIdsAndNFSENumber } from './invoices-ids-and-nfse-number';
import { OverdueOrPartiallyPaidInvoicesByClient } from './items-by-client';

@Injectable({ providedIn: 'root' })
export class InvoicesRepository extends RepositoryImpl<Invoice, Invoice> {
  constructor(api: ApiService) {
    super(api.root.path('invoices'), Invoice, Invoice);
  }

  sendInvoicesIdsToGenerateXls(invoicesId: String[]): Observable<void> {
    return this.api.path("xls").path("export").postJson(invoicesId);
  }

  getXls(): Observable<Blob> {
    return this.api.path("xls").path("export").getBlob();
  }

  sumInvoicesTotal(clientId: string, metadata: RequestMetadata =
    { autoCatch: InterceptorConfig.NO_INTERCEPT }): Observable<number> {
    return this.api
      .path("balance")
      .path(clientId)
      .path("total")
      .getJson({ metadata: metadata });
  }

  sumOverdueInvoices(clientId: string, metadata: RequestMetadata =
    { autoCatch: InterceptorConfig.NO_INTERCEPT }): Observable<number> {
    return this.api
      .path("balance")
      .path(clientId)
      .path("overdue")
      .getJson({ metadata: metadata });
  }

  sumFreeToUseBillables(clientId: string, metadata: RequestMetadata =
    { autoCatch: InterceptorConfig.NO_INTERCEPT }): Observable<number> {
    return this.api
      .path("balance")
      .path(clientId)
      .path("pending")
      .getJson({ metadata: metadata });
  }


  size(
    query: string = '',
    filters: Filter[] = [],
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.NO_INTERCEPT }): Observable<number> {
    const search = new UrlSearchParamsBuilder()
      .query(query)
      .filters(filters)
      .build()
    return this.api
      .path("size")
      .getJson({ params: search, metadata: metadata });
  }

  getDetails(invoiceId: string, metadata: RequestMetadata =
    { autoCatch: InterceptorConfig.NO_INTERCEPT }): Observable<InvoiceDetails> {
    return this.api
      .path(invoiceId)
      .path('details')
      .getJson({ metadata: metadata }).pipe(map((r) => InvoiceDetails.fromJson(r)), retryWhen(retryExceptOnClientError()));
  }

  getReport(invoiceId: string, metadata: RequestMetadata =
    { autoCatch: InterceptorConfig.NO_INTERCEPT }): Observable<InvoiceReport> {
    return this.api
      .path(invoiceId)
      .path('reports')
      .getJson({ metadata: metadata }).pipe(map((r) => InvoiceReport.fromJson(r)), retryWhen(retryExceptOnClientError()));
  }

  getPermissions(invoice: Invoice, metadata: RequestMetadata =
    { autoCatch: InterceptorConfig.NO_INTERCEPT }): Observable<InvoicePermissionsStatus[]> {
    return this.api
      .path(invoice.id)
      .path('permissions')
      .getJson({ metadata: metadata }).pipe(map((r: InvoicePermissionsStatus[]) => r), retryWhen(retryExceptOnClientError()));
  }

  invoiceActionRequest(invoice: Invoice, action: InvoiceActionRequest): Observable<void> {
    return this.api.path(invoice.id).patchJson(action);
  }

  getBankSlip(invoice: Invoice): Observable<Blob> {
    return this.api.path(invoice.id).path('bankSlip').getBlob();
  }

  generateFinancialAgreementInvoice(invoice: InvoiceFinancialAgreement): Observable<void> {
    return this.api.path("financialAgreement").postJson(invoice);
  }

  generateBankslipAndNFSE(idsAndRPSLot: InvoicesIdsAndNFSENumber): Observable<void> {
    return this.api.path("documents").postJson(idsAndRPSLot);
  }

  findAllGroupByClient(metadata: RequestMetadata = { autoCatch: InterceptorConfig.NO_INTERCEPT }): Observable<OverdueOrPartiallyPaidInvoicesByClient[]> {
    return this.api
      .path('clients')
      .getJson<any[]>({ metadata: metadata })
      .pipe(
        map((r) => r.map((j: any) => OverdueOrPartiallyPaidInvoicesByClient.fromJson(j))),
        retryWhen(retryExceptOnClientError())
      )
  }
  
  reprocessBankSlips(invoicesIds: string[]): Observable<void> {
    return this.api.path("bankSlips").path("reprocessing").postJson(invoicesIds);
  }
}
