import { DomainEntity, ReadonlyRepository } from '@financial/arch';

export enum FilterType {
  TEXTUAL,
  NUMERIC,
  BOOLEAN,
  DATE,
  DATE_TIME,
  REFERENCE,
  ENUM
}
export class FilterDescription {
  constructor(
    public readonly field: string,
    public readonly name: string,
    public readonly type: FilterType
  ) {}
}

export class TextualFilterDescription extends FilterDescription {
  constructor(field: string, name: string) {
    super(field, name, FilterType.TEXTUAL);
  }
}

export class NumericFilterDescription extends FilterDescription {
  constructor(field: string, name: string) {
    super(field, name, FilterType.NUMERIC);
  }
}

export class BooleanFilterDescription extends FilterDescription {
  constructor(field: string, name: string) {
    super(field, name, FilterType.BOOLEAN);
  }
}

export class RefFilterDescription<T extends DomainEntity> extends FilterDescription {
  constructor(field: string, name: string, public readonly repository: ReadonlyRepository<T, any>) {
    super(field, name, FilterType.REFERENCE);
  }
}

export class EnumFilterDescription<T> extends FilterDescription {
  constructor(field: string, name: string, public readonly values: T[]) {
    super(field, name, FilterType.ENUM);
  }
}
