export class IntervalInvoiceType {
  public static FINALIZATION_DATE = new IntervalInvoiceType("Baixa boleto", "FINALIZATION_DATE");
  public static SEND_FUNAPE = new IntervalInvoiceType("Enviado Funape", "SEND_FUNAPE_DATE");
  public static EMISSION_DATE = new IntervalInvoiceType("Emissão", "EMISSION_DATE");

  public constructor(
    public readonly label: string,
    public readonly value: string
  ) { }

  public static values(): IntervalInvoiceType[] {
    return [
      IntervalInvoiceType.SEND_FUNAPE,
      IntervalInvoiceType.FINALIZATION_DATE,
      IntervalInvoiceType.EMISSION_DATE
    ]
  }

  public static get(value: string): IntervalInvoiceType {
    const intervalType = IntervalInvoiceType.values().find(v => v.value === value);
    if (!intervalType) {
      const err = new Error(`${value} not mapped!`);
      console.error(err);
      throw err;
    }
    return intervalType;
  }
}