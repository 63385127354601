import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { EntityCrudState } from '@financial/common-components';
import { ClientsRepository } from '@financial/domain';
import {
  Billable,
  BillableAgreement,
  BillableAnalysis,
  BillableRepository,
  BillableSingleRequisition
} from 'libs/domain/src/lib/billables';
import { BillableStatus } from 'libs/domain/src/lib/billables/billable-status';
import { ProductItem } from 'libs/domain/src/lib/billables/product-item';
import { ServiceItem } from 'libs/domain/src/lib/billables/service-item';
import { debounceTime, distinctUntilChanged, lastValueFrom, map } from 'rxjs';

@Component({
  selector: 'app-billables-details',
  templateUrl: './billables-details.component.html',
  styleUrls: ['./billables-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillablesDetailsComponent implements OnInit {
  form: FormGroup;

  @Output() entityChange = new EventEmitter<Billable>();

  @Output() validChange = new EventEmitter<boolean>();

  @Output() dirtyChange = new EventEmitter<boolean>();

  private _state: EntityCrudState;

  private _valid = true;

  private _dirty = false;

  private _showActionsButtons = false;

  private _disableDataSelector = true;

  private _entityToUpdate: Billable;

  constructor(
    private fb: FormBuilder,
    private changeRef: ChangeDetectorRef,
    private repository: BillableRepository,
    private route: ActivatedRoute,
    public clientsRepository: ClientsRepository,
    private dialogRef: MatDialogRef<BillablesDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) private _entity: Billable
  ) {
    this._state = EntityCrudState.VIEW;
    this._entityToUpdate = this._entity;
    if (this.billableStatusIsFreeToUse) {
      this._showActionsButtons = true;
    }
  }

  get isAnalysis(): boolean {
    return this._entity && this._entity.isBillableAnalysis;
  }

  get isSingleRequisition(): boolean {
    return this._entity && this._entity.isBillableSingleRequisition;
  }

  get isAgreement(): boolean {
    return this._entity && this._entity.isBillableAgreement;
  }

  get serviceItemsFormArrayControls(): AbstractControl[] {
    return this.serviceItems().controls;
  }

  get productItemsFormArrayControls(): AbstractControl[] {
    return this.productItems().controls;
  }

  get isEntityCrudEditState(): boolean {
    return this._state === EntityCrudState.EDIT;
  }

  get isEntityCrudViewState(): boolean {
    return this._state === EntityCrudState.VIEW;
  }

  get billableStatusIsFreeToUse(): boolean {
    return this._entity.billableStatus === BillableStatus.FREE_TO_USE;
  }

  get showActionsButtons(): boolean {
    return this._showActionsButtons;
  }

  get canUpdateBillable(): boolean {
    return this.isEntityCrudEditState;
  }

  get clientCnpIsCNPJ(): boolean {
    return this._entity.client.cnp.length > 11 ? true : false;
  }

  get disableDataSelector(): boolean {
    return this._disableDataSelector;
  }

  ngOnInit(): void {
    this.createFormFromEntity();
    this.changeRef.markForCheck();
  }

  addFormValueChange(mapper: (f: any) => Billable): void {
    this.form.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(300), map(mapper))
      .subscribe((value) => {
        this.entityChange.emit((this._entity = value));
      });
  }

  onBackClick(): void {
    this.dialogRef.close();
  }

  onCancel(): void {
    this.changeDialogStateToView();
  }

  onEdit(): void {
    this._state = EntityCrudState.EDIT;
    this._disableDataSelector = false;
    this.syncFromState();
    this.changeRef.markForCheck();
  }

  async onUpdate(): Promise<void> {
    this._entityToUpdate.clientToBill = this.clientToBillFormControl().value;
    await lastValueFrom(this.repository.update(this._entityToUpdate));
    this.changeDialogStateToView();
    this.changeRef.markForCheck();
  }

  trackByControlValueId(_: any, control: AbstractControl) {
    return control.value.id;
  }

  private changeDialogStateToView(): void {
    this._state = EntityCrudState.VIEW;
    this._disableDataSelector = true;
  }

  private createFormFromEntity(): void {
    this.form = null;
    this.changeRef.markForCheck();
    if (this._entity) {
      const baseForm = {
        client: this.fb.group({
          name: this._entity.client.name,
          code: this._entity.client.code,
          cnp: this._entity.client.cnp
        }),
        processedDate: this._entity.processedDate,
        total: this._entity.total,
        discount: this._entity.discount,
        status: this._entity.billableStatus.label,
        recipeNumber: this._entity.recipeNumber,
        clientToBill: [this._entity.clientToBill]
      };
      if (this.isAnalysis) {
        const billable = this._entity as BillableAnalysis;
        this.form = this.fb.group({
          ...baseForm,
          analysis: this.fb.group({
            protocol: billable.analysis.protocol,
            signed: billable.analysis.signed,
            entryDate: billable.analysis.entryDate
          }),
          serviceItems: this.fb.array(
            billable.serviceItems.map((item: ServiceItem) => this.serviceItemsControl(item))
          )
        });
        this.addFormValueChange(
          (analysis: BillableAnalysis) =>
            new BillableAnalysis(
              this._entity.id,
              analysis.client,
              analysis.perClientDiscount,
              analysis.processedDate,
              analysis.total,
              analysis.discount,
              analysis.analysis,
              analysis.serviceItems?.map(
                (item: ServiceItem) => new ServiceItem(item.service, item.analysisType, item.amount)
              ),
              analysis.billableStatus,
              analysis.signed
            )
        );
      } else if (this.isSingleRequisition) {
        const billable = this._entity as BillableSingleRequisition;
        this.form = this.fb.group({
          ...baseForm,
          requisition: billable.requisition,
          productItems: this.fb.array(
            billable.productItems.map((item: ProductItem) => this.productItemsControl(item))
          )
        });
        this.addFormValueChange(
          (singleRequisition: BillableSingleRequisition) =>
            new BillableSingleRequisition(
              this._entity.id,
              singleRequisition.client,
              singleRequisition.perClientDiscount,
              singleRequisition.processedDate,
              singleRequisition.total,
              singleRequisition.discount,
              singleRequisition.productItems?.map(
                (item: ProductItem) => new ProductItem(item.product, item.amount)
              ),
              singleRequisition.billableStatus,
              singleRequisition.requisition
            )
        );
      } else if (this.isAgreement) {
        this.form = this.fb.group({
          ...baseForm
        });
        this.addFormValueChange(
          (agreement: BillableAgreement) =>
            new BillableAgreement(
              this._entity.id,
              agreement.client,
              agreement.perClientDiscount,
              agreement.processedDate,
              agreement.total,
              agreement.discount,
              agreement.invoiceNumber,
              agreement.value,
              agreement.billableStatus
            )
        );
      }
    }
    if (this.form) {
      this.form.statusChanges.subscribe((s: any) => {
        if (this.form.dirty !== this._dirty) {
          this.dirtyChange.emit((this._dirty = this.form.dirty));
        }
        if (this.form.valid !== this._valid) {
          this.validChange.emit((this._valid = this.form.valid));
        }
      });
    }
    this.syncFromState();
  }

  private syncFromState(): void {
    if (this.form) {
      if (this.canUpdateBillable) {
        this.form.enable();
      }
      Object.values(this.form.controls)
        .filter((control: AbstractControl) => control !== this.clientToBillFormControl())
        .forEach((it: AbstractControl) => it.disable());
    }
  }

  private serviceItemsControl(item: ServiceItem): FormGroup {
    return this.fb.group({
      service: this.fb.group({
        name: item.service.name,
        code: item.service.code,
        value: item.service.value
      }),
      analysisType: item.analysisType.label,
      amount: [item.amount]
    });
  }

  private productItemsControl(item: ProductItem): FormGroup {
    return this.fb.group({
      product: this.fb.group({
        name: item.product.name,
        code: item.product.code,
        value: item.product.value
      }),
      amount: [item.amount]
    });
  }

  private serviceItems(): FormArray {
    return this.form.get('serviceItems') as FormArray;
  }

  private productItems(): FormArray {
    return this.form.get('productItems') as FormArray;
  }

  private clientToBillFormControl(): FormControl {
    return this.form.get('clientToBill') as FormControl;
  }
}
