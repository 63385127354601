export class BillableStatus {

  public static IN_USE = new BillableStatus("IN_USE", "EM USO");
  public static FREE_TO_USE = new BillableStatus("FREE_TO_USE", "LIBERADA PARA USO");
  public static INVALID = new BillableStatus("INVALID", "INVÁLIDA");
  public static FINALIZED = new BillableStatus("FINALIZED", "FINALIZADA");

  public constructor(
    public readonly name: string,
    public readonly label: string
  ) { }

  public static values(): BillableStatus[] {
    return [
      BillableStatus.IN_USE,
      BillableStatus.FREE_TO_USE,
      BillableStatus.INVALID,
      BillableStatus.FINALIZED
    ];
  }

  public static get(name: string): BillableStatus {
    const billableStatus = BillableStatus.values().find(v => v.name === name);
    if (!billableStatus) {
      const err = new Error(`${name} Status is not mapped!`);
      throw err;
    }
    return billableStatus;
  }
}