export class AnalysisType {

  public static PHYSICOCHEMICAL = new AnalysisType("PHYSICOCHEMICAL", "Físico-Químico");
  public static UREIC_NITROGEN = new AnalysisType("UREIC_NITROGEN", "Nitrogênio Ureico");
  public static CASEIN = new AnalysisType("CASEIN", "Caseina");
  public static CPP = new AnalysisType("CPP", "CPP");
  public static CRYOSCOPY = new AnalysisType("CRYOSCOPY", "Crioscopia");
  public static MICROBIOLOGICAL_INHIBITOR = new AnalysisType("MICROBIOLOGICAL_INHIBITOR", "Inibidor Microbiológico");

  public constructor(
    public readonly name: string,
    public readonly label: string
  ) { }

  public static values(): AnalysisType[] {
    return [
      AnalysisType.PHYSICOCHEMICAL,
      AnalysisType.UREIC_NITROGEN,
      AnalysisType.CASEIN,
      AnalysisType.CPP,
      AnalysisType.CRYOSCOPY,
      AnalysisType.MICROBIOLOGICAL_INHIBITOR,

    ];
  }

  public static get(name: string): AnalysisType {
    const analysisType = AnalysisType.values().find(v => v.name == name);
    if (!analysisType) {
      const err = new Error(`${name} Status is not mapped!`);
      console.error(err);
      throw err;
    }
    return analysisType;
  }
}
