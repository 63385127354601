import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { PermissionService } from '@financial/domain';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { MenuGroup, MenuItem, SubMenu } from '../../../features/features-catalog';

interface EntryGroup {
  name: string;
  childs: Entry[];
  source: MenuGroup;
}

interface Entry {
  name: string;
  icon: IconName;
  path?: string;
  childs?: (MenuGroup | MenuItem)[];
  isSubmenu: boolean;
  source: MenuItem | SubMenu;
  details: string;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent implements OnInit, OnDestroy {
  @Output() submenuClick = new EventEmitter<SubMenu>();

  entries: (EntryGroup | Entry)[] = [];

  private _menu: (MenuGroup | MenuItem)[] = [];

  constructor(private permissions: PermissionService, private changeDetector: ChangeDetectorRef) {}

  get menu() {
    return this._menu;
  }

  @Input() set menu(value: (MenuGroup | MenuItem)[]) {
    this._menu = value ? value : [];
    this.refreshMenu();
  }

  ngOnInit() {
    this.permissions.$change.pipe(untilDestroyed(this)).subscribe((v) => this.refreshMenu());
  }

  refreshMenu() {
    this.entries = this.buildFromGroup(this.menu);
    this.changeDetector.markForCheck();
  }

  trackByFn(index: Number, entry: any) {
    return entry.feature || entry.name;
  }

  onSubmenuClick(entry: Entry) {
    if (entry.source instanceof SubMenu) {
      this.submenuClick.emit(entry.source);
    }
  }

  ngOnDestroy() {}

  private buildFromGroup(value: (MenuItem | MenuGroup)[]): (Entry | EntryGroup)[] {
    return value
      .map((v) =>
        v instanceof MenuGroup ? this.menuGroupToEntryGroup(v) : this.menuItemToEntry(v)
      )
      .filter((v) => !v.childs || v.childs.length > 0);
  }

  private buildFromItem(value: (MenuItem | SubMenu)[]): Entry[] {
    return value
      .filter((v) => this.userCanAccess(v))
      .map((v) => (v instanceof SubMenu ? this.submenuToEntry(v) : this.menuItemToEntry(v)));
  }

  private menuGroupToEntryGroup(v: MenuGroup): EntryGroup {
    return {
      name: v.name,
      childs: this.buildFromItem(v.childs),
      source: v
    };
  }

  private submenuToEntry(v: SubMenu): Entry {
    return {
      icon: v.icon,
      name: v.name,
      details: v.details,
      source: v,
      isSubmenu: true,
      childs: v.childs
    };
  }

  private menuItemToEntry(v: MenuItem): Entry {
    return {
      icon: v.icon,
      name: v.name,
      details: v.details,
      source: v,
      isSubmenu: false,
      path: v.path
    };
  }

  private userCanAccess(item: MenuItem | SubMenu | MenuGroup) {
    if (item instanceof SubMenu) {
      return true;
    } else if (item instanceof MenuGroup) {
      return true;
    } else if (item instanceof MenuItem) {
      return true;
    } else {
      return true;
    }
  }
}
