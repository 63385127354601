import { ChangeDetectionStrategy, Component } from "@angular/core";
import { DateInterval } from "@financial/arch";
import { ChartType, FlasksChartRepository } from '@financial/domain';

@Component({
    selector: 'ufg-flasks-chart',
    templateUrl: './flasks-chart.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlasksChartComponent {
    private readonly _chartType: string = ChartType.HORIZONTAL_BAR;
    private readonly _chartBackgroundcolorDataset: string[] = ['#1E3A76', '#1E3A76', '#1E3A76'];
    private readonly _title: string = "Quantidade de Frascos por Período";
    private readonly _chartId: string = "flasksChart";
    private readonly _interval: DateInterval = DateInterval.ofMonth(new Date());

    constructor(
        private flasksChartRepository: FlasksChartRepository,
    ) { }

    get chartType(): string {
        return this._chartType;
    }

    get backgroundcolorDataset(): string[] {
        return this._chartBackgroundcolorDataset;
    }

    get title(): string {
        return this._title;
    }

    get repository(): FlasksChartRepository {
        return this.flasksChartRepository;
    }

    get chartId(): string {
        return this._chartId
    }

    get enableTooltip(): boolean {
        return true
    }

    get enableScales(): boolean {
        return true;
    }

    get interval(): DateInterval {
        return this._interval;
    }
}