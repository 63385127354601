<app-layout pageTitle="Descontos" [showSearch]="true" (searchChange)="list.search($event)">
	<section actions>
		<button mat-icon-button (click)="crud.drawerRight.toggle()" [matBadge]="perspective.value.length"
			[matBadgeHidden]="perspective.value.length == 0" matBadgeColor="warn" matBadgeSize="small">
			<mat-icon svgIcon="filter"></mat-icon>
		</button>
	</section>
	<entity-crud #crud [functionality]="functionality" [repository]="repository" [entityTypes]="entityTypes"
		(entityChange)="list.invalidate()" [showDefaultFab]="false">

		<entity-cards #list [repository]="repository" [perspective]="perspective.value" [summary]="false">

      <mat-card *entityCard="let entity" (click)="crud.openEntity(entity.id)">
        <mat-card-header>
					<div matCardAvatar [ngClass]="classByTypeOrExpiration(entity)" class="icon-avatar">
            <mat-icon *ngIf="entity.isPerClientDiscount">local_offer</mat-icon>
            <mat-icon *ngIf="entity.isPerAmountDiscount">discount</mat-icon>
          </div>
          <mat-card-title> {{ entity.name }} </mat-card-title>
        </mat-card-header>

				<mat-card-content>
					<section class="content" *ngIf="entity.isPerClientDiscount">
						<mat-icon svgIcon="money-bill"></mat-icon>
						<div>
							{{ entity.value | currency:'BRL' }}
						</div>
					</section>
					<section *ngIf="entity.isPerAmountDiscount" class="content">
						<mat-icon svgIcon="money-bill"></mat-icon>
						<div>
							{{ entity.value }}%
						</div>
					</section>
					<section *ngIf="entity.isPerClientDiscount" class="content">
						<mat-icon>domain</mat-icon>
						<div>
							{{ entity.client.name }}
						</div>
					</section>
					<section *ngIf="entity.isPerAmountDiscount" class="content">
						<mat-icon>science</mat-icon>
						<div>
							{{ entity.amount }}
						</div>
					</section>
				</mat-card-content>
			</mat-card>
		</entity-cards>

		<entity-fab-dial class="floatDefault" (optionSelect)="crud.newEntity($event.value)" [options]="fabOptions">
		</entity-fab-dial>

		<section drawerRight>
			<entity-list-perspective #perspective [allowedFilters]="allowedFilters" [allowedSorts]="allowedSorts">
			</entity-list-perspective>
		</section>

		<app-discounts-details *entityCrudDetailsContent="let crud" [state]="crud.state" [(entity)]="crud.entity"
			(submit)="crud.listeners.onSubmit($event)" (validChange)="crud.listeners.validityChange($event)"
			(dirtyChange)="crud.listeners.dirtyChange($event)">
		</app-discounts-details>

	</entity-crud>
</app-layout>