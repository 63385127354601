<fieldset>
  <legend>Fatura</legend>
  <section class="inline-infos">
    <mat-form-field thin>
      <mat-label>Faturar para</mat-label>
      <input matInput [value]="entity.recipient" readonly />
    </mat-form-field>
    <mat-form-field class="short-field" thin>
      <mat-label>Número</mat-label>
      <!-- TODO: VALIDAR SE É O NÚMERO DA NFSE OU NÚMERO DA FATURA -->
      <!-- <input matInput [value]="entity.nFSeNumber" readonly /> -->
      <input matInput [value]="entity.number" readonly />
    </mat-form-field>
    <mat-form-field thin>
      <mat-label>Data Inclusão</mat-label>
      <input matInput [value]="entity.date | date: 'short'" readonly />
    </mat-form-field>
    <mat-form-field class="short-field" thin>
      <mat-label>Referência</mat-label>
      <input matInput [value]="entity.reference | date: 'MM/yyyy'" readonly />
    </mat-form-field>
    <mat-form-field class="short-field" thin>
      <mat-label>Vencimento</mat-label>
      <input matInput [value]="entity.expiration | date: 'shortDate'" readonly />
    </mat-form-field>
  </section>
</fieldset>