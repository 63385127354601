import { InvoiceStatus } from './../../../../../../libs/domain/src/lib/invoices/invoice-status';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { DateInterval, Filter, FilterOp, PageData, RefreshablePage } from '@financial/arch';
import { EntityCrudComponent, EntityCrudType, EntityListPerspective, EnumFilterDescription, RefFilterDescription } from '@financial/common-components';
import { ClientSummary, Invoice, InvoiceSummary, InvoicesRepository, InvoicesTemporalRepository } from '@financial/domain';
import { lastValueFrom } from 'rxjs';
import { ClientsRepository } from './../../../../../../libs/domain/src/lib/clients/clients.repository';
import { MatDialog } from '@angular/material/dialog';
import { RegenerateBankSlipDialogComponent } from './regenerate-bank-slip-dialog/regenerate-bank-slip-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoicesComponent extends RefreshablePage implements OnInit {
  @ViewChild(EntityCrudComponent, { static: true }) crud: EntityCrudComponent;

  entityTypes: EntityCrudType[] = [{ 
      creator: () => new Invoice(),
      title: 'Fatura',
      showHelpButton: false
  }];

  readonly pageSizeOptions = [10, 20, 50, 100];

  opened = false;

  readonly columns: TableColumn[] = [
    { name: 'number', label: 'Número' },
    { name: 'formattedCnp', label: 'CPF/CNPJ'},
    { name: 'clientName', label: 'Cliente' },
    { name: 'statusLabel', label: 'Status' },
    { name: 'date', label: 'Data de Entrada' },
    { name: 'total', label: 'Valor total' },
    { name: 'eceosBankSlipStatusLabel', label: 'Status do Boleto' },
  ];

  readonly allowedFilters = [
    new RefFilterDescription<ClientSummary>('client:id', 'Cliente', this.clientsRepository),
    new EnumFilterDescription<InvoiceStatus>('status', 'Status', InvoiceStatus.values())
  ];

  allowedSorts = [];

  private page = new PageData(0, 10);

  private currentPage = this.page;

  private readonly _displayedColumns = this.columns.map(v => v.name);

  private _invoices: InvoiceSummary[] = [];

  private _totalOfInvoices: number;

  private _perspective: EntityListPerspective;

  private _displayBalance = false;

  private _pendingBalance = 0;

  private _overdueBalance = 0;

  private _totalInvoices = 0;

  private _notOverdueInvoicesBalance = 0;

  private _noInvoiceFound = false;

  private _filter: Filter = new Filter('bankSlipWithError', FilterOp.EQ, true);

  private _bankSlipWithError: InvoiceSummary[] = [];

  private _showActives = false;

  private _interval: DateInterval = DateInterval.ofMonth(new Date());

  constructor(
    public temporalRepository: InvoicesTemporalRepository,
    private repository: InvoicesRepository,
    private clientsRepository: ClientsRepository,
    private router: Router,
    private changeRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {
    super();
  }

  get interval(): DateInterval {
    return this._interval
  }

  set interval(interval: DateInterval) {
    if (this._interval !== interval) {
      this._interval = interval;
      this.loadInvoices();
    }
  }

  get invoices() {
    return this._invoices;
  }

  get displayedColumns() {
    return this._displayedColumns;
  }

  get totalOfInvoices() {
    return this._totalOfInvoices;
  }

  get displayBalance() {
    return this._displayBalance;
  }

  get notOverdueInvoicesBalance() {
    return this._notOverdueInvoicesBalance;
  }

  get totalInvoices() {
    return this._totalInvoices;
  }

  get pendingBalance() {
    return this._pendingBalance;
  }

  get overdueBalance() {
    return this._overdueBalance;
  }

  get noInvoiceFound() {
    return this._noInvoiceFound;
  }

  get bankSlipWithError(): boolean {
    return this._bankSlipWithError.length > 0;
  }

  get showActives(): boolean {
    return this._showActives;
  }

  async ngOnInit() {
    this._bankSlipWithError = await lastValueFrom(this.repository.page(this.page, '', [this._filter]));
    this.changeRef.markForCheck();
  }

  async insertInvoice() {
    await this.router.navigate([`/billables/clients`], {
      replaceUrl: false,
    });
  }

  hasUnsavedState() {
    return false;
  }

  async openActions(invoice: Invoice) {
    this.repository.getPermissions(invoice).subscribe(() => {
      this.router.navigate([`/invoices/${invoice.id}/actions`], {
        replaceUrl: false
      })
    })
  }

  async loadInvoices(query: string = '') {
    this._totalOfInvoices = await lastValueFrom(this.temporalRepository.count(this._interval, query, this._perspective?.filters));
    this._invoices = await lastValueFrom(this.temporalRepository.page(this._interval, this.currentPage, query, this._perspective?.filters));
    this._noInvoiceFound = this._totalOfInvoices <= 0;
    this.changeRef.markForCheck();
  }

  async openReleasedInvoices() {
    this.router.navigate([`/invoices/released`], {
      replaceUrl: false,
    });
  }

  openClientsWithOverdueInvoices() {
    this.router.navigate([`/invoices/clients`], {
      replaceUrl: false
    })
  }

  paginate(pagingEvent: PageEvent) {
    pagingEvent.pageIndex = pagingEvent.pageIndex * pagingEvent.pageSize;
    this.currentPage = new PageData(pagingEvent.pageIndex, pagingEvent.pageSize);
    this.loadInvoices();
    this.changeRef.markForCheck();
  }

  isPipeNotNeeded(column: TableColumn): boolean {
    return !this.verifyColumnName(column, 4) && !this.verifyColumnName(column, 5);
  }

  verifyColumnName(column: TableColumn, index: number): boolean {
    return (column?.name === this.columns[index]?.name);
  }

  trackByColumn(index: any, entity: TableColumn) {
    return entity.name;
  }

  onPrintClick() {
    window.print();
  }

  onRowClick(invoice: Invoice) {
    this.router.navigate([`invoices/${invoice.id}/actions`], {
      replaceUrl: false
    });

  }

  async filterChange(event: EntityListPerspective) {
    this._perspective = event;
    this.loadInvoices();
    if (this._perspective.filters.length > 0) {
      this._perspective.filters.forEach(async filter => {
        if (filter.field === this.allowedFilters[0].field) {
          this._displayBalance = true;
          this._pendingBalance = await lastValueFrom(this.repository.sumFreeToUseBillables(filter.value.id));
          this._overdueBalance = await lastValueFrom(this.repository.sumOverdueInvoices(filter.value.id));
          this._notOverdueInvoicesBalance = await lastValueFrom(this.repository.sumInvoicesTotal(filter.value.id));
          this._totalInvoices = this._overdueBalance + this._notOverdueInvoicesBalance;
          this.changeRef.markForCheck();
        }
      })
    } else {
      this._displayBalance = false;
    }
    this.changeRef.markForCheck();
  }

  exportInvoices() {
    this.router.navigate([`/invoices/export`], {
      replaceUrl: false
    })
  }

  openRegenerateBankSlipDialog(): void {
    const dialogRef = this.dialog.open(RegenerateBankSlipDialogComponent, {
      width: '70%',
      maxWidth: '70%',
      height: '85%',
      data: ''
    });
    dialogRef.afterClosed().subscribe(
      async result => {
        this.loadInvoices()
        this._bankSlipWithError = await lastValueFrom(this.repository.page(this.page, '', [this._filter]));

        if (result.data) {
          this.showSnackbarMessage('Ainda existem boletos que não foram emitidos');
        }
      }
    );
  }

  private showSnackbarMessage(message: string) {
    this.snackbar.open(`${message}`, null, { duration: 5000 });
  }

  async search(searchTerm: string): Promise<void> {
    this.loadInvoices(searchTerm);
  }
}

interface TableColumn {
  name: string,
  label: string
}