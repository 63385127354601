import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EntityCrudState } from '@financial/common-components';
import { ClientsRepository, Discount, PerAmountDiscount, PerClientDiscount } from '@financial/domain';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs';

@Component({
  selector: 'app-discounts-details',
  templateUrl: './discounts-details.component.html',
  styleUrls: ['./discounts-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscountsDetailsComponent implements OnInit {

  form: FormGroup;

  @Output() entityChange = new EventEmitter<Discount>();
  @Output() submit = new EventEmitter<Discount>();
  @Output() validChange = new EventEmitter<boolean>();
  @Output() dirtyChange = new EventEmitter<boolean>();

  private _entity: Discount;
  private _state: EntityCrudState;
  private _valid = true;
  private _dirty = false;

  constructor(
    private fb: FormBuilder,
    private ref: ChangeDetectorRef,
    public clientsRepository: ClientsRepository
  ) { }

  @Input()
  set state(value: EntityCrudState) {
    this._state = value;
    this.syncFromState();
  }

  @Input()
  set entity(entity: Discount) {
    if (this.entity !== entity) {
      this._entity = entity;
      this.createFormFromEntity();
    }
  }

  get entity() {
    return this._entity;
  }

  get isPerAmountDiscount() {
    return this.entity && this.entity.isPerAmountDiscount;
  }

  get isPerClientDiscount() {
    return this.entity && this.entity.isPerClientDiscount;
  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.submit.emit(this.entity);
  }

  onlyFutureDaysDateFilter = (date: Date = new Date()): boolean => {
    const now = new Date();
    return date > now;
  }

  private createFormFromEntity() {
    this.form = null;
    this.ref.detectChanges();
    if (this.entity) {
      const baseForm = {
        name: [this.entity.name, Validators.required],
        value: [this.entity.value, Validators.required],
        expiration: [this.entity.expiration]
      };
      if (this.isPerAmountDiscount) {
        const perAmountDiscount = this.entity as PerAmountDiscount;
        this.form = this.fb.group({
          ...baseForm,
          amount: [perAmountDiscount.amount]
        });
        this.addFormValueChange(
          d =>
            new PerAmountDiscount(
              this.entity.id,
              d.name,
              d.value,
              d.expiration,
              d.amount,
              this.entity.isActive
            )
        );
      }
      else if (this.isPerClientDiscount) {
        const perClientDiscount = this.entity as PerClientDiscount;
        this.form = this.fb.group({
          ...baseForm,
          client: [perClientDiscount.client]
        });

        this.addFormValueChange(
          d =>
            new PerClientDiscount(
              this.entity.id,
              d.name,
              d.value,
              d.expiration,
              d.client,
              this.entity.isActive
            )
        );
      }
    }
    if (this.form) {
      this.form.statusChanges.subscribe((s: any) => {
        if (this.form.dirty !== this._dirty) {
          this.dirtyChange.emit((this._dirty = this.form.dirty));
        }
        if (this.form.valid !== this._valid) {
          this.validChange.emit((this._valid = this.form.valid));
        }
      });
    }
    this.syncFromState();
  }

  private addFormValueChange(mapper: (f: any) => Discount) {
    this.form.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(300), map(mapper))
      .subscribe(value => {
        this.entityChange.emit((this._entity = value));
      });
  }

  private syncFromState() {
    if (this.form) {
      if (this._state === EntityCrudState.INSERT || this._state === EntityCrudState.EDIT) {
        this.form.enable();
      } else {
        this.form.disable();
      }
    }
  }

}
