import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsComponent } from './settings.component';
import { SharedModule } from '../../shared/shared.module';
import { SettingsRoutes } from './settings.routes';
import { SettingsDetailsComponent } from './settings-details/settings-details.component';

@NgModule({
  declarations: [SettingsComponent, SettingsDetailsComponent],
  imports: [CommonModule, SharedModule, SettingsRoutes]
})
export class SettingsModule {}
