import { DomainEntity, SgvId, SgvJson } from "@financial/arch";
import { EceosOperatable } from "../eceos-operatables";

export class Product implements DomainEntity {
    public eceosOperatableId = '';
    public formattedName = '';

    constructor(
        readonly id: string = SgvId.gen(),
        public name: string = '',
        public code: string = '',
        public value: number = 0,
        public eceosOperatable: EceosOperatable = null,
    ) {
        this.eceosOperatableId = eceosOperatable?.id ?? null;
    }

    toJson(): any {
        return SgvJson.to.simple(this);
    }

    static fromJson(json: any): Product {
        return json ? SgvJson.from.simple(json, Product) : null;
    }

}