import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login-page',
  template: `
    <app-login
      [validator]="service"
      [username]="username"
      usernameInputLabel="E-mail ou Usuário"
    ></app-login>
  `
})
export class LoginPageComponent implements OnInit {
  
  username: string;

  constructor(public service: LoginService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.username = params['username'];
    });
  }

  ngOnInit() {}
}
