<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form">
  <mat-tab-group #group>
    <mat-tab label="Dados Cadastrais" [swipeTab]="group">
      <fieldset class="personalData first">
        <section>
          <mat-form-field class="name">
            <mat-label>Nome</mat-label> <input matInput formControlName="name" />
          </mat-form-field>
          <mat-form-field class="code">
            <mat-label>Código do Cliente SISLAB</mat-label> <input matInput formControlName="code" />
          </mat-form-field>
          <ng-container *ngIf="isNaturalPerson">
            <mat-form-field>
              <mat-label>CPF</mat-label>
              <input matInput formControlName="cpf" kzMask="999.999.999-99" (blur)="onInvalidCnp($event)"/>
            </mat-form-field>
            <mat-form-field>
              <mat-label>RG</mat-label> <input matInput formControlName="rg" />
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="isLegalEntity">
            <mat-form-field>
              <mat-label>CNPJ</mat-label>
              <input matInput formControlName="cnpj" kzMask="99.999.999/9999-99" (blur)="onInvalidCnp($event)"/>
            </mat-form-field>
            <mat-form-field class="name">
              <mat-label>Razão Social</mat-label> <input matInput formControlName="corporateName" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Inscrição Estadual</mat-label> <input matInput formControlName="ie" kzMask="999.999.999.999"/>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Inscrição Municipal</mat-label> <input matInput formControlName="im" />
            </mat-form-field>
          </ng-container>
          <mat-form-field 
            matTooltipClass="client-details-tooltip"
            [matTooltip]="codFunapeMessage"
          >
            <mat-label>Código Funape</mat-label> <input matInput formControlName="cliforCode">
          </mat-form-field>
          <app-data-selector label="Cliente Eceos" [repository]="eceosClientsRepository" formControlName="eceosClient">
          </app-data-selector>
        </section>
      </fieldset>
      <fieldset>
        <legend>Endereço</legend>
        <app-address-form formControlName="address"></app-address-form>
      </fieldset>
      <fieldset>
        <mat-checkbox formControlName="generateBankSlip" >
          <mat-label>Cliente Emitirá Boleto</mat-label>
        </mat-checkbox>
      </fieldset>
    </mat-tab>
    <mat-tab label="Contatos" [swipeTab]="group">
      <section class="contacts">
        <fieldset>
          <legend>Telefones</legend>
          <app-contact-phones-form formControlName="phones"></app-contact-phones-form>
        </fieldset>
        <fieldset>
          <legend>e-mails</legend>
          <app-contact-emails-form formControlName="emails"></app-contact-emails-form>
        </fieldset>
      </section>
    </mat-tab>
  </mat-tab-group>
</form>
