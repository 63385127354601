import { BreakpointObserver } from '@angular/cdk/layout';
import { DOCUMENT, Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomainEntity, Functionality, Repository, TemporalRepository } from '@financial/arch';
import { EntityCrudAditionalActions, EntityCrudDetailsEvents } from '@financial/common-components';
import {
  DELETE,
  DELETE_TEMPORARY,
  INSERT,
  Permission,
  PermissionService,
  UPDATE
} from '@financial/domain';
import { BehaviorSubject, lastValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CrudMode } from '../crud-mode';
import {
  EntityCrudDetailsContentDirective,
  EntityCrudDetailsContext
} from '../entity-crud-details-content.directive';
import { EntityCrudState } from '../entity-crud-state';
import {
  EntityDeleteDialogComponent,
  EntityDeleteOption
} from '../entity-delete-dialog/entity-delete-dialog.component';

export interface EntityCrudDialogParams {
  functionality: Functionality;
  repository:
    | Repository<DomainEntity, DomainEntity>
    | TemporalRepository<DomainEntity, DomainEntity>;
  content: EntityCrudDetailsContentDirective;
  changed$: BehaviorSubject<boolean>;
  mode$: BehaviorSubject<EntityCrudState>;
  changeCrudMode: (CrudMode) => void;
  entityTypes: EntityCrudType[];
  type: string;
  id?: string;
}
export interface EntityCrudType {
  readonly type?: string;
  readonly title: string;
  readonly shortTitle?: string;
  readonly creator: () => DomainEntity | Promise<DomainEntity>;
  readonly match?: (entity: DomainEntity) => boolean;
  readonly showHelpButton: boolean; 
}
export interface EntityCrudDialogResult {
  changed: boolean;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'entity-crud-dialog',
  templateUrl: './entity-crud-dialog.component.html',
  styleUrls: ['./entity-crud-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
// tslint:disable: member-ordering
export class EntityCrudDialogComponent implements OnInit, OnDestroy {
  private stateChange = new Subject<EntityCrudState>();
  private entityChange = new Subject<any>();
  private beforeSave = new Subject<any>();
  private afterSave = new Subject<any>();

  content: EntityCrudDetailsContentDirective;

  context: EntityCrudDetailsContext<any> = new EntityCrudDetailsContext<any>(
    EntityCrudState.VIEW,
    null,
    {
      onSubmit: (e) => this.onSaveClick(),
      validityChange: (valid) => (this.valid = valid),
      dirtyChange: (dirty) => (this.dirty = dirty)
    },
    new EntityCrudAditionalActions(),
    new EntityCrudDetailsEvents<any>(
      this.stateChange,
      this.entityChange,
      this.beforeSave,
      this.afterSave
    )
  );

  valid = true;

  dirty = false;

  _maximized = false;

  onlyFullScreen = true;

  mobile = false;

  private permission: Permission;

  private entityType: EntityCrudType = null;

  private destroy$ = new Subject();

  constructor(
    private breakpointObserver: BreakpointObserver,
    public dialogService: MatDialog,
    public dialogRef: MatDialogRef<EntityCrudDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EntityCrudDialogParams,
    @Inject(DOCUMENT) private document: Document,
    private location: Location,
    private changeDetector: ChangeDetectorRef,
    private permissions: PermissionService,
    private snackbar: MatSnackBar
  ) {
    this.setEntityType();
    this.content = data.content;
    data.changed$.next(false);
    data.mode$.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      this.state = state;
    });
    this.dialogRef
      .backdropClick()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.inViewState || (this.inInsertState && !this.unsavedChanges)) {
          this.close();
        }
      });
    this.breakpointObserver
      .observe(['(max-width: 1366px)'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((v) => {
        this.onlyFullScreen = v.matches;
      });

    this.breakpointObserver
      .observe(['(max-width: 425px)'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((v) => {
        this.mobile = v.matches;
      });
    this.permission = this.permissions.getPermissionOf(this.data.functionality);
  }

  get entityActive() {
    return this.context.entity ? this.context.entity.isActive !== false : true;
  }

  get entity() {
    return this.context.entity;
  }

  set entity(entity: DomainEntity) {
    this.context.entity = entity;
    this.setEntityType();
    this.changeDetector.markForCheck();
    this.entityChange.next(entity);
  }

  get repository():
    | Repository<DomainEntity, DomainEntity>
    | TemporalRepository<DomainEntity, DomainEntity> {
    return this.data.repository;
  }

  get functionality(): Functionality {
    return this.data.functionality;
  }

  get showHelpButton(): boolean {
    return this.entityType ? this.entityType.showHelpButton : false;
  }

  get title(): string {
    return this.entityType ? this.entityType.title : null;
  }

  get shortTitle(): string {
    return this.entityType ? this.entityType.shortTitle : null;
  }

  get state(): EntityCrudState {
    return this.context.state;
  }

  set state(state: EntityCrudState) {
    const newToEntity =
      this.inInsertState && (state === EntityCrudState.VIEW || state === EntityCrudState.EDIT);
    this.context.state = state;
    if (this.inInsertState) {
      this.clearEntity();
    }
    if (newToEntity) {
      this.data.changeCrudMode(CrudMode.from(this.entity.id, this.data.type, this.context.state));
      this.data.id = this.entity.id;
    }
    this.dirty = false;
    this.refreshEntity();
    this.changeDetector.markForCheck();
    this.stateChange.next(state);
  }

  get inViewState() {
    return this.context.inViewState;
  }

  get inEditState() {
    return this.context.inEditState;
  }

  get inInsertState() {
    return this.context.inInsertState;
  }

  get showEdit() {
    return this.inViewState && this.context.aditionalActions.allowEdit && this.userCanUpdate;
  }

  get showSave() {
    return (this.inEditState && this.userCanUpdate) || (this.inInsertState && this.userCanInsert);
  }

  get showDelete() {
    return (
      this.inViewState && (this.userCanDelete || (this.userCanTempDelete && this.entityActive))
    );
  }

  get showSaveAndNew() {
    return this.inInsertState && this.userCanInsert;
  }

  get maximized() {
    return this._maximized;
  }

  get pristine() {
    return !this.dirty;
  }

  get unsavedChanges() {
    return this.dirty;
  }

  get userCanInsert() {
    return true;
  }

  get userCanUpdate() {
    return true;
  }

  get userCanDelete() {
    return true;
  }

  get userCanTempDelete() {
    return true;
  }

  private get currentDialogDocument(): Element {
    const list = this.document.getElementsByClassName('crud-dialog');
    return list.length > 0 ? list.item(0) : null;
  }

  set maximized(value: boolean) {
    this._maximized = value;
    if (value) {
      this.currentDialogDocument.classList.add('full-dialog');
    } else {
      this.currentDialogDocument.classList.remove('full-dialog');
    }
  }

  private clearEntity() {
    this.data.id = null;
    this.dirty = false;
    this.refreshEntity();
  }

  private async refreshEntity() {
    if (this.data.id) {
      this.entity = await lastValueFrom(this.repository.find(this.data.id));
    } else {
      const newEntity = this.entityType?.creator();
      this.entity = newEntity instanceof Promise ? await newEntity : newEntity;
    }
  }

  ngOnInit() {}

  private setEntityType() {
    const matchFn = (entityType) =>
      entityType.match && this.entity ? entityType.match(this.entity) : false;
    this.entityType = this.data.entityTypes.find((it) => it.type === this.data.type || matchFn(it));
  }

  private async save(): Promise<DomainEntity> {
    if (!this.valid) {
      console.warn('Formulário inválido não pode ser submetido');
      return null;
    }
    this.beforeSave.next(this.entity);
    try {
      if (this.inEditState) {
        if (this.userCanUpdate) {
          return await lastValueFrom(this.repository.update(this.entity));
        } else {
          this.showMessage('Usuário não tem permissão de atualizar');
          return null;
        }
      } else if (this.inInsertState) {
        if (this.userCanInsert) {
          return await lastValueFrom(this.repository.insert(this.entity));
        } else {
          this.showMessage('Usuário não tem permissão de inserir');
          return null;
        }
      } else {
        return null;
      }
    } finally {
      this.afterSave.next(this.entity);
    }
  }

  onSaveAction = () => this.onSaveClick();
  async onSaveClick() {
    const e = await this.save();
    if (e) {
      this.entity = e;
      this.data.changed$.next(true);
      if (this.inInsertState) {
        this.state = EntityCrudState.VIEW;
      } else {
        this.goBack();
      }
    }
    this.changeDetector.detectChanges();
  }
  onSaveAndContinueAction = () => this.onSaveAndContinueClick();
  async onSaveAndContinueClick() {
    const e = await this.save();
    if (e) {
      this.entity = e;
      this.data.changed$.next(true);
      this.state = EntityCrudState.EDIT;
    }
    this.changeDetector.detectChanges();
  }
  onSaveAndNewAction = () => this.onSaveAndNewClick();
  async onSaveAndNewClick() {
    const e = await this.save();
    if (e) {
      this.entity = null;
      this.data.changed$.next(true);
      this.state = EntityCrudState.INSERT;
    }
    this.changeDetector.detectChanges();
  }
  onSaveAndCloseAction = () => this.onSaveAndCloseClick();
  async onSaveAndCloseClick() {
    const e = await this.save();
    if (e) {
      this.entity = e;
      this.data.changed$.next(true);
      this.close();
    }
    this.changeDetector.detectChanges();
  }

  async onEditClick() {
    if (this.context.aditionalActions.allowEdit) {
      this.data.changeCrudMode(CrudMode.from(this.entity.id, this.data.type, EntityCrudState.EDIT));
    }
  }

  onActivateAction = () => this.onActivateClick();
  async onActivateClick() {
    const e = await lastValueFrom(this.repository.activate(this.entity));
    if (e) {
      this.entity = e;
      this.data.changed$.next(true);
    }
  }

  async onDeleteClick() {
    if (this.userCanDelete || (this.userCanTempDelete && this.entityActive)) {
      const result: EntityDeleteOption = await lastValueFrom(
        this.dialogService
          .open(EntityDeleteDialogComponent, {
            data: {
              allowPermanent: this.userCanDelete,
              allowTemporary: this.userCanTempDelete && this.entityActive
            }
          })
          .afterClosed()
      );

      if (result === EntityDeleteOption.TEMPORARY || result === EntityDeleteOption.PERMANENT) {
        await lastValueFrom(
          this.repository.delete(this.entity, result === EntityDeleteOption.TEMPORARY)
        );
        this.data.changed$.next(true);
        this.close();
      }
    }
  }

  onBackClick() {
    this.goBack();
  }

  private goBack() {
    this.location.back();
  }

  toggleMaximize() {
    this.maximized = !this.maximized;
  }

  close() {
    this.dialogRef.close();
  }

  private showMessage(message: string) {
    this.snackbar.open(message, null, { duration: 2000 });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
  }
}
