import { JsonEntity, SgvJson } from "@financial/arch";
import { AnalysisType } from "@financial/domain";
import { PerAmountDiscount } from "../discount";
import { ServiceSummary } from "../services";

export class ServiceItem implements JsonEntity {
    public serviceValue: number;
    public totalServiceValue: number;
    constructor(
        public service: ServiceSummary = null,
        public analysisType: AnalysisType = null,
        public amount: number = 0,
        public discount: PerAmountDiscount = null
    ) { }

    buildMetadata() {
        this.buildServiceValue();
        this.buildTotalServiceValue();
    }

    buildServiceValue() {
        this.serviceValue = (this.service.value * (100 - this.discount.value)) / 100;
    }

    buildTotalServiceValue() {
        this.totalServiceValue = this.serviceValue * this.amount;
    }

    toJson() {
        return SgvJson.to.simple(this, {
            service: this.service ? this.service.toJson() : null,
            analysisType: this.analysisType ? this.analysisType.name : null,
            discount: this.discount ? this.discount.toJson() : null
        });
    }

    static fromJson(json: any): ServiceItem {
        return SgvJson.from.simple(json, ServiceItem, {
            service: json.service ? ServiceSummary.fromJson(json.service) : null,
            analysisType: json.analysisType ? AnalysisType.get(json.analysisType) : null,
            discount: json.discount ? PerAmountDiscount.fromJson(json.discount) : null
        })
    }

}