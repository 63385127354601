<app-layout pageTitle="Produtos" [showSearch]="true" (searchChange)="list.search($event)">
    <section actions>
      <button
        mat-icon-button
        (click)="crud.drawerRight.toggle()"
        [matBadge]="perspective.value.length"
        [matBadgeHidden]="perspective.value.length == 0"
        matBadgeColor="warn"
        matBadgeSize="small"
      >
      </button>
    </section>
    <entity-crud
      #crud
      [functionality]="functionality"
      [repository]="repository"
      (entityChange)="list.invalidate()"
      [entityTypes]="entityTypes"
    >
      <entity-cards
        #list
        [repository]="repository"
        [perspective]="perspective.value"
        [summary]="false"
      >
        <mat-card *entityCard="let entity" (click)="crud.openEntity(entity.id)">
          <mat-card-header>
            <div matCardAvatar class="icon-avatar primary-bg product-by-lot-bg"><mat-icon>view_column</mat-icon></div>
            <div class="content">
              <mat-card-title> {{ entity.name }} </mat-card-title>
            </div>
          </mat-card-header>
          <mat-card-content>
            <section>
              <mat-icon svgIcon="barcode"></mat-icon> 
              {{ entity.code }}
            </section>
            <section>
              <mat-icon svgIcon="money-bill"></mat-icon>
              {{ entity.value | currency:'BRL' }}
            </section>
          </mat-card-content>
        </mat-card>
      </entity-cards>

      <section drawerRight>
        <entity-list-perspective
          #perspective
          [allowedFilters]="allowedFilters"
          [allowedSorts]="allowedSorts"
        >
        </entity-list-perspective>
      </section>
      
      <app-products-details
        *entityCrudDetailsContent="let crud"
        [state]="crud.state"
        [(entity)]="crud.entity"
        (submit)="crud.listeners.onSubmit($event)"
        (validChange)="crud.listeners.validityChange($event)"
        (dirtyChange)="crud.listeners.dirtyChange($event)"
      >
      </app-products-details>
    </entity-crud>
  </app-layout>