<app-layout [showBack]="true" [showMenu]="false" pageTitle="CLIFOR e Movimentação Financeira">
  <form [formGroup]="form" #perspective>
    <section>
      <mat-form-field>
        <mat-label>CPF/CNPJ</mat-label>
        <input formControlName="cnp" matInput [kzMask]="['999.999.999-99', '99.999.999/9999-99']">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Nº Nota Fiscal</mat-label>
        <input matInput formControlName="nfseNumber" type="number">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Nosso Número</mat-label>
        <input matInput formControlName="ourNumber" type="number">
      </mat-form-field>
      <mat-checkbox formControlName="nfseStatus">
        <label>NF's Canceladas</label>
      </mat-checkbox>
    </section>
    <section class="date-and-buttons">
      <section class="date-form">
        <mat-form-field>
          <mat-label>Tipo da data</mat-label>
          <mat-select formControlName="dateIntervalType" [compareWith]="compareDateType" disableOptionCentering
            value="undefined">
            <mat-option disabled selected value="undefined">Selecione uma opção</mat-option>
            <mat-option *ngFor="let dateOption of dateOptions; trackBy: trackByDateType" [value]="dateOption.value">
              {{ dateOption.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="date-range-picker">
          <mat-label>Data</mat-label>
          <mat-date-range-input formGroupName="dateRange" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Data inicial">
            <input matEndDate formControlName="end" placeholder="Data final">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </section>
      <section class="buttons">
        <button mat-button (click)="loadClifors()">Buscar</button>
        <button mat-button (click)="cleanForm()">Limpar</button>
      </section>
    </section>
  </form>
  <section>
    <mat-card>
      <table mat-table [dataSource]="cliforData">
        <ng-container [matColumnDef]="column.name" *ngFor="let column of columns; trackBy: trackByColumn">
          <th mat-header-cell *matHeaderCellDef>{{ column.label }}</th>
          <ng-container *ngIf="isPipeNotNeeded(column)">
            <td mat-cell *matCellDef="let element">{{ element[column.name] }}</td>
          </ng-container>
          <ng-container *ngIf="currencyColumns(column)">
            <td mat-cell *matCellDef="let element">{{ element[column.name] | currency: 'BRL'}}</td>
          </ng-container>
          <ng-container *ngIf="verifyColumnName(column, 3)">
            <td mat-cell *matCellDef="let element">{{ element[column.name] | date: 'shortDate'}}</td>
          </ng-container>
          <ng-container *ngIf="verifyColumnName(column, 7)">
            <td mat-cell *matCellDef="let element">
              <button mat-button (click)="downloadCliforBy(element)">CLIFOR</button>
              <button mat-button (click)="downloadFinancialMovementBy(element)">Movimento</button>
            </td>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <section class="no-clifors" *ngIf="noCliforsFound">
        Nenhuma fatura encontrada.
      </section>
      <section>
        <mat-paginator #pagingBar [pageSizeOptions]="pageSizeOptions" showFirstLastButtons [length]="totalOfClifors"
          (page)="paginate($event)">
        </mat-paginator>
      </section>
    </mat-card>
    <eco-fab-speed-dial class="floatDefault" [(open)]="fabOpen" (openChange)="onOpenFabChange($event)">
      <eco-fab-speed-dial-trigger [spin]="true">
        <button mat-fab class="spin180">
          <mat-icon> {{ fabOpen ? 'close' : 'download' }}</mat-icon>
        </button>
      </eco-fab-speed-dial-trigger>
      <eco-fab-speed-dial-actions>
        <button *ngFor="let option of fabOptions; trackBy: trackByOption" mat-mini-fab
          (click)="downloadDocuments(option)">
          <span class="tooltiptext" [ngClass]="labelPosition" *ngIf="fabOpen">
            {{ option.label }}
          </span>
          <mat-icon>
            {{ option.icon }}
          </mat-icon>
        </button>
      </eco-fab-speed-dial-actions>
    </eco-fab-speed-dial>
  </section>
</app-layout>