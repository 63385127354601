import { Directive, Input, TemplateRef } from '@angular/core';
import { DomainEntity } from '@financial/arch';
import { EMPTY, Observable } from 'rxjs';
import { EntityCrudState } from './entity-crud-state';

export interface EntityCrudAction {
  name: string;
  disabled?: boolean;
  rendered?: boolean;
  onAction(): Promise<void>;
}
export class EntityCrudAditionalActions {
  constructor(
    public allowEdit: boolean = true,
    readonly primary: EntityCrudAction[] = [],
    readonly secondary: EntityCrudAction[] = []
  ) {}
}

export interface EntityCrudDetailsListeners<T> {
  onSubmit(entity: T);
  validityChange(valid: boolean);
  dirtyChange(dirty: boolean);
}

export class EntityCrudDetailsEvents<T> {
  constructor(
    readonly stateChange$: Observable<EntityCrudState> = EMPTY,
    readonly entityChange$: Observable<T> = EMPTY,
    readonly beforeSave$: Observable<T> = EMPTY,
    readonly afterSave$: Observable<T> = EMPTY
  ) {}
}

export class EntityCrudDetailsContext<T> {
  $implicit = this;
  constructor(
    public state: EntityCrudState,
    public entity: T,
    readonly listeners: EntityCrudDetailsListeners<T>,
    readonly aditionalActions = new EntityCrudAditionalActions(),
    readonly events = new EntityCrudDetailsEvents<T>()
  ) {}

  get inViewState() {
    return this.state === EntityCrudState.VIEW;
  }

  get inEditState() {
    return this.state === EntityCrudState.EDIT;
  }

  get inInsertState() {
    return this.state === EntityCrudState.INSERT;
  }
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[entityCrudDetailsContent]'
})
export class EntityCrudDetailsContentDirective {
  constructor(public readonly templateRef: TemplateRef<EntityCrudDetailsContext<DomainEntity>>) {}
}
