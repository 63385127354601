import { throwError } from "rxjs";

export class EceosBankSlipStatus {
    public static NOT_GENERATED = new EceosBankSlipStatus("NOT_GENERATED", "NÃO GERADO");
    public static OPEN = new EceosBankSlipStatus("OPEN", "EM ABERTO");
    public static CANCELED = new EceosBankSlipStatus("CANCELED", "CANCELADO");
    public static FINALIZED = new EceosBankSlipStatus("FINALIZED", "FINALIZADO");
    public static OVERDUE = new EceosBankSlipStatus("OVERDUE", "EM ATRASO");
    public static PARTIALLY_PAYED = new EceosBankSlipStatus("PARTIALLY_PAYED", "PAGO PARCIALMENTE");
    public static FINANCIAL_AGREEMENT = new EceosBankSlipStatus("FINANCIAL_AGREEMENT", "EM ACORDO FINANCEIRO");
    public static FINALIZED_BY_FINANCIAL_AGREEMENT = new EceosBankSlipStatus("FINALIZED_BY_FINANCIAL_PAYED", "FINALIZADA POR ACORDO FINANCEIRO");

    public constructor(
        public readonly name: string,
        public readonly label: string
    ) { }

    public static values(): EceosBankSlipStatus[] {
        return [
            EceosBankSlipStatus.NOT_GENERATED,
            EceosBankSlipStatus.OPEN,
            EceosBankSlipStatus.CANCELED,
            EceosBankSlipStatus.FINALIZED,
            EceosBankSlipStatus.OVERDUE,
            EceosBankSlipStatus.PARTIALLY_PAYED,
            EceosBankSlipStatus.FINANCIAL_AGREEMENT,
            EceosBankSlipStatus.FINALIZED_BY_FINANCIAL_AGREEMENT
        ];
    }

    public static get(name: string): EceosBankSlipStatus {
        const eceosBankSlipStatus =
            EceosBankSlipStatus
                .values()
                .find(
                    value =>
                        value.name === name
                );

        if (!eceosBankSlipStatus) {
            throwError[`${name} Status is not mapped!`];
        }

        return eceosBankSlipStatus;
    }
}