import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { DomainEntity } from '@financial/arch';
// tslint:disable:directive-selector

export interface EntityCardDirectiveContext<T extends DomainEntity> {
  $implicit: T;
}

@Directive({
  selector: '[entityCard]'
})
export class EntityCardDirective<T extends DomainEntity> {
  constructor(
    public readonly templateRef: TemplateRef<EntityCardDirectiveContext<T>>,
    private viewContainer: ViewContainerRef
  ) {}
}
