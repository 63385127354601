<app-layout pageTitle="Nova Fatura" [showSearch]="true" [showMenu]="false" [showBack]="true">
  <section class="empty-data" *ngIf="!hasItems">
    <h3>Nenhuma Análise processada disponível para iniciar uma fatura.</h3>
  </section>
  <section class="cards">
    <mat-card *ngFor="let entity of items; trackBy: trackByFn" (click)="showBillables(entity.client.id)">
      <mat-card-header>
        <div matCardAvatar class="icon-avatar primary-bg pf-bg">
          <mat-icon>person</mat-icon>
        </div>
        <mat-card-title>
          {{ entity.client.formattedName }}
        </mat-card-title>
        <mat-card-subtitle>
          {{ entity.client.maskedCnp }}
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <section>
          <mat-icon>shopping_bag_outline</mat-icon>
          {{ entity.billableAmount }} Análises Processadas
        </section>
      </mat-card-content>
    </mat-card>
  </section>
</app-layout>