import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Functionality, RefreshablePage } from '@financial/arch';
import {
  EntityCrudComponent,
  EntityCrudType,
  EntityFabDialOption,
  FilterDescription,
  FilterType,
  SortDescription
} from '@financial/common-components';
import {
  CLIENTS,
  Client,
  ClientsRepository
} from '@financial/domain';
import { filter, lastValueFrom, take } from 'rxjs';

const NATURAL_PERSON_TYPE = 'naturalPerson';
const LEGAL_ENTITY_TYPE = 'legalEntity';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent extends RefreshablePage implements OnInit {
  @ViewChild(EntityCrudComponent, { static: true }) crud: EntityCrudComponent;

  readonly columns = [
    { name: 'formattedName', label: 'Nome' },
    { name: 'code', label: 'Código Sislab' },
    { name: 'cliforCode', label: 'Código Funape'},
    { name: 'maskedCnp', label: 'CPF/CNPJ' },
    { name: 'phone', label: 'Telefone'},
    { name: 'email', label: 'Email'}
  ];
  
  entityTypes: EntityCrudType[] = [
    {
      type: NATURAL_PERSON_TYPE,
      title: 'Cliente',
      creator: () => Client.naturalPerson(),
      match: (entity: Client) => entity.isNaturalPerson,
      showHelpButton: false
    },
    {
      type: LEGAL_ENTITY_TYPE,
      title: 'Cliente',
      creator: () => Client.legalEntity(),
      match: (entity: Client) => entity.isLegalEntity,
      showHelpButton: false
    }
  ];

  fabOptions: EntityFabDialOption[] = [
    {
      value: NATURAL_PERSON_TYPE,
      label: 'Pessoa Física',
      icon: 'person',
      backgroundColor: 'darkgreen'
    },
    {
      value: LEGAL_ENTITY_TYPE,
      label: 'Pessoa Jurídica',
      icon: 'business',
      backgroundColor: 'darkgoldenrod'
    }
  ];

  fabOpen = false;

  functionality: Functionality = CLIENTS;

  allowedFilters = [
    new FilterDescription('name', 'Nome', FilterType.TEXTUAL),
    new FilterDescription('documents.corporateName', 'Razão Social', FilterType.TEXTUAL),
    new FilterDescription('documents.cpf', 'CPF', FilterType.TEXTUAL),
    new FilterDescription('documents.cnpj', 'CNPJ', FilterType.TEXTUAL),
    new FilterDescription('documents.rg', 'RG', FilterType.TEXTUAL),
    new FilterDescription('documents.ie', 'Inscrição Estadual', FilterType.TEXTUAL),
    new FilterDescription('contacts.phone', 'Telefone/Celular', FilterType.TEXTUAL),
    new FilterDescription('contacts.email', 'E-mail', FilterType.TEXTUAL)
  ];

  allowedSorts = [
    new SortDescription('name', 'Nome'),
    new SortDescription('documents.corporateName', 'Razão Social')
  ];

  constructor(
    public repository: ClientsRepository,
    private router: Router,
    private location: Location
  ) {
    super();
  }

  ngOnInit() {}

  hasUnsavedState() {
    return this.crud.hasUnsavedState();
  }

  exportClients() {
    this.router.navigate(['/clients/export'], {
      replaceUrl: false
    });
  }

  async navigateToClient(client: Client) {
    if (this.crud.isEditState()) {
      this.location.back();
      const newLocation = await lastValueFrom(
        this.router.events.pipe(
          filter((event) => event instanceof NavigationStart),
          take(1)
        )
      );
    }
    this.crud.currentDialog.close();
    await this.router.navigateByUrl('/', { skipLocationChange: true });
    await this.router.navigate([`/clients/${client.id}`], {
      replaceUrl: true
    });
  }
}
