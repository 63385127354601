import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EceosOperatablesRepository, Service, ServiceRepository } from '@financial/domain';
import { EntityCrudState } from '@financial/common-components';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
@Component({
  selector: 'app-services-details',
  templateUrl: './services-details.component.html',
  styleUrls: ['./services-details.component.scss']
})
export class ServicesDetailsComponent {

  form: FormGroup;

  hasChargingFlow = false;

  @Output() entityChange = new EventEmitter<Service>();

  @Output() submit = new EventEmitter<Service>();

  @Output() validChange = new EventEmitter<boolean>();

  @Output() dirtyChange = new EventEmitter<boolean>();

  @Output() serviceChange = new EventEmitter<Service>();

  private _entity: Service;

  private _state: EntityCrudState;

  private _valid = true;

  private _dirty = false;

  constructor(
    private formBuilder: FormBuilder,
    private ref: ChangeDetectorRef,
    public clientsRepository: ServiceRepository,
    public eceosOperatableRepository: EceosOperatablesRepository
  ) { }

  @Input()
  set entity(entity: Service) {
    if (this._entity === entity) {
      return;
    }

    this._entity = entity;
    this.createFormFromEntity();
    this.setEceosOperatable();
  }

  get entity() {
    return this._entity;
  }

  @Input()
  set state(value: EntityCrudState) {
    this._state = value;
    this.syncFromState();
  }

  get state(): EntityCrudState {
    return this._state;
  }

  private get eceosOperatableControl(): AbstractControl {
    return this.form.get('eceosOperatable');
  }

  async setEceosOperatable() {
    if (this._entity?.eceosOperatableId) {
      this.eceosOperatableControl.setValue(await lastValueFrom(this.eceosOperatableRepository.find(this._entity.eceosOperatableId)));
      this.dirtyChange.emit(this._dirty = false);
    }
  }

  addFormValueChange(mapper: (f: any) => Service) {
    this.form.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(300), map(mapper))
      .subscribe((value) => {
        this.entityChange.emit((this._entity = value));
      });
  }

  onSubmit() {
    this.submit.emit(this.entity);
  }

  private createFormFromEntity() {
    this.form = null;
    this.ref.detectChanges();
    if (this.entity) {
      this.form = this.formBuilder.group({
        name: [this.entity.name, Validators.required],
        code: [this.entity.code, Validators.required],
        value: [this.entity.value, Validators.required],
        eceosOperatable: [this.entity.eceosOperatable, Validators.required]
      });
      this.addFormValueChange((service: Service) => new Service(this._entity.id, service.name, service.code, service.value, service.eceosOperatable));
    }
    if (this.form) {
      this.form.statusChanges.subscribe((s: any) => {
        if (this.form.dirty !== this._dirty) {
          this.dirtyChange.emit((this._dirty = this.form.dirty));
        }
        if (this.form.valid !== this._valid) {
          this.validChange.emit((this._valid = this.form.valid));
        }
      });
    }
    this.syncFromState();
  }

  private syncFromState() {
    if (this.form) {
      if (this._state === EntityCrudState.INSERT || this._state === EntityCrudState.EDIT) {
        this.form.enable();
      } else {
        this.form.disable();
      }
    }
  }
}
