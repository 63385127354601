<section>
  <header mat-dialog-title>
    <section>
      <button class="back-action" mat-icon-button (click)="onBackClick()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </section>
    <section class="title">
      <ng-container *ngIf="isEntityCrudViewState">Visualização de Faturável</ng-container>
      <ng-container *ngIf="isEntityCrudEditState">Edição de Faturável</ng-container>
    </section>
  </header>
</section>
<mat-dialog-content>
  <form [formGroup]="form" *ngIf="form">
    <fieldset formGroupName="client" class="client">
      <legend>Cliente</legend>
      <mat-form-field class="name">
        <mat-label>Nome</mat-label> <input matInput formControlName="name" readonly />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Código do Cliente</mat-label> <input matInput formControlName="code" readonly />
      </mat-form-field>
      <mat-form-field> 
        <mat-label *ngIf="clientCnpIsCNPJ">CNPJ</mat-label> 
        <mat-label *ngIf="!clientCnpIsCNPJ">CPF</mat-label>
        <input matInput formControlName="cnp" readonly />
      </mat-form-field>
    </fieldset>
    <fieldset>
      <legend>Detalhes</legend>
      <section>
        <mat-form-field>
          <mat-label>Data de Processamento</mat-label> 
          <input matInput [matDatepicker]="picker" formControlName="processedDate" readonly />
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Total</mat-label> <input matInput formControlName="total" readonly />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Desconto</mat-label> <input matInput formControlName="discount" readonly />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Status</mat-label> <input matInput formControlName="status" readonly />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Número de Receita</mat-label> <input matInput formControlName="recipeNumber" readonly />
        </mat-form-field>
      </section>
      <section class="client-to-bill">
        <app-data-selector
          [disabled]="disableDataSelector" 
          class="data-selector"
          label="Cliente para Faturar" 
          [repository]="clientsRepository" 
          formControlName="clientToBill">
        </app-data-selector>
      </section>
    </fieldset>
    <fieldset *ngIf="isAnalysis">
      <ng-container formGroupName="analysis">
        <mat-form-field>
          <mat-label>Protocolo</mat-label> <input matInput formControlName="protocol" readonly />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Data de entrada</mat-label> 
          <input matInput [matDatepicker]="picker" formControlName="entryDate" readonly />
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-checkbox formControlName="signed" readonly>
          <mat-label>Assinado</mat-label>
        </mat-checkbox>
      </ng-container>
      <ng-container formArrayName="serviceItems">
        <ng-container *ngFor="let item of serviceItemsFormArrayControls; let i = index; trackBy: trackByControlValueId">
          <section [formGroupName]="i">
            <ng-container formGroupName="service">
              <mat-form-field>
                <mat-label>Nome</mat-label> <input matInput formControlName="name" readonly />
              </mat-form-field>
              <mat-form-field>
                <mat-label>Código</mat-label> <input matInput formControlName="code" readonly />
              </mat-form-field>
              <mat-form-field>
                <mat-label>Valor</mat-label> <input matInput formControlName="value" readonly />
              </mat-form-field>
            </ng-container>
            <mat-form-field>
              <mat-label>Tipo da análise</mat-label> <input matInput formControlName="analysisType" readonly />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Quantidade</mat-label> <input matInput formControlName="amount" readonly />
            </mat-form-field>
          </section>
        </ng-container>
      </ng-container>
    </fieldset>
    <fieldset *ngIf="isSingleRequisition">
      <mat-form-field>
        <mat-label>Requisição</mat-label> <input matInput formControlName="requisition" readonly />
      </mat-form-field>
      <ng-container formArrayName="productItems">
        <ng-container *ngFor="let item of productItemsFormArrayControls; let i = index; trackBy: trackByControlValueId">
          <section class="items" [formGroupName]="i">
            <ng-container formGroupName="product">
              <mat-form-field>
                <mat-label>Nome</mat-label> <input matInput formControlName="name" readonly />
              </mat-form-field>
              <mat-form-field>
                <mat-label>Código</mat-label> <input matInput formControlName="code" readonly />
              </mat-form-field>
              <mat-form-field>
                <mat-label>Valor</mat-label> <input matInput formControlName="value" readonly />
              </mat-form-field>
            </ng-container>
            <mat-form-field>
              <mat-label>Quantidade</mat-label> <input matInput formControlName="amount" readonly />
            </mat-form-field>
          </section>
        </ng-container>
      </ng-container>
    </fieldset>
    <fieldset *ngIf="isAgreement">
      <mat-form-field>
        <mat-label>Número da Fatura</mat-label> <input matInput formControlName="invoiceNumber" readonly />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Valor</mat-label> <input matInput formControlName="value" readonly />
      </mat-form-field>
    </fieldset>
  </form>
</mat-dialog-content>
<mat-dialog-actions *ngIf="showActionsButtons" class="buttons">
  <button mat-button [disabled]="false" *ngIf="isEntityCrudViewState" (click)="onEdit()">Editar</button>
  <ng-container *ngIf="isEntityCrudEditState">
    <button mat-button [disabled]="false" (click)="onCancel()">Cancelar</button>
    <button mat-button [disabled]="false" (click)="onUpdate()">Atualizar</button>
  </ng-container>
</mat-dialog-actions>