<section class="hidden-on-print">
  <div>
    <header mat-dialog-title>
      <section>
        <button class="back-action" mat-icon-button (click)="onBackClick()">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </section>
      <section class="title">
        <ng-container>Detalhes da Fatura</ng-container>
      </section>
      <section>
        <button mat-icon-button (click)="onPrintClick()">
          <mat-icon>print</mat-icon>
        </button>
      </section>
    </header>
  </div>

  <mat-dialog-content>
    <ng-container>
      <app-invoice-details-dialog-billable-content [entity]="entity">
      </app-invoice-details-dialog-billable-content>
      <app-invoice-clients-content [entity]="entity"></app-invoice-clients-content>
      <fieldset>
        <legend>Detalhes</legend>
        <div *ngFor="let item of entity.items; trackBy: trackByFn" class="service">
          <section class="inline-infos">
            <mat-form-field thin>
              <mat-label>Serviço</mat-label>
              <input matInput [value]="item.serviceNumber" readonly />
            </mat-form-field>
            <!-- TODO: Adicionar o Serviço correto -->
            <mat-form-field thin>
              <mat-label>Data</mat-label>
              <input matInput [value]="entity.date | date: 'short'" readonly />
            </mat-form-field>
            <mat-form-field thin>
              <mat-label>Número da Receita</mat-label>
              <input matInput [value]="item.billable.recipeNumber" readonly />
            </mat-form-field>
          </section>
          <app-invoice-details-dialog-view-table-form [entity]="entity" [item]="item">
          </app-invoice-details-dialog-view-table-form>
        </div>
      </fieldset>
    </ng-container>
  </mat-dialog-content>
</section>

<section class="show-only-on-print columns">
  <print-header title="Serviços Fatura"></print-header>
  <app-invoice-details-dialog-billable-content [entity]="entity">
  </app-invoice-details-dialog-billable-content>
  <app-invoice-clients-content [entity]="entity"></app-invoice-clients-content>
  <fieldset>
    <legend>Detalhes</legend>
    <div *ngFor="let item of entity.items; trackBy: trackByFn" class="page-break">
      <section class="grid-container">
        <ng-container>
          <!-- TODO: Adicionar o Serviço correto -->
          <mat-form-field thin class="field-in-grid">
            <mat-label>Serviço</mat-label>
            <input matInput [value]="item.serviceNumber" readonly />
          </mat-form-field>
          <mat-form-field thin class="field-in-grid">
            <mat-label>Data</mat-label>
            <input matInput [value]="item.billable.processedDate | date: 'short'" readonly />
          </mat-form-field>
          <mat-form-field thin class="field-in-grid">
            <mat-label>Número da Receita</mat-label>
            <input matInput [value]="item.billable.recipeNumber" readonly />
          </mat-form-field>
        </ng-container>
        <app-invoice-details-dialog-print-table [entity]="entity" [item]="item" class="table-in-grid">
        </app-invoice-details-dialog-print-table>
      </section>
      <mat-divider></mat-divider>
    </div>
  </fieldset>
  <app-invoice-total-print-table [entity]="entity"></app-invoice-total-print-table>
</section>