import { DateInterval } from '@financial/arch';
import * as moment from 'moment';

export class DateRange {
  public static TODAY = new DateRange('TODAY', 'Hoje', DateInterval.ofToday());
  public static YESTERDAY = new DateRange(
    'YESTERDAY',
    'Ontem',
    DateInterval.ofDay(
      moment()
        .subtract(1, 'day')
        .toDate()
    )
  );
  public static LAST_SEVEN_DAYS = new DateRange(
    'LAST_SEVEN_DAYS',
    'Últimos 7 dias',
    DateInterval.of(
      moment()
        .subtract(6, 'days')
        .toDate(),
      new Date()
    )
  );
  public static LAST_THIRTY_DAYS = new DateRange(
    'LAST_THIRTY_DAYS',
    'Últimos 30 dias',
    DateInterval.of(
      moment()
        .subtract(29, 'days')
        .toDate(),
      new Date()
    )
  );
  public static THIS_MONTH = new DateRange(
    'THIS_MONTH',
    'Mês atual',
    DateInterval.of(
      moment()
        .startOf('month')
        .toDate(),
      moment()
        .endOf('month')
        .toDate()
    )
  );

  public static LAST_MONTH = new DateRange(
    'LAST_MONTH',
    'Mês passado',
    DateInterval.of(
      moment()
        .subtract(1, 'month')
        .startOf('month')
        .toDate(),
      moment()
        .subtract(1, 'month')
        .endOf('month')
        .toDate()
    )
  );

  public static LAST_THREE_MONTH = new DateRange(
    'LAST_THREE_MONTH',
    'Últimos 3 meses',
    DateInterval.of(
      moment()
        .subtract(2, 'month')
        .startOf('month')
        .toDate(),
      moment()
        .endOf('month')
        .toDate()
    )
  );

  public static THIS_YEAR = new DateRange(
    'THIS_YEAR',
    'Este ano',
    DateInterval.of(
      moment()
        .startOf('year')
        .toDate(),
      moment()
        .endOf('year')
        .toDate()
    )
  );

  constructor(
    public readonly name: string,
    public readonly label: string,
    public readonly interval: DateInterval
  ) {}

  static get(name: string): DateRange {
    return name ? this.values().find(v => v.name === name.toUpperCase()) : null;
  }

  static values(): DateRange[] {
    return [
      DateRange.TODAY,
      DateRange.YESTERDAY,
      DateRange.LAST_SEVEN_DAYS,
      DateRange.LAST_THIRTY_DAYS,
      DateRange.THIS_MONTH,
      DateRange.LAST_MONTH,
      DateRange.LAST_THREE_MONTH,
      DateRange.THIS_YEAR
    ];
  }
}
