import { SgvJson } from "@financial/arch";
import { EceosBankSlipStatus } from "./eceosBankSlipStatus";

export class EceosBankSlip {

    constructor(
        public id: string = null,
        public status: EceosBankSlipStatus = EceosBankSlipStatus.NOT_GENERATED,
        public finalizationDate: Date = null,
        public withError: boolean = false
    ) { }

    toJson(): any {
        return SgvJson.to.simple(this, {
            status: this.status.name
          });
     }

    static fromJson(json: any): EceosBankSlip {
        return json
            ? SgvJson.from.simple(json, EceosBankSlip, {
                status: json.status ? EceosBankSlipStatus.get(json.status) : null,
            })
            : null
    }
}