import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiService, PageData } from '@financial/arch';
import { EntityListPerspective, EnumFilterDescription, RefFilterDescription } from '@financial/common-components';
import { ClientsRepository, ClientSummary, Invoice, InvoicesRepository } from '@financial/domain';
import { saveAs } from 'file-saver';
import { InvoiceStatus } from 'libs/domain/src/lib/invoices/invoice-status';
import { lastValueFrom } from 'rxjs';
import { SortDescription } from './../../../../../../../libs/common-components/src/lib/entity-list-perspective/sort-description';

@Component({
  selector: 'app-invoices-export-table',
  templateUrl: './invoices-export-table.component.html',
  styleUrls: ['./invoices-export-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoicesExportTableComponent implements OnInit {

  repository: InvoicesRepository;

  selection = new SelectionModel<Invoice>(true, []);

  readonly columns: TableColumn[] = [
    { name: 'select', label: 'Selecione' },
    { name: 'number', label: 'Número' },
    { name: 'clientName', label: 'Cliente' },
    { name: 'statusLabel', label: 'Status' },
    { name: 'date', label: 'Data de Entrada' },
    { name: 'total', label: 'Valor total' },
  ];

  readonly allowedFilters = [
    new RefFilterDescription<ClientSummary>('client:id', 'Cliente', this.clientsRepository),
    new EnumFilterDescription<InvoiceStatus>('status', 'Status', InvoiceStatus.values())
  ];

  @Output() selectedInvoices = new EventEmitter<Invoice[]>();

  allowedSorts: SortDescription[] = [];

  openedPerspective = false;

  private _perspective: EntityListPerspective;

  private _displayedColumns = this.columns.map(v => v.name);

  private page = new PageData(0, 10);

  private currentPage = this.page;

  private _invoices: Invoice[] = [];

  private _totalOfInvoices: number;

  private _searchTerm = '';

  private _invoicesIds: string[] = [];

  private _pageSizeOptions = [10, 20, 50, 100];

  private _noInvoiceFound = false;

  private _query = '';

  constructor(
    private api: ApiService,
    private snackbar: MatSnackBar,
    private changeRef: ChangeDetectorRef,
    private invoicesRepository: InvoicesRepository,
    private clientsRepository: ClientsRepository,
    private router: Router,
  ) { }

  get noInvoiceFound() {
    return this._noInvoiceFound;
  }

  get displayedColumns() {
    return this._displayedColumns;
  }

  get invoices() {
    return this._invoices;
  }

  get totalOfInvoices() {
    return this._totalOfInvoices;
  }

  get pageSizeOptions() {
    return this._pageSizeOptions;
  }

  ngOnInit() {
    this.repository = new InvoicesRepository(this.api);
    this.changeRef.markForCheck();
  }

  trackByColumns(index: any, entity: TableColumn): TableColumn {
    return entity;
  }

  toggleAll(event: any) {
    return event ? this.selectAllOptions() : null;
  }

  toggleRow(event: any, row: Invoice) {
    return event ? this.selection.toggle(row) : null
  }

  async exportInvoices() {
    if (this.selection.selected.length <= 0) {
      this.snackbar.open("Nenhuma fatura selecionada", null, { duration: 4000 })
    } else {
      this.selection.selected.forEach(invoice => {
        this._invoicesIds.push(invoice.id);
      })
      this.invoicesRepository.sendInvoicesIdsToGenerateXls(this._invoicesIds).subscribe(async invoices => {
        const invoicesXls = await lastValueFrom(this.invoicesRepository.getXls());
        if (invoicesXls) {
          saveAs(invoicesXls, "Planilha de Faturas");
        }
        this.router.navigate(['/invoices'], {
          replaceUrl: false
        })
      });
    }
  }

  verifyColumnName(col: TableColumn, index: number): boolean {
    return (col?.name === this.columns[index]?.name);
  }

  async loadInvoices() {
    this._totalOfInvoices = await lastValueFrom(this.repository.size(this._query, this._perspective.filters));
    this._invoices = await lastValueFrom(this.repository.page(this.currentPage, this._query, this._perspective.filters));
    this._noInvoiceFound = this._totalOfInvoices <= 0;
    this.changeRef.markForCheck();
  }

  paginate(pagingEvent: PageEvent) {
    pagingEvent.pageIndex = pagingEvent.pageIndex * pagingEvent.pageSize;
    this.currentPage = new PageData(pagingEvent.pageIndex, pagingEvent.pageSize);
    this.loadPaginatedItems();
    this.changeRef.markForCheck();
  }

  isChecked(row: Invoice): boolean {
    return this.selection.selected.some(invoice => invoice.id === row.id);
  }

  selectAllOptionsIsChecked(): boolean {
    return (this.selection.hasValue() && this.isAllSelected());
  }

  isIndeterminateState(): boolean {
    return (this.selection.hasValue() && !this.isAllSelected());
  }

  isAllSelected(): boolean {
    return (this.selection.selected.length === this._invoices.length);
  }

  selectAllOptions() {
    this.isAllSelected() ?
      this.selection.clear() :
      this._invoices.forEach(row => this.selection.select(row));
  }

  trackByColumn(index: any, entity: TableColumn) {
    return entity.name;
  }

  isPerspectiveOpen() {
    this.openedPerspective = !this.openedPerspective;
  }

  async filterChange(event: EntityListPerspective) {
    this._perspective = event;
    this.loadInvoices();
    this.changeRef.markForCheck();
  }

  private async loadPaginatedItems() {
    this._invoices = await lastValueFrom(this.repository.page(this.currentPage, this._searchTerm));
    this.changeRef.markForCheck();
  }
}

interface TableColumn {
  name: string,
  label: string
}