import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { DateInterval, TemporalReadonlyRepository } from '@financial/arch';
import { ChartType } from "@financial/domain";
import * as Chart from 'chart.js';
import { ChartScales } from "chart.js";

@Component({
    selector: 'ufg-charts',
    templateUrl: './dashboards.component.html',
    styleUrls: ['./dashboards.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardsComponent implements OnInit {
    private _dashboards: Dashboard[] = [];
    private _selectedDashboard: Dashboard = null;
    private _chart: Chart = null;
    private _interval: DateInterval = null;

    @Input() chartType: string = "";

    @Input() chartBackgroundcolorDataset: string[] = [];

    @Input() title: string = "";

    @Input() repository: TemporalReadonlyRepository<any, any>;

    @Input() enableLegend: boolean = false;

    @Input() enableTooltip: boolean = false;

    @Input() enableScales: boolean = false;

    @Input() chartId: string = "";

    @Input()
    set interval(value: DateInterval) {
        if (this._interval === value) return;

        this._interval = value;
        this._chart?.destroy();
        this.loadChartData();
    }

    constructor(
        private changeDetection: ChangeDetectorRef,
    ) { }

    get interval(): DateInterval {
        return this._interval;
    }

    get hasData(): boolean {
        return this._dashboards.length > 0 && this._selectedDashboard != null;
    }

    get isDoughnut(): boolean {
        return this.chartType === ChartType.DOUGHNUT;
    }

    get selectedDashboard(): Dashboard {
        return this._selectedDashboard;
    }

    ngOnInit(): void {
        this.loadChartData();
    }

    private loadChartData(): void {
        this._dashboards = [];
        this.repository.list(this.interval)
            .subscribe({
                next: (result: Dashboard[]) => {
                    this._dashboards = result;
                    this._selectedDashboard = result[0];
                },
                error: (error) => console.error(error),
                complete: () => this.loadChart()
            });
    }

    private loadChart() {
        this._chart = new Chart(this.chartId, {
            type: this.chartType,
            data: {
                labels: this._dashboards.flatMap(dto => dto.name),
                datasets: [
                    {
                        data: this._dashboards.flatMap(dto => dto.value),
                        backgroundColor: this.chartBackgroundcolorDataset
                    }
                ]
            },
            options: {
                onHover: (event) => {
                    var element: any = this._chart.getElementAtEvent(event);
                    if (element.length > 0) {
                        this._selectedDashboard = this._dashboards[element[0]._index];
                        this.changeDetection.markForCheck();
                    }
                },
                responsive: true,
                legend: {
                    display: this.enableLegend,
                    position: 'bottom'
                },
                title: {
                    display: false
                },
                animation: {
                    animateScale: true,
                    animateRotate: true
                },
                tooltips: {
                    enabled: this.enableTooltip
                },
                cutoutPercentage: 70,
                scales: this.getScales()
            }
        });
        this.changeDetection.markForCheck();
    }

    private getScales(): ChartScales | undefined {
        if (this.enableScales) {
            return {
                xAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        }

        return undefined;
    }
}

interface Dashboard {
    name: string,
    value: number,
    percentage: number
}