import { CliforComponent } from './clifor/clifor.component';
import { ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ChangeAlertGuard } from '@financial/arch';
import { FinancialGuard } from '../../core/guards/financial/financial.guard';
import { ClientsComponent } from './clients.component';

const BASE_PATH = 'clients';

export const ClientsRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild([
  { path: BASE_PATH, redirectTo: `${BASE_PATH}/`, pathMatch: 'full' },
  {
    path: `${BASE_PATH}/export`,
    component: CliforComponent,
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    canActivate: [FinancialGuard],
    canDeactivate: [ChangeAlertGuard]
  },
  {
    path: `${BASE_PATH}/:id`,
    component: ClientsComponent,
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    canActivate: [FinancialGuard],
    canDeactivate: [ChangeAlertGuard]
  }
]);
