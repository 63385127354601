import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, PageData } from '@financial/arch';
import { Invoice, InvoicesRepository } from '@financial/domain';
import { lastValueFrom } from 'rxjs';
import { InvoiceFinancialAgreement } from './../../../../../../../libs/domain/src/lib/invoices/Invoice-financial-agreement';
import { OverdueInvoicesRepository } from './../../../../../../../libs/domain/src/lib/invoices/overdue-and-partially-paid-invoices-repository';

@Component({
  selector: 'app-overdue-invoices-table',
  templateUrl: './overdue-invoices-table.component.html',
  styleUrls: ['./overdue-invoices-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverdueInvoicesTableComponent implements OnInit {

  repository: OverdueInvoicesRepository;

  form: FormGroup;

  selection = new SelectionModel<any>(true, []);

  columns: TableColumn[] = [
    { name: 'select', label: 'Selecionar' },
    { name: 'number', label: 'Número da Fatura' },
    { name: 'clientName', label: 'Cliente' },
    { name: 'statusLabel', label: 'Status' },
    { name: 'formattedTotal', label: 'Valor' },
    { name: 'formattedDate', label: 'Data de Vencimento' }
  ];

  private _displayedColumns = this.columns.map(v => v.name);

  private page = new PageData(0, 10);

  private currentPage = this.page;

  private _invoicesTable: Invoice[] = [];

  private _totalOfInvoices: number;

  private _searchTerm = '';

  private _invoicesIds: string[] = [];

  private _expiration: Date;

  private _financialAgreementInvoices: InvoiceFinancialAgreement;

  private _pageSizeOptions = [10, 20, 50, 100];

  constructor(
    private api: ApiService,
    private snackbar: MatSnackBar,
    private changeRef: ChangeDetectorRef,
    private invoicesRepository: InvoicesRepository,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder
  ) { 
    this.form = fb.group({
      expiration: [this._expiration, [Validators.required]]
    })
  }

  get displayedColumns() {
    return this._displayedColumns;
  }

  get invoices() {
    return this._invoicesTable;
  }

  get totalOfInvoices() {
    return this._totalOfInvoices;
  }

  get pageSizeOptions() {
    return this._pageSizeOptions;
  }

  ngOnInit() {
    this.repository = new OverdueInvoicesRepository(this.api, this.activatedRoute.snapshot.paramMap.get('id'));
    this.changeRef.markForCheck();
  }

  trackByColumns(index: number, entity: TableColumn): TableColumn {
    return entity;
  }

  toggleAll(event: MatCheckboxChange) {
    return event ? this.selectAllOptions() : null;
  }

  toggleRow(event: MatCheckboxChange, row: Invoice) {
    return event ? this.selection.toggle(row) : null
  }

  getDateValue(event: any) {
    this._expiration = event.target.value._d;
    this.changeRef.markForCheck();
  }

  onlyFutureDaysDateFilter = (date: Date | null): boolean => {
    const now = new Date();
    const day = (date || new Date());
    return day > now;
  }

  generateNewBillables() {
    if (this.verifyExpirationInputAndInvoicesSelection()) {
      this.selection.selected
        .map(invoice => this._invoicesIds.push(invoice.id));
      this.invoicesRepository.generateFinancialAgreementInvoice(
        new InvoiceFinancialAgreement(this._invoicesIds, this._expiration)
      ).subscribe(_ => {
        this.router.navigate([`invoices`]);
      });
      this.changeRef.markForCheck();
    }
  }

  verifyColumnName(col: TableColumn, index: number): boolean {
    return (col?.name === this.columns[index]?.name);
  }

  async search(searchTerm: string) {
    this._searchTerm = searchTerm;
    if (this._searchTerm.length >= 1) {
      this._totalOfInvoices = await lastValueFrom(this.repository.size(this._searchTerm));
      this._invoicesTable = await lastValueFrom(this.repository.page(this.page, this._searchTerm));
    }
    if (this._searchTerm.length === 0) {
      this.loadInvoicesTable();
    }
    this.changeRef.markForCheck();
  }

  async loadInvoicesTable() {
    this._totalOfInvoices = await lastValueFrom(this.repository.size());
    this._invoicesTable = await lastValueFrom(this.repository.page(this.currentPage));
    this.changeRef.markForCheck();
  }

  paginate(pagingEvent: PageEvent) {
    pagingEvent.pageIndex = pagingEvent.pageIndex * pagingEvent.pageSize;
    this.currentPage = new PageData(pagingEvent.pageIndex, pagingEvent.pageSize);
    this.loadPaginatedItems();
    this.changeRef.markForCheck();
  }

  isChecked(row: Invoice): boolean {
    return this.selection.selected.some(invoice => invoice.id === row.id);
  }

  selectAllOptionsIsChecked(): boolean {
    return (this.selection.hasValue() && this.isAllSelected());
  }

  isIndeterminateState(): boolean {
    return (this.selection.hasValue() && !this.isAllSelected());
  }

  isAllSelected(): boolean {
    return (this.selection.selected.length === this._invoicesTable.length);
  }

  selectAllOptions() {
    this.isAllSelected() ?
      this.selection.clear() :
      this._invoicesTable.forEach(row => this.selection.select(row));
  }

  private async loadPaginatedItems() {
    this._invoicesTable = await lastValueFrom(this.repository.page(this.currentPage, this._searchTerm));
    this.changeRef.markForCheck();
  }

  private verifyExpirationInputAndInvoicesSelection(): boolean {
    if(this.form.untouched || this.form.controls['expiration'].invalid){
      this.snackbar.open("Uma data de expiração deve ser selecionada para continuar.", null, { duration: 4000 });
      return false
    }
    if (this.selection.selected.length <= 0) {
      this.snackbar.open("Ao menos uma fatura deve ser selecionada para continuar.", null, { duration: 4000 });
      return false;
    }
    return true;
  }
}

interface TableColumn {
  name: string,
  label: string
}
