import { InvoicesChartComponent } from './invoices-chart/invoices-chart.component';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { HomeComponent } from './home.component';
import { HomeRoutes } from './home.routes';
import { FlasksChartComponent } from './flasks-chart/flasks-chart.component';
import { DashboardsComponent } from './dashboards/dashboards.component';

@NgModule({
  imports: [CommonModule, SharedModule, HomeRoutes],
  declarations: [
    HomeComponent,
    InvoicesChartComponent,
    FlasksChartComponent,
    DashboardsComponent
  ],
  providers: [ DatePipe ]
})
export class HomeModule {}
