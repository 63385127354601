import { JsonEntity, SgvJson } from "@financial/arch";
import { Product } from "../products";

export class ProductItem implements JsonEntity {
    public totalProductValue: number = 0;
    constructor(
        public product: Product = null,
        public amount: number = 0,
    ) { }

    buildTotalProductValue() {
        this.totalProductValue = this.product.value * this.amount
    }

    toJson() {
        return SgvJson.to.simple(this, {
            product: this.product ? this.product.toJson() : null,
        });
    }

    static fromJson(json: any): ProductItem {
        return SgvJson.from.simple(json, ProductItem, {
            product: json.product ? Product.fromJson(json.product) : null,
        })
    }

}