import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { InvoiceDetails } from '@financial/domain';
import { BillableAgreement } from 'libs/domain/src/lib/billables';
import { ProductItem } from 'libs/domain/src/lib/billables/product-item';
import { ServiceItem } from 'libs/domain/src/lib/billables/service-item';
import { Item } from 'libs/domain/src/lib/invoices/item';

@Component({
  selector: 'app-invoice-details-dialog-view-table-form',
  templateUrl: './invoice-details-dialog-view-table-form.component.html',
  styleUrls: ['./invoice-details-dialog-view-table-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceDetailsDialogViewTableFormComponent implements OnInit {

  @Input() entity: InvoiceDetails;

  private _item: Item;

  private _billableAgreement: BillableAgreement;

  @Input()
  set item(item: Item) {
    if (this._item !== item) {
      this._item = item;
      this._item.setBillableItems();
    }
  }

  get item() {
    return this._item;
  }

  get billableAgreement() {
    return this._billableAgreement;
  }

  ngOnInit() {
    if(this.item.billable.isBillableAgreement){
      this._billableAgreement = this.item.billable as BillableAgreement;
    }
  }

  trackByItem(index: any, entity: ServiceItem | ProductItem) {
    return entity;
  }

}
