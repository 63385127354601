import { DomainEntity, SgvJson } from '@financial/arch';
import { Billable, BillableAnalysis, BillableSingleRequisition } from '../billables';
import { ProductItem } from '../billables/product-item';
import { ServiceItem } from '../billables/service-item';

export class Item implements DomainEntity {

  id: string;

  private _serviceItems: ServiceItem[] = [];

  private _productItems: ProductItem[] = [];

  constructor(
    public billable: Billable = null,
    public total: number = 0,
    public appliedDiscount: number = 0,
    public justification: string = null
  ) { }

  get discountJustification() {
    return this.justification ? this.justification : "-";
  }

  get productItems(){
    return this._productItems;
  }

  get serviceItems(){
    return this._serviceItems;
  }

  get serviceNumber() {
    return this.billable.protocol;
  }

  get billableName() {
    return this.billable.name;
  }
  
  get value(): number {
    return this.billable.total;
  }

  get totalValue(): number {
    return this.billable.total > this.appliedDiscount ? this.billable.total - this.appliedDiscount : this.billable.total;
  }

  get protocolNumber() {
    return this.billable.isBillableAnalysis ? (this.billable as BillableAnalysis).analysis.protocol : "-";
  }

  get signed() {
    return this.billable.isBillableAnalysis ? (this.billable as BillableAnalysis).signed ? "Sim" : "Não" : "-";
  }

  get date() {
    return this.billable.date;
  }

  setBillableItems(){
    if (this.billable.isBillableAnalysis) {
      const billableAnalysis = this.billable as BillableAnalysis;
      this._serviceItems = billableAnalysis.serviceItems;
      this._serviceItems.forEach((it) => it.buildMetadata());
    } 
    if (this.billable.isBillableSingleRequisition) {
      const billableSingleRequisition = this.billable as BillableSingleRequisition;
      this._productItems = billableSingleRequisition.productItems;
      this._productItems.forEach((it) => it.buildTotalProductValue())
    }
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      billable: this.billable ? this.billable.toJson() : null,
    });
  }

  static fromJson(json: any): Item {
    return SgvJson.from.simple(json, Item, {
      billable: json.billable ? Billable.fromJson(json.billable) : null,
    });
  }
}
