import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DefaultSharedModule } from '@financial/default-shared';
import { LayoutBaseComponent } from './layout-base.component';

@NgModule({
  imports: [CommonModule, DefaultSharedModule],
  declarations: [LayoutBaseComponent],
  exports: [LayoutBaseComponent]
})
export class LayoutBaseModule {}
