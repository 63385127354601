import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output, 
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OnlineStatusService } from '@financial/arch';
import { SearchBoxComponent } from '@financial/common-components';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-layout-base',
  templateUrl: './layout-base.component.html',
  styleUrls: ['./layout-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() fillPrimary = false;

  @Input() pageTitle: string = null;

  @Input() showSearch = false;

  @Input() searchPlaceholder = 'Pesquisar';

  @Input() showMenu = false;

  @Input() showBack = false;

  @Input() homePath = '/';

  @Output() searchChange = new EventEmitter<string>();

  @Output() menuOpened$ = new EventEmitter<boolean>();

  @ViewChild('menuContainer') menuContainer: ElementRef;

  offline = false;

  isSearchOpened$: Observable<boolean>;

  private _searchBox: SearchBoxComponent;

  private _menuOpened = false;

  private searchSubject = new BehaviorSubject('');

  private destroy$ = new EventEmitter<boolean>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private location: Location,
    private route: ActivatedRoute,
    public onlineStatusService: OnlineStatusService
  ) {
    this.searchSubject
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((search) => this.searchChange.emit(search));
  }

  @ViewChild(SearchBoxComponent)
  set searchBox(searchBox: SearchBoxComponent) {
    if (this._searchBox !== searchBox) {
      this._searchBox = searchBox;
      this.publishFromSearchBox();
    }
  }

  get searchBox(): SearchBoxComponent {
    return this._searchBox;
  }

  get menuOpened() {
    return this._menuOpened;
  }

  set menuOpened(value: boolean) {
    this.menuOpened$.emit((this._menuOpened = value));
  }

  closeMenu() {
    this.menuOpened = false;
  }

  toggleMenu() {
    this.menuOpened = !this.menuOpened;
  }

  onSearchChange(text: string) {
    this.searchSubject.next(text);
  }

  onSearchClear() {
    this.searchSubject.next('');
  }

  onGoBackClick() {
    this.location.back();
  }

  resetMenu() {
    this.menuContainer.nativeElement.scrollTo({ top: 0 });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  @Input() onSideBackClick: () => void = () => this.closeMenu();

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.route.params.subscribe((r) => {
      if (r?.id?.length === 0) this.searchBox?.focusOnInput();
    });
  }

  private publishFromSearchBox() {
    if (this.searchBox) {
      this.isSearchOpened$ = combineLatest([
        this.searchBox.isMobile$,
        this.searchBox.isSearchVisible$
      ]).pipe(map(([isMobile, isSearchVisible]) => !isMobile || !isSearchVisible));
      this.changeDetector.markForCheck();
    }
  }
}
