import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Invoice, InvoiceDetails, InvoicesRepository, LegalEntityDocuments, NaturalPersonDocuments } from '@financial/domain';
import { saveAs } from 'file-saver';
import { EceosCategory } from 'libs/domain/src/lib/eceos-categories/eceos-category';
import { InvoiceStatus } from 'libs/domain/src/lib/invoices/invoice-status';
import { Item } from 'libs/domain/src/lib/invoices/item';
import { lastValueFrom } from 'rxjs';
import { InvoiceReportComponent } from '../invoice-report/invoice-report.component';
import { InvoiceDetailsDialogComponent } from '../invoices-details-dialog/invoice-details-dialog.component';
import { EceosCategoryRepository } from './../../../../../../../libs/domain/src/lib/eceos-categories/eceos-categories.repository';
import { CancelBankSlipRequest, CancelInvoiceRequest, GenerateBankSlipRequest, ReleasedInvoiceRequest, UpdateDiscountsRequest, UpdateEceosCategoryRequest, UpdateExpirationRequest, UpdateItemsRequest } from './../../../../../../../libs/domain/src/lib/invoices/invoice-action-request';
import { InvoicePermissionsStatus } from './../../../../../../../libs/domain/src/lib/invoices/invoice-permissions-status';
import { InvoiceReport } from './../../../../../../../libs/domain/src/lib/invoices/invoice-report';
import { ItemsToUpdate } from './../../../../../../../libs/domain/src/lib/invoices/items-by-client/items-to-update';
import { CancelBankslipDialogComponent } from './cancel-bankslip-dialog/cancel-bankslip-dialog.component';
import { NumberMask } from '@financial/common-utils';

@Component({
  selector: 'app-invoice-actions',
  templateUrl: './invoice-actions.component.html',
  styleUrls: ['./invoice-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceActionsComponent implements OnInit {

  isEditMode = false;

  form: FormGroup;

  loading = true;

  private _permissions: InvoicePermissionsStatus[] = [];

  private _lastPermissions: InvoicePermissionsStatus[] = [];  

  private _expiration: Date;

  private _entity: Invoice = null;

  private _discountHasBeenUpdated = false;

  private _discounts: Item[] = [];

  private _eceosCategory: EceosCategory;

  constructor(
    private invoiceRepository: InvoicesRepository,
    private activatedroute: ActivatedRoute,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private changeRef: ChangeDetectorRef,
    private router: Router,
    private snackbar: MatSnackBar,
    public eceosCategoryRepository: EceosCategoryRepository
  ) {
    this.form = fb.group({
      expiration: [this._expiration, [Validators.required]],
      eceosCategory: [this._eceosCategory, [Validators.required]]
    });
  }

  get entity() {
    return this._entity;
  }

  get showInvoiceDetails(): boolean {
    return this.checkByStatus(InvoicePermissionsStatus.INVOICE_DETAILS);
  }

  get showGenerateBankslip(): boolean {
    return this.checkByStatus(InvoicePermissionsStatus.GENERATE_BANKSLIP);
  }

  get showCancelBankslip(): boolean {
    return this.checkByStatus(InvoicePermissionsStatus.CANCEL_BANKSLIP);
  }

  get showFinancialRelease(): boolean {
    return this.checkByStatus(InvoicePermissionsStatus.FINANCIAL_RELEASE);
  }

  get isInvoiceStatusNotCanceled(): boolean {
    return this?._entity?.status !== InvoiceStatus.CANCELED;
  }

  get expirationControl(): AbstractControl {
    return this.form.get('expiration');
  }

  get eceosCategoryControl(): AbstractControl {
    return this.form.get('eceosCategory');
  }

  get expirationControlHasValue(): boolean {
    return this.expirationControl === null;
  }

  get eceosCategoryControlHasValue(): boolean {
    return this.eceosCategoryControl === null;
  }

  get clientCnp(): string {
    return this.entity.client.cnp;
  }

  get formattedClientCnp(): string {
    return this.clientCnp.length > 11 ? '99.999.999/9999-99' : '999.999.999-99';
  }

  get pageTitle(): string {
    return `Fatura - ${this.entity.clientName} - ${new NumberMask(this.formattedClientCnp).apply(this.clientCnp)}`
  }

  async ngOnInit() {
    this._entity = await lastValueFrom(this.invoiceRepository.find(this.activatedroute.snapshot.paramMap.get('id')));
    this.setFormValues();
    this.updatePermissions("carregar a fatura");
    this.loading = false;
    this.changeRef.markForCheck();
  }

  getDateValue(event: any) {
    this._expiration = event.target.value._d;
    this.changeRef.markForCheck();
  }

  trackByItems(index: any, entity: Item): Item {
    return entity;
  }

  onlyFutureDaysDateFilter = (date: Date | null): boolean => {
    const now = new Date();
    const day = (date || new Date());
    return day > now;
  }

  allowDateChoice(): boolean {
    return this.showFinancialRelease && this.isEditMode;
  }

  saveDiscount(items: Item[]) {
    this._discounts = items;
    this._discountHasBeenUpdated = true;
  }

  async saveInvoiceItems(items: ItemsToUpdate) {
    if (!this._discountHasBeenUpdated
      && !this.isExpirationValidToSave()
      && (items.itemsToAdd.length <= 0 && items.itemsToRemove.length <= 0)
      && !this.isCategoryValidToSave()) {
      this.showSnackbarMessage("Nenhum item da fatura foi alterado");
    }
    if (this._discounts.length > 0) {
      await lastValueFrom(this.invoiceRepository.invoiceActionRequest(
        this._entity,
        new UpdateDiscountsRequest(this._discounts.map(it => it.toJson()))
      ));
      this._discountHasBeenUpdated = false;
    }
    if (this.isExpirationValidToSave()) {
      await lastValueFrom(this.invoiceRepository.invoiceActionRequest(
        this._entity,
        new UpdateExpirationRequest(this._expiration)
      ));
      this._expiration = null;
    }
    if (this.isCategoryValidToSave()) {
      await lastValueFrom(this.invoiceRepository.invoiceActionRequest(
        this._entity,
        new UpdateEceosCategoryRequest(this._eceosCategory)
      ));
    }
    if ((items.itemsToAdd.length > 0 || items.itemsToRemove.length > 0)) {
      await lastValueFrom(this.invoiceRepository.invoiceActionRequest(
        this._entity,
        new UpdateItemsRequest(
          items.itemsToAdd.map((it) => it.toJson()),
          items.itemsToRemove.map((it) => it.toJson())
        )
      ));
    }
    this._entity = await lastValueFrom(this.invoiceRepository.find(this._entity.id));
    this.closeInvoiceUpdate();
    this.changeRef.markForCheck();
  }

  async closeInvoiceUpdate() {
    this.isEditMode = false;
    this.changeRef.markForCheck();
  }

  async editInvoice() {
    this.isEditMode = true;
    this.changeRef.markForCheck();
  }

  async financialRelease() {
    this.validateDateInput();
    if (this.form.valid) {
      await lastValueFrom(this.invoiceRepository.invoiceActionRequest(
        this._entity,
        new ReleasedInvoiceRequest()
      ));
      this.entity.expiration = this._expiration;
      this.updatePermissions("liberar fatura");
    }
    this.changeRef.markForCheck();
  }

  async cancelInvoice() {
    await lastValueFrom(this.invoiceRepository.invoiceActionRequest(
      this._entity,
      new CancelInvoiceRequest
    ));
    this.router.navigate(['/invoices']);
  }

  async generateBankslip() {
    await lastValueFrom(this.invoiceRepository.invoiceActionRequest(
      this._entity,
      new GenerateBankSlipRequest
    ));
    this.updatePermissions("gerar boleto");
  }

  async printBankslip() {
    const bankslip = await lastValueFrom(this.invoiceRepository.getBankSlip(this._entity));
    if (bankslip) {
      saveAs(bankslip, `Boleto referente a fatura ${this._entity.number}`);
    }
  }

  async loadInvoiceDetails() {
    this.invoiceRepository.getDetails(this._entity.id).subscribe(invoiceDetails => {
      this.openDetailsDialog(invoiceDetails);
    });
  }

  loadInvoiceReport() {
    this.invoiceRepository.getReport(this._entity.id).subscribe(invoiceReport => {
      this.openReportDialog(invoiceReport);
    })
  }

  openCancelBankslipDialog() {
    const dialogRef = this.dialog.open(CancelBankslipDialogComponent, {
      width: '50%',
      maxWidth: '50%',
      height: '30%',
      data: ''
    });
    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result) {
          this.cancelBankslip(result.data)
            .catch(error => 
              this.snackbar.open(error.error.error, null, { duration: this.calculateReadingTime(error.error.error)})
            )
        }
      });
  }

  private calculateReadingTime(message: string): number {
    const amountWords = message.split(" ").length;
    const milliSeconds = Math.max(4, Math.min(10, amountWords / 4)) * 1000;
    return milliSeconds;
  }

  private async updatePermissions(action: string) {
    this._lastPermissions = this._permissions;
    this._permissions = await lastValueFrom(this.invoiceRepository.getPermissions(this._entity));
    if(this.compareInvoicePermissions()) {
      this.showSnackbarMessage(`Não foi possível ${action}. Por favor, entre em contato com o suporte.`)
    }
    this.changeRef.markForCheck();
  }

  private async cancelBankslip(justification: string) {
    await lastValueFrom(this.invoiceRepository.invoiceActionRequest(
      this._entity,
      new CancelBankSlipRequest(justification)
    ));
    this.updatePermissions("cancelar boleto");
  }

  private async setFormValues() {
    if (this._entity.expiration) {
      this.expirationControl.setValue(this._entity.expiration)
    }
    if (this._entity.eceosCategory) {
      this.eceosCategoryControl.setValue(await lastValueFrom(this.eceosCategoryRepository.find(this._entity.eceosCategory.id)));
    }
  }

  private openReportDialog(invoice: InvoiceReport) {
    this.dialog.open(InvoiceReportComponent, {
      panelClass: 'reports-dialog-container',
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      data: invoice
    })
  }

  private openDetailsDialog(invoice: InvoiceDetails) {
    this.dialog.open(InvoiceDetailsDialogComponent, {
      panelClass: 'reports-dialog-container',
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      data: invoice
    })
  }

  private validateDateInput(): void {
    if (this.expirationControlHasValue) {
      this.showSnackbarMessage("Uma data de expiração deve ser selecionada para liberar a fatura.");
    }
    if(this.eceosCategoryControlHasValue) {
      this.showSnackbarMessage("Uma categoria financeira deve ser selecionada para liberar a fatura.");
    }
  }

  private showSnackbarMessage(message: string) {
    this.snackbar.open(message, null, { duration: 4000 });
  }

  private checkByStatus(status: InvoicePermissionsStatus): boolean {
    return this._permissions.includes(status);
  }

  private isCategoryValidToSave(): boolean {
    this._eceosCategory = this.eceosCategoryControl.value;
    if (this.form.untouched || (this.eceosCategoryControl.untouched && !this._eceosCategory)) {
      return false;
    }
    this._entity.eceosCategory = this._eceosCategory;
    return true;
  }

  private isExpirationValidToSave(): boolean {
    if (this.form.untouched || (this.expirationControlHasValue && !this._expiration)) {
      return false;
    }
    this._entity.expiration = this._expiration;
    return true;
  }

  private compareInvoicePermissions(): boolean {
    return this._permissions.toString() === this._lastPermissions.toString() && this._permissions.length > 0;
  }

}
