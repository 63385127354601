import { Injectable } from '@angular/core';
import { ApiService, TemporalReadonlyRepositoryImpl } from '@financial/arch';
import { Invoice } from './invoice';

@Injectable({ providedIn: 'root' })
export class InvoicesTemporalRepository extends TemporalReadonlyRepositoryImpl<Invoice, Invoice> {
  constructor(api: ApiService) {
    super(api.root.path('invoices').path('temporal'), Invoice, Invoice);
  }
}
