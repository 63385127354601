import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoginService } from './login.service';

import { SharedModule } from '../../shared/shared.module';
import { LoginPageComponent } from './login.component';
import { LoginRoutes } from './login.routes';

@NgModule({
  imports: [CommonModule, SharedModule, LoginRoutes],
  declarations: [LoginPageComponent],
  providers: [LoginService],
  exports: [LoginPageComponent]
})
export class LoginModule {}
