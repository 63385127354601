import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '../../shared/shared.module';
import { BillablesComponent } from './billables.component';
import { BillableRoutes } from './billables.routes';
import { BillablesDetailsComponent } from './billables-details/billables-details.component';

@NgModule({
  imports: [CommonModule, SharedModule, MatTableModule, BillableRoutes],
  declarations: [BillablesComponent, BillablesDetailsComponent]
})
export class BillablesModule { }
