import { MatIconRegistry } from '@angular/material/icon';

import { DomSanitizer } from '@angular/platform-browser';

import { IconDefinition } from '@fortawesome/fontawesome-common-types';

import {
  faQrcode,
  faFileInvoice,
  faFileInvoiceDollar,
  faUser,
  faInfo,
  faWrench,
  faMoneyBill,
  faCalendarDay,
  faIdCard,
  faSignOutAlt,
  faHome,
  faFilter,
  faBoxOpen,
  faFolderOpen,
  faBox,
  faBarcode,
} from '@fortawesome/free-solid-svg-icons';
import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class FontAwesomeRegister {
  private readonly iconsToRegistry = [
    faQrcode,
    faFileInvoice,
    faFileInvoiceDollar,
    faUser,
    faInfo,
    faWrench,
    faMoneyBill,
    faCalendarDay,
    faIdCard,
    faHome,
    faSignOutAlt,
    faFilter,
    faBoxOpen,
    faFolderOpen,
    faBox,
    faBarcode,
    faFileInvoice
  ];

  private registered = new Set<IconDefinition>();

  constructor(private mdIconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {}

  registerDefault(){
    this.registerAll(...this.iconsToRegistry);
  }

  registerAll(...icons:IconDefinition[]) {
    for (const i of icons) {
      this.registerIcon(i);
    }
  }

  registerIcon(iconDef: IconDefinition, namespaceOnly: boolean = false) {
    if (this.registered.has(iconDef)) return;

    const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${iconDef.icon[0]} ${iconDef.icon[1]}"><path d="${iconDef.icon[4]}" /></svg>;`; // use template strings

    this.mdIconRegistry.addSvgIconLiteralInNamespace(
      iconDef.prefix,
      iconDef.iconName,
      this.sanitizer.bypassSecurityTrustHtml(svg)
    );
    if (!namespaceOnly) {
      this.mdIconRegistry.addSvgIconLiteral(
        iconDef.iconName,
        this.sanitizer.bypassSecurityTrustHtml(svg)
      );
    }
    this.registered.add(iconDef);
  }
}
