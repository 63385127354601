import { Injectable } from "@angular/core";
import { ApiService, TemporalReadonlyRepositoryImpl } from "@financial/arch";
import { FlasksChart } from "@financial/domain";


@Injectable({ providedIn: 'root' })
export class FlasksChartRepository extends TemporalReadonlyRepositoryImpl<FlasksChart, FlasksChart>{

  constructor(api: ApiService) {
    super(
      api.root
        .path('singleRequisitions')
        .path('reports')
        .path('chartData'),
      FlasksChart,
      FlasksChart
    )
  }
}