<table *ngIf="!item.billable.isBillableAgreement">
  <tr>
    <th class="name">Descrição</th>
    <th class="description"></th>
    <th class="amount">Qtd</th>
    <th class="value">Valor</th>
    <th class="total">Total</th>
  </tr>
  <ng-container *ngIf="item.billable.isBillableAnalysis">
    <tr *ngFor="let serviceItem of item.serviceItems; trackBy: trackByItem">
      <td class="name">{{ serviceItem.service.name }}</td>
      <td></td>
      <td>{{ serviceItem.amount }}</td>
      <td class="value">{{ serviceItem.serviceValue | currency: 'BRL' }}</td>
      <td class="total">{{ serviceItem.totalServiceValue | currency: 'BRL' }}</td>
    </tr>
  </ng-container>
  <ng-container *ngIf="item.billable.isBillableSingleRequisition">
    <tr *ngFor="let productItem of item.productItems; trackBy: trackByItem">
      <td class="name">{{ productItem.product.formattedName }}</td>
      <td></td>
      <td>{{ productItem.amount }}</td>
      <td class="value">{{ productItem.product.value | currency: 'BRL' }}</td>
      <td class="total">{{ productItem.totalProductValue | currency: 'BRL' }}</td>
    </tr>
  </ng-container>
  <tr *ngIf="item.justification">
    <td class="name">Justificativa de débito/crédito: {{ item.justification }}</td>
  </tr>
  <ng-container *ngIf="isInvoiceDetails">
    <tr>
      <td></td>
      <td>(=) Sub. Total</td>
      <td></td>
      <td></td>
      <td class="total">{{ item.total | currency: 'BRL'}}</td>
    </tr>
    <tr>
      <td></td>
      <td>(+) Encargo(s)</td>
      <td></td>
      <td></td>
      <td class="total">{{ 0 | currency: 'BRL'}}</td>
    </tr>
    <tr>
      <td></td>
      <td>(-) Descontos</td>
      <td></td>
      <td></td>
      <td class="total">{{ item.appliedDiscount | currency: 'BRL'}}</td>
    </tr>
    <tr>
      <td></td>
      <td>(=) Saldo Total</td>
      <td></td>
      <td></td>
      <td class="total">{{ item.totalValue | currency: 'BRL'}}</td>
    </tr>
  </ng-container>
</table>
<table *ngIf="item.billable.isBillableAgreement">
  <tr>
    <th class="name">Descrição</th>
    <th class="description"></th>
    <th class="amount"></th>
    <th class="value"></th>
    <th class="total">Total</th>
  </tr>
  <tr>
    <td class="name">Referente a Fatura nº {{ billableAgreement.invoiceNumber }}</td>
    <td></td>
    <td></td>
    <td class="value"></td>
    <td class="total">{{ billableAgreement.value | currency: 'BRL' }}</td>
  </tr>
</table>