import { ServicesComponent } from './services.component';
import { ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ChangeAlertGuard } from '@financial/arch';
import { FinancialGuard } from '../../core/guards/financial/financial.guard';

const BASE_PATH = 'services';

export const ServiceRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild([
  { path: BASE_PATH, redirectTo: `${BASE_PATH}/`, pathMatch: 'full' },
  {
    path: `${BASE_PATH}/:id`,
    component: ServicesComponent,
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    canActivate: [FinancialGuard],
    canDeactivate: [ChangeAlertGuard]
  }
]);
