import { Filter, SortOrder, Actives } from '@financial/arch';

export interface EntityListPerspective {
  name: string;
  filters: Filter[];
  sorts: SortOrder[];
  actives: Actives
  length: number;
  isEqual(perspective: EntityListPerspective): boolean;
}

export class SimpleEntityListPerspective implements EntityListPerspective {
  constructor(
    public name: string = '',
    public filters: Filter[] = [],
    public sorts: SortOrder[] = [],
    public actives = Actives.TRUE
  ) {}

  addFilter(f: Filter) {
    return new SimpleEntityListPerspective(this.name, [...this.filters, f], [...this.sorts]);
  }

  removeFilter(f: Filter) {
    return new SimpleEntityListPerspective(this.name, this.filters.filter(v => v !== f), [
      ...this.sorts
    ]);
  }

  addSort(o: SortOrder) {
    return new SimpleEntityListPerspective(this.name, [...this.filters], [...this.sorts, o]);
  }

  removeSort(o: SortOrder) {
    return new SimpleEntityListPerspective(
      this.name,
      [...this.filters],
      this.sorts.filter(v => v !== o)
    );
  }

  get length() {
    return this.filters.length + this.sorts.length;
  }

  isEqual(perspective: EntityListPerspective) {
    const same =
      perspective === this ||
      (perspective.length === this.length &&
        perspective.name === this.name &&
        perspective.filters &&
        perspective.filters.length === this.filters.length &&
        perspective.sorts.length === this.sorts.length &&
        perspective.actives === this.actives &&
        this.isArrayEqual(perspective.filters, this.filters) &&
        this.isArrayEqual(perspective.sorts, this.sorts));

    return same;
  }

  private isArrayEqual(v1: any[], v2: any[]) {
    if (v1.length !== v2.length) {
      return false;
    }
    for (let i = 0; i < v1.length; i++) {
      if (v1[i] !== v2[i]) {
        return false;
      }
    }
    return true;
  }
}
