import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BillablesModule } from './billables/billables.module';
import { ClientsModule } from './clients/clients.module';
import { DiscountModule } from './discounts/discounts.module';
import { FeaturesRoutes } from './features.routes';
import { HomeModule } from './home/home.module';
import { ClientsWithItemstModule } from './invoices/clients-with-items/clients-with-items.module';
import { InvoicesModule } from './invoices/invoices.module';
import { LoginModule } from './login/login.module';
import { ProductsModule } from './products/products.module';
import { ServicesModule } from './services/services.module';
import { SettingsModule } from './settings/settings.module';

@NgModule({
  imports: [
    CommonModule,
    LoginModule,
    HomeModule,
    ClientsModule,
    DiscountModule,
    ProductsModule,
    FeaturesRoutes,
    ServicesModule,
    InvoicesModule,
    SettingsModule,
    BillablesModule,
    ClientsWithItemstModule
  ],
  declarations: []
})
export class FeaturesModule { }
