<app-layout 
    [showMenu]="false" 
    [showBack]="true" 
    *ngIf="!loading" 
    [pageTitle]="pageTitle"
  >
  <section [formGroup]="form">
    <fieldset>
      <section class="inline-infos">
        <mat-form-field>
          <mat-label>Data de Vencimento</mat-label>
          <input *ngIf="allowDateChoice" [disabled]="!isEditMode" (dateInput)="getDateValue($event)" formControlName="expiration" matInput
            [matDatepicker]="picker" [matDatepickerFilter]="onlyFutureDaysDateFilter" required>
          <input *ngIf="!allowDateChoice" [value]="entity.expiration | date: 'shortDate'" matInput readonly>
          <mat-datepicker-toggle matSuffix [for]="picker">
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <app-data-selector class="category"
          [disabled]="!isEditMode"
          label="Categoria Financeira"
          [repository]="eceosCategoryRepository"
          formControlName="eceosCategory"
        ></app-data-selector>
        <ng-container *ngIf="showFinancialRelease">
          <button mat-button (click)="editInvoice()">Editar Fatura</button>
        </ng-container>
        <ng-container *ngIf="showFinancialRelease">
          <button mat-button [disabled]="isEditMode" (click)="financialRelease()">Liberar Fatura</button>
        </ng-container>
        <ng-container *ngIf="showGenerateBankslip">
          <button mat-button (click)="generateBankslip()">Gerar Boleto</button>
        </ng-container>
        <ng-container *ngIf="showCancelBankslip">
          <button mat-button (click)="openCancelBankslipDialog()">Cancelar Boleto</button>
          <button mat-button (click)="printBankslip()">Imprimir Boleto</button>
        </ng-container>
        <ng-container *ngIf="showInvoiceDetails">
          <button mat-button (click)="loadInvoiceReport()">Relatório da Fatura</button>
          <button mat-button (click)="loadInvoiceDetails()">Detalhes da Fatura</button>
        </ng-container>
        <button *ngIf="isInvoiceStatusNotCanceled" [disabled]="showCancelBankslip" mat-button (click)="cancelInvoice()">Cancelar Fatura</button>
      </section>
    </fieldset>
    <mat-card *ngIf="!isEditMode">
      <table>
        <tr>
          <th class="billable">Faturável</th>
          <th class="values">Protocolo</th>
          <th class="values">Assinado</th>
          <th class="values">Desconto</th>
          <th class="values">Justificativa</th>
          <th class="values">Valor Total</th>
        </tr>
        <tr *ngFor="let item of entity.items; trackBy: trackByItems">
          <td class="billable">{{ item.billableName | truncate: [55] }}</td>
          <td class="values">{{ item.protocolNumber }}</td>
          <td class="values">{{ item.signed }}</td>
          <td class="values">{{ item.appliedDiscount | currency: 'BRL' }}</td>
          <td class="values">{{ item.discountJustification }}</td>
          <td class="values">{{ item.totalValue | currency: 'BRL' }}</td>
        </tr>
      </table>
    </mat-card>
    <section *ngIf="isEditMode">
      <app-items-client-table [invoice]="entity" (update)="saveInvoiceItems($event)" (updateAppliedDiscount)="saveDiscount($event)" (cancel)="closeInvoiceUpdate()">
      </app-items-client-table>
    </section>
  </section>
</app-layout>