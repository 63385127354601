import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { PageData, ReadonlyRepository, SortOrder } from '@financial/arch';
import { RepositoryDataSource } from '../shared';
import { LoadQueue } from '../shared/load-helper';

interface EntityTableColumn {
  name: string;
  label: string;
  tooltip?: string;
  numeric?: boolean;
  format?: (value: any) => any;
  nested?: boolean;
  sortable?: boolean;
  hidden?: boolean;
  filter?: boolean;
  width?: number;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'entity-table',
  templateUrl: './entity-table.component.html',
  styleUrls: ['./entity-table.component.scss']
})
export class EntityTableComponent<S> implements OnInit {
  @Input() title: string;

  @Input() columns: EntityTableColumn[] = [];

  @Input() sortBy: SortOrder;

  @Input() showCheckbox = false;

  @Output() entitySelect = new EventEmitter<S>();

  @Output() loadFail = new EventEmitter<Error>();

  @Output() selectedItems = new EventEmitter<S[]>();

  @Output() save = new EventEmitter<string>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  @Input() repository: ReadonlyRepository<S, any>;

  dataSource: RepositoryDataSource<S>

  loadQueue = new LoadQueue();

  selection = new SelectionModel<S>(true, []);

  checkboxColumn: EntityTableColumn;

  constructor(private snackbar: MatSnackBar) { }

  get displayedColumns() {
    return this.columns.map(v => v.name)
  }

  get loading() {
    return !this.loadQueue.empty;
  }

  ngOnInit(): void {
    if (this.showCheckbox) {
      this.fillCheckboxColumn();
    }
    this.dataSource = new RepositoryDataSource(this.repository);
    this.dataSource.paginate(new PageData(this.paginator.pageIndex, this.paginator.pageSize));
  }

  fillCheckboxColumn() {
    this.checkboxColumn =
      { name: 'select', label: 'Selecionar' }
    this.columns.unshift(this.checkboxColumn)
  }

  onRowClick(entity: S) {
    this.entitySelect.emit(entity);
  }

  search(searchTerm: string): void {
    this.dataSource.search(searchTerm);
  }

  paginate(pagingEvent: PageEvent): void {
    pagingEvent.pageIndex = pagingEvent.pageIndex * pagingEvent.pageSize;
    this.dataSource.paginate(new PageData(pagingEvent.pageIndex, pagingEvent.pageSize));
  }

  invalidate() {
    this.dataSource.invalidate();
  }

  hasCheckboxColumn(col: EntityTableColumn): boolean {
    if (col.name === this.checkboxColumn.name) {
      return true;
    }
    return false;
  }

  selectAllOptionsIsChecked(): boolean {
    if (this.selection.hasValue() && this.isAllSelected()) {
      return true;
    }
    return false;
  }

  isChecked(row: any): boolean {
    if (this.selection.isSelected(row)) {
      return true;
    }
    return false;
  }

  isIndeterminateState(): boolean {
    if (this.selection.hasValue() && !this.isAllSelected()) {
      return true;
    }
    return false;
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.value.length;
    return numSelected === numRows;
  }

  selectAllOptions() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data
        .value.forEach(row => this.selection.select(row));
  }

  saveSelectedItems() {
    this.getSelectedItems()
    this.save.emit()
  }

  private getSelectedItems() {
    if (this.selection.selected.length > 0) {
      return this.selectedItems.emit(this.selection.selected);
    } else {
      this.snackbar.open("Ao menos um item deve ser selecionado para continuar.", null, { duration: 4000 })
    }
  }
}
