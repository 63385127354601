import { EceosCategory } from 'libs/domain/src/lib/eceos-categories/eceos-category';
import { InvoiceActions } from "./invoice-action";
import { Item } from "./item";

export interface InvoiceActionRequest { action: string }

export class ReleasedInvoiceRequest implements InvoiceActionRequest {
    action = InvoiceActions.RELEASE_INVOICE
}

export class CancelInvoiceRequest implements InvoiceActionRequest {
    action = InvoiceActions.CANCEL_INVOICE
}

export class GenerateBankSlipRequest implements InvoiceActionRequest {
    action = InvoiceActions.GENERATE_BANK_SLIP
}

export class CancelBankSlipRequest implements InvoiceActionRequest {
    action = InvoiceActions.CANCEL_BANK_SLIP
    constructor(public justification: string = "") { }
}

export class UpdateExpirationRequest implements InvoiceActionRequest {
    action = InvoiceActions.UPDATE_EXPIRATION
    constructor(public expiration: Date) { }
}

export class UpdateEceosCategoryRequest implements InvoiceActionRequest {
    action = InvoiceActions.UPDATE_CATEGORY
    constructor(public eceosCategory: EceosCategory) { }
}

export class UpdateDiscountsRequest implements InvoiceActionRequest {
    action = InvoiceActions.UPDATE_DISCOUNTS
    constructor(public discounts: Item[] = []) { }
}

export class UpdateItemsRequest implements InvoiceActionRequest {
    action = InvoiceActions.UPDATE_ITEMS
    constructor(
        public itemsToAdd: Item[] = [],
        public itemsToRemove: Item[] = []
    ) { }
}
