<section class="hidden-on-print">
  <section>
    <header mat-dialog-title>
      <section>
        <button class="back-action" mat-icon-button (click)="onBackClick()">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </section>
      <section class="title">
        <ng-container>Fatura</ng-container>
      </section>
      <section>
        <button mat-icon-button (click)="onPrintClick()">
          <mat-icon>print</mat-icon>
        </button>
      </section>
    </header>
  </section>
  <mat-dialog-content>
    <ng-container>
      <fieldset class="inline-infos">
        <mat-form-field thin>
          <mat-label>Faturar para</mat-label>
          <input matInput [value]="entity.recipient" readonly />
        </mat-form-field>
      </fieldset>
      <app-invoice-clients-content [entity]="entity"></app-invoice-clients-content>
      <fieldset>
        <legend>Lançamentos: Serviços e Créditos de Recebimentos</legend>
        <div *ngFor="let item of entity.items; trackBy: trackByFn" class="service">
          <section *ngIf="item.billable.isBillableAnalysis" class="inline-infos">
            <mat-form-field thin>
              <mat-label>Data</mat-label>
              <input matInput [value]="entity.date | date: 'short'" readonly />
            </mat-form-field>
            <mat-form-field thin>
              <mat-label>Número</mat-label>
              <input matInput [value]="entity.number" readonly />
            </mat-form-field>
          </section>
          <app-invoice-details-dialog-view-table-form [entity]="entity" [item]="item">
          </app-invoice-details-dialog-view-table-form>
        </div>
      </fieldset>
    </ng-container>
  </mat-dialog-content>
</section>

<section class="show-only-on-print columns">
  <print-header title="Fatura"></print-header>
  <fieldset>
    <mat-form-field thin class="inline-infos">
      <mat-label>Faturar para</mat-label>
      <input matInput [value]="entity.recipient" readonly />
    </mat-form-field>
  </fieldset>
  <app-invoice-clients-content [entity]="entity"></app-invoice-clients-content>
  <fieldset>
    <section class="inline-infos">
      <mat-form-field class="short-field" thin>
        <mat-label>Vencimento</mat-label>
        <input matInput [value]="entity.expiration | date: 'shortDate'" readonly />
      </mat-form-field>
      <mat-form-field class="short-field" thin>
        <mat-label>Total Fatura</mat-label>
        <input matInput [value]="entity.total | currency: 'BRL'" readonly />
      </mat-form-field>
      <mat-form-field class="short-field" thin>
        <mat-label>Referência</mat-label>
        <input matInput [value]="entity.reference | date: 'MM/yyyy'" readonly />
      </mat-form-field>
      <mat-form-field class="short-field" thin>
        <mat-label>Número</mat-label>
        <!-- TODO: VALIDAR SE É O NÚMERO DA NFSE OU NÚMERO DA FATURA -->
        <!-- <input matInput [value]="entity.nFSeNumber" readonly /> -->
        <input matInput [value]="entity.number">
      </mat-form-field>
    </section>
  </fieldset>
  <fieldset>
    <legend>Lançamentos: Serviços e Créditos de Recebimentos</legend>
    <div *ngFor="let item of entity.items; trackBy: trackByFn" class="page-break">
      <section class="inline-infos">
        <ng-container *ngIf="item.billable.isBillableAnalysis">
          <mat-form-field thin>
            <mat-label>Data</mat-label>
            <!-- TODO: Conferir a data -->
            <input matInput [value]="item.billable.processedDate | date: 'short'" readonly />
          </mat-form-field>
        </ng-container>
        <app-invoice-details-dialog-print-table [entity]="entity" [item]="item" class="table-in-grid">
        </app-invoice-details-dialog-print-table>
      </section>
      <mat-divider></mat-divider>
    </div>
  </fieldset>
  <app-invoice-total-print-table [entity]="entity"></app-invoice-total-print-table>
</section>