import { DomainEntity, SgvId, SgvJson } from '@financial/arch';
import { LegalEntityDocuments } from '@financial/domain';
import { NumberMaskPipe } from './../../../../../common-components/src/lib/mask/mask-pipe.pipe';
import { NaturalPersonDocuments } from './../../core/documents';

export class InvoiceDataClifor implements DomainEntity {

  private pipe: NumberMaskPipe = new NumberMaskPipe();

  constructor(
    readonly id: string = SgvId.gen(),
    public idClient: string = '',
    public idInvoice: string = '',
    public cliforCode: string = '',
    public cnp: string = '',
    public nfseNumber: string = '',
    public nfseValue: number = 0,
    public nfseEmission: Date = null,
    public bankSlipNumber: number = 0,
    public bankSlipValue: number = 0,
    public sendFunape: boolean = false,
    public isActive = true
  ) { }

  get formattedCnp() {
    return this.pipe.transform(this.cnp, this.cnp.length > 11 ? LegalEntityDocuments.MASK : NaturalPersonDocuments.MASK);
  }

  get funapeSentStatus() {
    return this.sendFunape ? 'Sim' : 'Não';
  }

  toJson() {
    return SgvJson.to.simple(this)
  }

  static fromJson(json: any): InvoiceDataClifor {
    return SgvJson.from.simple(json, InvoiceDataClifor, {
      nfseEmission: json.nfseEmission ? new Date(json.nfseEmission) : null
    });
  }

}