import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EntityCrudState } from '@financial/common-components';
import { EceosOperatablesRepository, Product, ProductsRepository } from '@financial/domain';
import { lastValueFrom } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-products-details',
  templateUrl: './products-details.component.html',
  styleUrls: ['./products-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductsDetailsComponent {

  form: FormGroup;

  hasChargingFlow = false;

  @Output() entityChange = new EventEmitter<Product>();

  @Output() submit = new EventEmitter<Product>();

  @Output() validChange = new EventEmitter<boolean>();

  @Output() dirtyChange = new EventEmitter<boolean>();

  private _entity: Product;

  private _state: EntityCrudState;

  private _valid = true;

  private _dirty = false;

  constructor(
    private formBuilder: FormBuilder,
    private ref: ChangeDetectorRef,
    public productsRepository: ProductsRepository,
    public eceosOperatableRepository: EceosOperatablesRepository
  ) { }

  @Input()
  set entity(entity: Product) {
    if (this._entity === entity) {
      return;
    }

    this._entity = entity;
    this.createFormFromEntity();
    this.setEceosOperatable();
  }

  get entity() {
    return this._entity;
  }

  @Input()
  set state(value: EntityCrudState){
    this._state = value;
    this.syncFromState();
  }

  get eceosOperatableControl(): AbstractControl {
    return this.form.get('eceosOperatable');
  }

  async setEceosOperatable() {
    if (this._entity?.eceosOperatableId) {
      this.eceosOperatableControl.setValue(await lastValueFrom(this.eceosOperatableRepository.find(this._entity.eceosOperatableId)));
      this.dirtyChange.emit(this._dirty = false);
    }
  }

  onSubmit() {
    this.submit.emit(this.entity);
  }

  private createFormFromEntity() {
    this.form = null;
    this.ref.detectChanges();
    if (this.entity) {
      this.form = this.formBuilder.group({
        name: [this.entity.name, Validators.required],
        code: [this.entity.code, Validators.required],
        value: [this.entity.value, Validators.required],
        eceosOperatable: [this.entity.eceosOperatable, Validators.required]
      });
      this.addFormValueChange((product: Product) => new Product(this._entity.id, product.name, product.code, product.value, product.eceosOperatable));
      if (this.form) {
        this.form.statusChanges.subscribe((s: any) => {
          if (this.form.dirty !== this._dirty) {
            this.dirtyChange.emit((this._dirty = this.form.dirty));
          }
          if (this.form.valid !== this._valid) {
            this.validChange.emit((this._valid = this.form.valid));
          }
        });
      }
      this.syncFromState();
    }
  }


  private syncFromState() {
    if (this.form) {
      if (this._state === EntityCrudState.INSERT || this._state === EntityCrudState.EDIT) {
        this.form.enable();
      } else {
        this.form.disable();
      }
    }
  }

  addFormValueChange(mapper: (f: any) => Product) {
    this.form.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(300), map(mapper))
      .subscribe(value => {
        this.entityChange.emit((this._entity = value));
      });
  }
}
