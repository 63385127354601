import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Functionality } from '@financial/arch';
import {
  EntityCrudComponent,
  EntityCrudType,
  FilterDescription,
  FilterType,
  SortDescription
} from '@financial/common-components';
import { Service, SERVICES, ServiceRepository } from '@financial/domain';
import { filter, lastValueFrom, take } from 'rxjs';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  @ViewChild(EntityCrudComponent, { static: true }) crud: EntityCrudComponent;

  entityTypes: EntityCrudType[] = [{ 
    title: 'Serviços', 
    creator: () => new Service(),
    showHelpButton: false
  }];

  fabOpen = true;

  functionality: Functionality = SERVICES;

  allowedFilters = [
    new FilterDescription('name', 'Nome', FilterType.TEXTUAL),
    new FilterDescription('value', 'Valor', FilterType.TEXTUAL)
  ];

  allowedSorts = [new SortDescription('name', 'Nome'), new SortDescription('value', 'Valor')];

  constructor(
    public repository: ServiceRepository,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit() {}

  hasUnsavedState() {
    return this.crud.hasUnsavedState();
  }
}
