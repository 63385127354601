<app-layout pageTitle="Exportar Faturas" [showMenu]="false" [showBack]="true">
  <section actions>
    <button mat-icon-button (click)="isPerspectiveOpen()">
      <mat-icon svgIcon="filter"></mat-icon>
    </button>
  </section>
  <mat-sidenav-container>
    <mat-sidenav #sidenav mode="side" (opened)="perspective" position="end" [opened]="openedPerspective">
      <section drawerRight>
        <entity-list-perspective #perspective [allowedFilters]="allowedFilters" [allowedSorts]="allowedSorts"
          (valueChange)="filterChange($event)">
        </entity-list-perspective>
      </section>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-card>
        <table mat-table [dataSource]="invoices">
          <ng-container [matColumnDef]="column.name" *ngFor="let column of columns; trackBy: trackByColumn">
            <ng-container *ngIf="verifyColumnName(column, 0)">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="toggleAll($event)" [checked]="selectAllOptionsIsChecked()"
                  [indeterminate]="isIndeterminateState()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()"
                  (change)="toggleRow($event, row)" [checked]="isChecked(row)">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container *ngIf="verifyColumnName(column, 4)">
              <td mat-cell *matCellDef="let element">{{ element[column.name] | date: 'shortDate'}}</td>
            </ng-container>
            <ng-container *ngIf="verifyColumnName(column, 5)">
              <td mat-cell *matCellDef="let element">{{ element[column.name] | currency: 'BRL'}}</td>
            </ng-container>
            <th mat-header-cell *matHeaderCellDef>{{ column.label }}</th>
            <td mat-cell *matCellDef="let element">{{ element[column.name] }}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <section class="no-invoice" *ngIf="noInvoiceFound">
          Nenhuma fatura encontrada.
        </section>
        <section>
          <mat-paginator #pagingBar [pageSizeOptions]="pageSizeOptions" showFirstLastButtons [length]="totalOfInvoices"
            (page)="paginate($event)">
          </mat-paginator>
        </section>
        <button class="floatDefault" mat-fab (click)="exportInvoices()">
          <mat-icon>download</mat-icon>
        </button>
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-container>
</app-layout>