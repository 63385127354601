import { SgvJson } from "@financial/arch";
import { ClientSummary } from "@financial/domain";

export class OverdueOrPartiallyPaidInvoicesByClient {
  constructor(
    public readonly client: ClientSummary = null,
    public readonly invoicesAmount: number = 0,
    public readonly expiration: Date = null
  ) { }

  static fromJson(json: any): OverdueOrPartiallyPaidInvoicesByClient {
    return json
      ? SgvJson.from.simple(json, OverdueOrPartiallyPaidInvoicesByClient, {
        client: json.client ? ClientSummary.fromJson(json.client) : null,
        processedDate: json.processedDate ? new Date(json.processedDate) : null
      })
      : null
  }
}