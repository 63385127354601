import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { AuthManagerService, AuthService } from '@financial/arch';
import { MenuGroup, SubMenu } from '../../../features/features-catalog';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainMenuComponent implements OnInit {
  @Input() menu: MenuGroup[] = [];

  @Output() submenuClick = new EventEmitter<SubMenu>();

  constructor(private authService: AuthService, private authManagerService: AuthManagerService) {}

  ngOnInit() {}

  onSubmenuClick(submenu: SubMenu) {
    this.submenuClick.emit(submenu);
  }

  logout() {
    this.authService.publishLogout();
    this.authManagerService.publishLogout();
    location.reload();
  }
}
