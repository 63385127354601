import { PrintHeaderComponent } from './print-header/print-header.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DefaultSharedModule } from '@financial/default-shared';
import { ContactEmailsFormComponent } from './contact-emails-form/contact-emails-form.component';
import { ContactPhonesFormComponent } from './contact-phones-form/contact-phones-form.component';
import { CnpsFormComponent } from './cnps-form/cnps-form.component';
import { PeriodicityModule } from './periodicity/periodicity.module';

const COMPONENTS = [
  ContactEmailsFormComponent,
  ContactPhonesFormComponent,
  CnpsFormComponent,
  PrintHeaderComponent
];

const MODULES = [PeriodicityModule];

@NgModule({
  imports: [CommonModule, DefaultSharedModule, ...MODULES],
  declarations: COMPONENTS,
  exports: [...COMPONENTS, ...MODULES]
})
export class ComponentsModule {}
