<mat-dialog-content>
  <h2 mat-dialog-title>Cancelar boleto?</h2>
  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>Justificativa</mat-label>
      <textarea 
        matInput
        formControlName="justification" 
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize" 
        cdkAutosizeMinRows="3"
        cdkAutosizeMaxRows="5">
      </textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button type="button" color="accent" (click)="onYesClick()">SIM</button>
  <button mat-button type="button" (click)="onNoClick()">NÃO</button>
</mat-dialog-actions>