import { JsonEntity, SgvJson } from "@financial/arch";
import { ServiceSummary } from "../services";
import { AnalysisType } from "./analysis-type";

export class Assay implements JsonEntity {
    constructor(
        public service: ServiceSummary = null,
        public analysisType: AnalysisType = null,
    ) { }

    toJson(): any {
        return SgvJson.to.simple(this, {
            service: this.service ? this.service.toJson() : null,
            analysisType: this.analysisType ? this.analysisType.name : null,
        });
    }

    static fromJson(json: any): Assay {
        return SgvJson.from.simple(json, Assay, {
            service: json.service ? ServiceSummary.fromJson(json.service) : null,
            analysisType: json.analysisType ? AnalysisType.get(json.analysisType) : null 
        })
    }
}