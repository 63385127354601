import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProductsComponent } from './products.component';
import { ProductsRoutes } from './products.routes';
import { ProductsDetailsComponent } from './products-details/products-details.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule, ProductsRoutes],
  declarations: [ProductsComponent, ProductsDetailsComponent]
})
export class ProductsModule {}
