import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { InvoiceDetails } from '@financial/domain';
import { ProductItem } from 'libs/domain/src/lib/billables/product-item';
import { ServiceItem } from 'libs/domain/src/lib/billables/service-item';
import { Item } from 'libs/domain/src/lib/invoices/item';
import { BillableAgreement } from './../../../../../../../../libs/domain/src/lib/billables/billable';
import { InvoiceReport } from './../../../../../../../../libs/domain/src/lib/invoices/invoice-report';

@Component({
  selector: 'app-invoice-details-dialog-print-table',
  templateUrl: './invoice-details-dialog-print-table.component.html',
  styleUrls: ['./invoice-details-dialog-print-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceDetailsDialogPrintTableComponent implements OnInit {

  @Input() item: Item;

  @Input() entity: InvoiceDetails | InvoiceReport;

  private _isInvoiceDetails = false;

  private _billableAgreement: BillableAgreement;

  constructor() { }

  get isInvoiceDetails() {
    return this._isInvoiceDetails;
  }

  get billableAgreement() {
    return this._billableAgreement;
  }

  ngOnInit(): void {
    if (this.item.billable.isBillableAgreement) {
      this._billableAgreement = this.item.billable as BillableAgreement;
    }
    this._isInvoiceDetails = (this.entity instanceof InvoiceDetails);
    this.item.setBillableItems();
  }

  trackByItem(index: any, entity: ServiceItem | ProductItem) {
    return entity;
  }

}
