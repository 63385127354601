import { DataSource, CollectionViewer } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, Subscription, AsyncSubject, Subject } from "rxjs";
import { PageData, ReadonlyRepository } from "@financial/arch";
import { map, tap } from 'rxjs/operators';
import { LoadQueue } from './load-helper';
import { MatPaginatedTabHeader } from '@angular/material/tabs/paginated-tab-header';

export class RepositoryDataSource<ST> extends DataSource<ST> {

  private sizeStream = new BehaviorSubject<number>(0);
  private dataStream = new BehaviorSubject<ST[]>([]);
  private subscription: Subscription;
  private loadFail = new Subject<any>();
  private _searchTerm = "";
  private loadQueue = new LoadQueue();

  constructor(
    private repository: ReadonlyRepository<any, ST>,
    private _currentPage = new PageData(0, 10)
  ) { super() }

  get data() {
    return this.dataStream;
  }

  get page() {
    return this._currentPage;
  }

  get searchTerm() {
    return this._searchTerm;
  }

  get hasData(): boolean {
    return this.dataStream.value.length > 0;
  }

  get size(): number {
    return this.sizeStream.value;
  }

  get loading(){
    return !this.loadQueue.empty;
  }

  connect(collectionViewer: CollectionViewer): Observable<ST[]> {
    console.log("Connect")
    collectionViewer.viewChange.pipe(
      // tap(v => console.log(v)),
      // map(range => new PageData(range.start, (range.end > 1 ? range.end - 1 : 0) - range.start))
    ).subscribe(_ => this.load())
    return this.dataStream;
  }

  disconnect(): void {
    console.log("Disconnect")
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  search(searchTerm: string): void {
    this._searchTerm = searchTerm;
    this.load();
  }

  paginate(page: PageData): void {
    this._currentPage = page
    this.load();
  }

  invalidate() {
    this.load();
  }

  load(): void {
    const loading = this.loadQueue.enqueue();

    this.repository.count(this.searchTerm).subscribe(
      t => this.sizeStream.next(t),
      e => this.loadFail.next(e),
      () => this.loadQueue.markCount(loading)
    );
    if (this.page.rows > 0) {
      this.repository.page(
        this.page,
        this.searchTerm,
        [],
      ).subscribe(d =>
        this.dataStream.next(d),
        e => this.loadFail.next(e),
        () => this.loadQueue.markList(loading)
      );
    } else {

      this.dataStream.next([])
      this.loadQueue.markList(loading)
    }
  }
}
