import { DomainEntity, SgvId, SgvJson } from "@financial/arch";
import { Client } from "@financial/domain";
import { InvoiceStatus } from "./invoice-status";
import { Item } from "./item";

export class InvoiceDetails implements DomainEntity {
  constructor(
    readonly id: string = SgvId.gen(),
    public readonly client: Client = new Client(),
    public readonly recipient: string = "",
    public readonly reference: Date = null,
    public readonly items: Item[] = [],
    public readonly expiration: Date = null,
    public readonly number: number = 0,
    public readonly total: number = 0,
    public readonly name: string = "",
    public readonly status: InvoiceStatus = InvoiceStatus.PENDING,
    public readonly date: Date = null,
    public readonly nFSeNumber: number = 0,
    public readonly version = 0,
    public readonly isActive = true,
    public readonly discount: number = 0
  ) { }

  get totalWithoutDiscount() {
    return this.items.reduce((previousValue, item) => previousValue + item.value, 0);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      client: this.client ? this.client.toJson() : null,
      items: SgvJson.to.array(this.items),
      status: this.status
    })
  }

  static fromJson(json: any): InvoiceDetails {
    return SgvJson.from.simple(json, InvoiceDetails, {
      client: json.client ? Client.fromJson(json.client) : null,
      reference: json.reference ? new Date(json.reference) : null,
      items: json.items ? SgvJson.from.array(json.items, Item.fromJson) : null,
      expiration: json.expiration ? new Date(json.expiration) : null,
      status: json.status ? InvoiceStatus.get(json.status) : null
    })
  }
}