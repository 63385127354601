<section class="gerenerateBankSlipDialog">
  <section class="table_section">
    <h3 class="title">Lista de boletos que não foram gerados</h3>
    <table mat-table [dataSource]="invoices" class="table">
      <ng-container
        [matColumnDef]="column.name"
        *ngFor="let column of columns; trackBy: trackByColumn"
      >
        <th mat-header-cell *matHeaderCellDef>{{ column.label }}</th>
        <ng-container *ngIf="isPipeNotNeeded(column)">
          <td mat-cell *matCellDef="let element">{{ element[column.name] }}</td>
        </ng-container>
        <ng-container *ngIf="verifyColumnName(column, 2)">
          <td mat-cell *matCellDef="let element">{{ element[column.name] | currency: 'BRL' }}</td>
        </ng-container>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let emprow; columns: displayedColumns"></tr>
    </table>
    <section>
      <mat-paginator
        #pagingBar
        [pageSizeOptions]="pageSizeOptions"
        showFirstLastButtons
        [length]="totalOfInvoices"
        (page)="paginate($event)"
      >
      </mat-paginator>
    </section>
  </section>
  <section class="regenerate_section">
    <mat-dialog-content>
      <h3 class="title">Deseja emitir os boletos novamente?</h3>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-raised-button type="button" color="accent" (click)="onYesClick()">
        <span class="answer">SIM</span>
      </button>
      <button mat-button type="button" (click)="onNoClick()">
        <span class="answer">NÃO</span>
      </button>
    </mat-dialog-actions>
  </section>
</section>