<app-layout pageTitle="Faturas" [showSearch]="false" class="hidden-on-print">
  <section actions>
    <button mat-icon-button *ngIf="bankSlipWithError" (click)="openRegenerateBankSlipDialog()">
      <mat-icon>sync_problem</mat-icon>
    </button>
    <button mat-icon-button (click)="onPrintClick()">
      <mat-icon>print</mat-icon>
    </button>
    <button mat-icon-button (click)="opened = !opened">
      <mat-icon svgIcon="filter"></mat-icon>
    </button>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon svgIcon="ellipsis-v"></mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="openReleasedInvoices()">
        <mat-icon svgIcon="archive"></mat-icon>
        <span>Gerar Fluxo de Cobrança</span>
      </button>
      <button mat-menu-item (click)="openClientsWithOverdueInvoices()">
        <mat-icon svgIcon="archive"></mat-icon>
        <span>Gerar Acordo Financeiro</span>
      </button>
      <button mat-menu-item (click)="exportInvoices()">
        <mat-icon>output</mat-icon>
        <span>Exportar XLS</span>
      </button>
    </mat-menu>
  </section>
  <mat-sidenav-container>
    <mat-sidenav #sidenav mode="side" (opened)="perspective" position="end" [opened]="opened">
      <section drawerRight>
        <entity-list-perspective 
          #perspective 
          [allowedFilters]="allowedFilters" 
          [allowedSorts]="allowedSorts"
          [showActives]="showActives"
          (valueChange)="filterChange($event)">
        </entity-list-perspective>
      </section>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-card *ngIf="displayBalance">
        <section class="balance">
          <mat-form-field>
            <mat-label>Faturáveis Pendentes</mat-label>
            <input matInput readonly value="{{ pendingBalance | currency: 'BRL' }}">
          </mat-form-field>
          <mat-form-field class="overdue">
            <mat-label class="overdue">Em atraso</mat-label>
            <input matInput readonly value="{{ overdueBalance | currency: 'BRL' }}">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Faturas</mat-label>
            <input matInput readonly value="{{ notOverdueInvoicesBalance | currency: 'BRL' }}">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Total Faturas</mat-label>
            <input matInput readonly value="{{ totalInvoices | currency: 'BRL' }}">
          </mat-form-field>
        </section>
      </mat-card>
      <mat-card>
        <app-date-range-picker [(interval)]="interval"></app-date-range-picker>
        <app-search-box
          #searchBox
          backIcon="arrow_back"
          class="searchBox"
          placeholder="Buscar por Nº da Fatura"
          (searchDebounce)="search($event)"
          flex
        ></app-search-box>
        <table mat-table [dataSource]="invoices">
          <ng-container [matColumnDef]="column.name" *ngFor="let column of columns; trackBy: trackByColumn">
            <th mat-header-cell *matHeaderCellDef>{{ column.label }}</th>
            <ng-container *ngIf="isPipeNotNeeded(column)">
              <td mat-cell *matCellDef="let element">{{ element[column.name] }}</td>
            </ng-container>
            <ng-container *ngIf="verifyColumnName(column, 4)">
              <td mat-cell *matCellDef="let element">{{ element[column.name] | date: 'shortDate'}}</td>
            </ng-container>
            <ng-container *ngIf="verifyColumnName(column, 5)">
              <td mat-cell *matCellDef="let element">{{ element[column.name] | currency: 'BRL'}}</td>
            </ng-container>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowClick(row)" class="hoverable"></tr>
        </table>
        <section class="no-invoice" *ngIf="noInvoiceFound">
          Nenhuma fatura encontrada.
        </section>
        <section>
          <mat-paginator #pagingBar [pageSizeOptions]="pageSizeOptions" showFirstLastButtons [length]="totalOfInvoices"
            (page)="paginate($event)">
          </mat-paginator>
        </section>
        <button class="floatDefault" mat-fab (click)="insertInvoice()">
          <mat-icon>add</mat-icon>
        </button>
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-container>
</app-layout>
<section class="show-only-on-print">
  <print-header title="Faturas"></print-header>
  <table mat-table [dataSource]="invoices">
    <ng-container [matColumnDef]="column.name" *ngFor="let column of columns; trackBy: trackByColumn">
      <th mat-header-cell *matHeaderCellDef>{{ column.label }}</th>
      <ng-container *ngIf="isPipeNotNeeded(column)">
        <td mat-cell *matCellDef="let element">{{ element[column.name] }}</td>
      </ng-container>
      <ng-container *ngIf="verifyColumnName(column, 4)">
        <td mat-cell *matCellDef="let element">{{ element[column.name] | date: 'shortDate'}}</td>
      </ng-container>
      <ng-container *ngIf="verifyColumnName(column, 5)">
        <td mat-cell *matCellDef="let element">{{ element[column.name] | currency: 'BRL'}}</td>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</section>