import { JsonEntity } from '@financial/arch';
import { NumberMask } from '@financial/common-utils';

export class PhoneContact implements JsonEntity {
  constructor(public value: string) {}

  get formattedValue() {
    return PhoneContact.MASK.apply(this.value);
  }

  get isValid() {
    return this.value && this.value.length >= 8;
  }

  toJson(): any {
    return {
      type: 'TELEFONE',
      detail: this.value
    };
  }

  static MASK = new NumberMask(['9999-9999', '99999-9999', '(99) 9999-9999', '(99) 99999-9999']);

  static fromJson(json: any): PhoneContact {
    return json && json.detail ? new PhoneContact(json.detail.replace(/\D/g, '').trim()) : null;
  }
}
