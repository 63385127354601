import { Component, OnInit, forwardRef } from '@angular/core';
import { MailContact } from '@financial/domain';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { empty } from 'rxjs';

@Component({
  selector: 'app-contact-emails-form',
  templateUrl: './contact-emails-form.component.html',
  styleUrls: ['./contact-emails-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactEmailsFormComponent),
      multi: true
    }
  ]
})
export class ContactEmailsFormComponent implements OnInit, ControlValueAccessor {
  constructor() {}

  get value() {
    return this._value.filter(c => c.value && c.value !== '');
  }

  set value(value: MailContact[]) {
    if (value) {
      this._value = value;
    } else {
      this._value = [];
    }
  }

  get valueWithNew() {
    return this._value;
  }
  private _value: MailContact[] = [];

  disabled = false;

  private onChangeListener: any = null;

  private onTouchedListener: any = null;

  ngOnInit() {}

  writeValue(obj: any): void {
    this.value = obj;
    this.assertEmptyValue();
  }

  registerOnChange(fn: any): void {
    this.onChangeListener = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListener = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.assertEmptyValue();
  }

  onChange(value: string, contact: MailContact) {
    contact.value = value;
    this.assertEmptyValue();
    if (this.onChangeListener) {
      this.onChangeListener(this.value);
    }
  }

  remove(contact: MailContact) {
    this._value = this._value.filter(c => c !== contact);
    this.assertEmptyValue();
    if (this.onChangeListener) {
      this.onChangeListener(this.value);
    }
  }

  isValid(contact: MailContact) {
    return contact && contact.isValid;
  }

  private assertEmptyValue() {
    let invalidNumber = this._value.filter(c => !this.isValid(c)).length;
    if (invalidNumber > 1 || this.value) {
      this._value = this.value;
      invalidNumber = 0;
    }
    if (invalidNumber === 0 && !this.disabled) {
      this._value.push(new MailContact(''));
    }
  }
}
