import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ApiService, PageData } from '@financial/arch';
import { Invoice } from '@financial/domain';
import { Item } from 'libs/domain/src/lib/invoices/item';
import { ItemsByClientRepository } from 'libs/domain/src/lib/invoices/items-by-client/items-by-client.repository';
import { filter, lastValueFrom, map } from 'rxjs';
import { ItemsToUpdate } from './../../../../../../../../libs/domain/src/lib/invoices/items-by-client/items-to-update';
import { UpdateDiscountDialogComponent } from './update-discount-dialog/update-discount-dialog.component';

@Component({
  selector: 'app-items-client-table',
  templateUrl: './items-client-table.component.html',
  styleUrls: ['./items-client-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemsClientTableComponent implements OnInit {

  @Input() invoice: Invoice;

  @Input() title: string;

  repository: ItemsByClientRepository;

  selection = new SelectionModel<any>(true, []);

  columns: TableColumn[] = [
    { name: 'select', label: 'Selecionar' },
    { name: 'protocolNumber', label: 'Nº Protocolo' },
    { name: 'billableName', label: 'Faturável' },
    { name: 'signed', label: 'Assinado' },
    { name: 'date', label: 'Data de coleta' },
    { name: 'value', label: 'Valor' },
    { name: 'appliedDiscount', label: 'Desconto' },
    { name: 'totalValue', label: 'Valor Total' }
  ];

  @Output() save = new EventEmitter<string>();

  @Output() update = new EventEmitter<ItemsToUpdate>();

  @Output() updateAppliedDiscount = new EventEmitter<Item[]>();

  @Output() cancel = new EventEmitter<string>();

  @Output() selectedItems = new EventEmitter<Item[]>();

  private readonly _columnsWithPipe = [4, 5, 6, 7];

  private readonly _currencyColumns = [5, 7];

  private page = new PageData(0, 10);

  private currentPage = this.page;

  private _tableItems: Item[] = [];

  private _itemsToRemove: Item[] = [];

  private _invoiceItems: Item[] = [];

  private _itemsToUpdate: ItemsToUpdate = new ItemsToUpdate();

  private _displayedColumns = this.columns.map(v => v.name);

  private _idClient = ''

  private _showSaveButton = false;

  private _showNextButton = false;

  private _searchTerm = '';

  private _totalOfItems: number;

  private _discountInputChanged = false;

  private _discountsToUpdate: Item[] = [];

  constructor(
    private api: ApiService,
    private snackbar: MatSnackBar,
    private changeRef: ChangeDetectorRef,
    private activatedroute: ActivatedRoute,
    private dialog: MatDialog
  ) { }

  get displayedColumns() {
    return this._displayedColumns;
  }

  get items() {
    return this._tableItems;
  }

  get totalOfItems() {
    return this._totalOfItems;
  }

  get showSaveButton() {
    return this._showSaveButton;
  }

  get showNextButton() {
    return this._showNextButton;
  }

  showDiscountButton(column: TableColumn) {
    return this.invoice && this.verifyColumnName(column, 6);
  }

  showDiscountValue(column: TableColumn) {
    return !this.invoice && this.verifyColumnName(column, 6);
  }

  async ngOnInit() {
    this.showSaveOrNextButton();
    if (this.invoice) {
      this._idClient = this.invoice.client.id;
    } else {
      this._idClient = this.activatedroute.snapshot.paramMap.get('id');
    }
    this.repository = new ItemsByClientRepository(this._idClient, this.api);
    this.changeRef.markForCheck();
  }

  verifyColumnsPipes(columns: number[], column: TableColumn): boolean {
    return columns.every(columnIndex => !this.verifyColumnName(column, columnIndex));
  }

  isPipeNotNeeded(column: TableColumn): boolean {
    return this.verifyColumnsPipes(this._columnsWithPipe, column);
  }

  currencyColumns(column: TableColumn): boolean {
    return !this.verifyColumnsPipes(this._currencyColumns, column);
  }

  verifyColumnName(col: TableColumn, index: number): boolean {
    return (col?.name === this.columns[index]?.name);
  }

  async search(searchTerm: string) {
    this._searchTerm = searchTerm;
    if (this._searchTerm.length > 4) {
      this._totalOfItems = await lastValueFrom(this.repository.size(this._idClient, this._searchTerm));
      this._tableItems = await lastValueFrom(this.repository.page(this.page, this._searchTerm));
    }
    if (this._searchTerm.length === 0) {
      this.loadTableItems();
    }
    this.unshiftInvoiceItemsInTable();
    this.changeRef.markForCheck();
  }

  showSaveOrNextButton() {
    if (this.invoice) {
      this._showSaveButton = true;
    } else {
      this._showNextButton = true;
    }
  }

  unshiftInvoiceItemsInTable() {
    this.invoice?.items.forEach(item => {
      this._tableItems.unshift(item)
    })
  }

  async loadTableItems() {
    this._totalOfItems = await lastValueFrom(this.repository.size(this._idClient));
    this._tableItems = await lastValueFrom(this.repository.page(this.currentPage));
    if (this.invoice) {
      this.invoice.items.forEach(item => {
        this._tableItems.unshift(item);
        this.selection.select(item);
        this._invoiceItems.push(item);
      });
    }
    this.changeRef.markForCheck();
  }

  saveSelectedItems(): void {
    if (this.selection.selected.length <= 0) {
      this.snackbar.open("Ao menos um item deve ser selecionado para continuar.", null, { duration: 4000 })
    } else {
      this.selectedItems.emit(this.selection.selected);
      this.save.emit();
    }
  }

  async updateItems() {
    this.removePreSelectedItems();
    this._itemsToUpdate.itemsToAdd = this.selection.selected;
    this._itemsToUpdate.itemsToRemove = this._itemsToRemove;
    if (this._discountInputChanged) {
      this.updateAppliedDiscount.emit(this._discountsToUpdate);
    }
    this.update.emit(this._itemsToUpdate)
  }

  async loadPaginatedItems() {
    this._tableItems = await lastValueFrom(this.repository.page(this.currentPage, this._searchTerm));
    this.unshiftInvoiceItemsInTable();
    this.changeRef.markForCheck();
  }

  paginate(pagingEvent: PageEvent) {
    pagingEvent.pageIndex = pagingEvent.pageIndex * pagingEvent.pageSize;
    this.currentPage = new PageData(pagingEvent.pageIndex, pagingEvent.pageSize);
    this.loadPaginatedItems();
    this.changeRef.markForCheck();
  }

  cancelItems() {
    this.cancel.emit();
  }

  removeItem(row: Item) {
    if (this.invoice) {
      if (this.invoice.items.includes(row) && !this.isChecked(row) && !this._itemsToRemove.includes(row)) {
        this._itemsToRemove.push(row);
      }
      if (this.invoice.items.includes(row) && this.isChecked(row)) {
        this._itemsToRemove.forEach((selected, index) => {
          if (selected === row) {
            this._itemsToRemove.splice(index, 1);
          }
        });
      }
    }
  }

  isChecked(row: Item): boolean {
    return this.selection.selected.some(item => item.billable.id === row.billable.id);
  }

  selectAllOptionsIsChecked(): boolean {
    return (this.selection.hasValue() && this.isAllSelected());
  }

  isIndeterminateState(): boolean {
    return (this.selection.hasValue() && !this.isAllSelected());
  }

  isAllSelected(): boolean {
    return (this.selection.selected.length === this._tableItems.length);
  }

  selectAllOptions() {
    this.isAllSelected() ?
      this.selection.clear() :
      this._tableItems.forEach(row => this.selection.select(row));
  }

  openUpdateDiscountDialog(value: number, row: Item) {
    const dialogRef = this.dialog.open(UpdateDiscountDialogComponent, {
      width: '50%',
      maxWidth: '50%',
      height: '30%',
      data: value
    });
    dialogRef.afterClosed().pipe(
      filter(it => it != null),
      map(it => it as UpdatedDiscount)
    ).subscribe(it => {
      this.invoice.items
        .filter(item => item.id === row.id)
        .forEach(item => {
          item.appliedDiscount = it.discount;
          item.justification = it.justification;
          this._discountsToUpdate.push(item);
          this._discountInputChanged = true;
        });
      this.changeRef.markForCheck();
    });
  }

  private removePreSelectedItems(): void {
    if (this.selection?.selected?.length <= 0) {
      return;
    }
    this.invoice.items
      .map(it =>
        this.selection.selected.find(selected => it === selected)
      )
      .filter(it => it != null)
      .forEach(it =>
        this.selection.selected.splice(it, 1)
      );
    this.selectedItems.emit(this.selection.selected);
  }

}

interface UpdatedDiscount {
  discount: number,
  justification: string
}

interface TableColumn {
  name: string,
  label: string
}
