import { Pipe, PipeTransform } from '@angular/core';
import { DateInterval } from '@financial/arch';
import * as moment from 'moment';
const WIHTOUT_TIME_FORMAT = {
  sameDay: '[Hoje]',
  nextDay: '[Amanhã]',
  lastDay: '[Ontem]',
  nextWeek: 'DD/MM/YYYY',
  lastWeek: 'DD/MM/YYYY',
  sameElse: 'DD/MM/YYYY'
};
const WIHT_TIME_FORMAT = {
  nextWeek: 'DD/MM/YYYY HH:mm',
  lastWeek: 'DD/MM/YYYY HH:mm',
  sameElse: 'DD/MM/YYYY HH:mm'
};

type Format = 'date' | 'datetime';

@Pipe({
  name: 'dateInterval'
})
export class DateIntervalPipe implements PipeTransform {
  private readonly cache = new WeakMap<DateInterval, string>();

  transform(value: DateInterval, format: Format, customRanges?: Map<DateInterval, string>): string {
    if (!this.cache.has(value)) {
      this.cache.set(value, this.parse(value, format, customRanges));
    }
    return this.cache.get(value);
  }

  private parse(value: DateInterval, format: Format, customRanges?: Map<DateInterval, string>) {
    if (format == null) {
      format = 'date';
    }
    if (value.isWholeDay) {
      return this.calendarOf(value.begin, format);
    }
    if (value.isWholeMonth) {
      return this.monthOf(value.begin);
    }
    if (value.isWholeYear) {
      return this.yearOf(value.begin);
    }
    if (value.isFullMonths) {
      return `${this.monthOf(value.begin)} até ${this.monthOf(value.end)}`;
    }
    if (value.isFullYears) {
      return `${this.yearOf(value.begin)} até ${this.yearOf(value.end)}`;
    }
    return `${this.calendarOf(value.begin, format)} até ${this.calendarOf(value.end, format)}`;
  }

  private calendarOf(date: Date, format: Format) {
    const v = moment(date);
    switch (format) {
      case 'datetime':
        return v.calendar(null, WIHT_TIME_FORMAT);
      default:
        return v.calendar(null, WIHTOUT_TIME_FORMAT);
    }
  }

  private monthOf(date: Date) {
    const v = moment(date);
    const str = v.format('MMMM');
    return v.year() === moment().year() ? str : `${str} de ${this.yearOf(date)}`;
  }

  private yearOf(date: Date) {
    return moment(date).format('YYYY');
  }
}
