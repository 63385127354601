import { JsonEntity, SgvJson } from '@financial/arch';

export class Address implements JsonEntity {
  constructor(
    public zipCode: string = '',
    public city: string = '',
    public uf: string = '',
    public street: string = '',
    public number: string = ''
  ) { }

  get fullAddress() {
    let result = '';
    if (this.zipCode) {
      if (this.street) {
        result += this.street;
      }
      if (this.number !== '') {
        result += `, ${this.number}`;
      }
      if (result !== '') {
        result += ' - ';
      }
      if (this.city) {
        result += this.city;
      }
      if (this.uf && this.city) {
        result += '/';
      }
      if (this.uf) {
        result += this.uf;
      }
    }
    return result;
  }

  toJson() {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): Address {
    return SgvJson.from.simple(json, Address);
  }
}