import { ModuleWithProviders } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ChangeAlertGuard } from '@financial/arch';
import { FinancialGuard } from "../../core/guards/financial/financial.guard";
import { BillablesComponent } from "./billables.component";
import { BillablesDetailsComponent } from "./billables-details/billables-details.component";

const BASE_PATH = 'billables';
const BASE_ID_PATH = ':id'

export const BillableRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild([
  { path: BASE_PATH, redirectTo: `${BASE_PATH}/`, pathMatch: 'full' },
  {
    path: `${BASE_PATH}/${BASE_ID_PATH}`,
    component: BillablesComponent,
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    canActivate: [FinancialGuard],
    canDeactivate: [ChangeAlertGuard]
  }
]);
