import { Action, Functionality } from '@financial/arch';
import { DELETE, DELETE_TEMPORARY, INSERT, UPDATE } from './crud-actions';
export class Feature implements Functionality {
  private readonly actionMap = new Map(
    this.actions.map((a) => [a.id, a]) as unknown as ReadonlyArray<[string, Action]>
  );

  constructor(public readonly id: string, public readonly actions: Action[] = []) {}

  getActionById(id: string) {
    return this.actionMap.get(id);
  }

  toJson() {
    return this.id;
  }

  public static fromJson(id: string) {
    return ALL_MAP.get(id);
  }

  public static all() {
    return ALL;
  }
}

export const SERVICES = new Feature('SERVICES', [INSERT, UPDATE, DELETE_TEMPORARY]);
export const SETTINGS = new Feature('SETTINGS', [INSERT, UPDATE, DELETE_TEMPORARY]);
export const CLIENTS = new Feature('CLIENTS', [INSERT, UPDATE, DELETE_TEMPORARY]);
export const DISCOUNTS = new Feature('DISCOUNTS', [INSERT, UPDATE, DELETE_TEMPORARY]);
export const PRODUCTS = new Feature('PRODUCTS', [INSERT, UPDATE, DELETE_TEMPORARY]);
export const INVOICES = new Feature('INVOICES', [INSERT, UPDATE, DELETE_TEMPORARY]);
export const BILLABLES = new Feature('BILLABLES', [INSERT, UPDATE, DELETE_TEMPORARY]);

export const ALL = [CLIENTS, DISCOUNTS, PRODUCTS, SERVICES, SETTINGS, INVOICES, BILLABLES];
const ALL_MAP = new Map(
  ALL.map((f) => [f.id, f]) as unknown as ReadonlyArray<[string, Functionality]>
);
