import { SgvJson } from '@financial/arch';
import { DomainEntity } from "../../../../arch/src/lib/domain";

export class InvoicesIdsAndNFSENumber implements DomainEntity{
  
  readonly id: string;
  readonly isActive = true;
  
  constructor(
    public invoicesIds: string[] = [],
    public nfseNumber: string = ''
  ) { }
  
  toJson(): any {
    return SgvJson.to.simple(this, {
      invoicesIds: this.invoicesIds
    })
  }

}