import { Injectable } from '@angular/core';
import { RepositoryImpl, ApiService } from '@financial/arch';
import { Analysis, AnalysisSummary } from './analysis';

@Injectable({providedIn:'root'})
export class AnalysesRepository extends RepositoryImpl<AnalysisSummary, Analysis> {
  constructor(api: ApiService) {
    super(api.root.path('analyses'), AnalysisSummary, Analysis);
  }
}
