<app-layout pageTitle="Clientes" [showSearch]="true" (searchChange)="list.search($event)">
  <section actions>
    <button
      mat-icon-button
      (click)="crud.drawerRight.toggle()"
      [matBadge]="perspective.value.length"
      [matBadgeHidden]="perspective.value.length == 0"
      matBadgeColor="warn"
      matBadgeSize="small"
    >
      <mat-icon svgIcon="filter"></mat-icon>
    </button>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon svgIcon="ellipsis-v"></mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="exportClients()">
        <mat-icon>file_download</mat-icon>
        <span>Clifor/Movimentação Financeira</span>
      </button>
    </mat-menu>
  </section>
  <entity-crud
    #crud
    [functionality]="functionality"
    [repository]="repository"
    [entityTypes]="entityTypes"
    (entityChange)="list.invalidate()"
    [showDefaultFab]="false"
  >
    <mat-card>
      <entity-table
      #list 
      title="Lista de Clientes" 
      [repository]="repository"
      [columns]="columns"
      (entitySelect)="crud.openEntity($event.id)"> 
      </entity-table>
    </mat-card>

    <entity-fab-dial
      class="floatDefault"
      (optionSelect)="crud.newEntity($event.value)"
      [options]="fabOptions"
    >
    </entity-fab-dial>

    <section drawerRight>
      <entity-list-perspective
        #perspective
        [allowedFilters]="allowedFilters"
        [allowedSorts]="allowedSorts"
      >
      </entity-list-perspective>
    </section>

    <app-clients-details
      *entityCrudDetailsContent="let crud"
      [state]="crud.state"
      [(entity)]="crud.entity"
      (submit)="crud.listeners.onSubmit($event)"
      (validChange)="crud.listeners.validityChange($event)"
      (dirtyChange)="crud.listeners.dirtyChange($event)"
      (clientChange)="navigateToClient($event)"
    >
    </app-clients-details>
  </entity-crud>
</app-layout>
