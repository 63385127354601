import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DateInterval } from '@financial/arch';
import { ChartType, InvoicesChartRepository } from '@financial/domain';

@Component({
  selector: 'ufg-invoices-chart',
  templateUrl: './invoices-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoicesChartComponent {
  private readonly _chartType: string = ChartType.DOUGHNUT;
  private readonly _chartBackgroundcolorDataset: string[] = ['#1DB824', '#2D3EB7', '#B82621'];
  private readonly _title: string = "Resumo das Faturas por Período";
  private readonly _enableLegends: boolean = true;
  private readonly _chartId: string = "invoicesChart";
  private readonly _interval: DateInterval = DateInterval.ofWeek(new Date());

  constructor(
    private invoicesChartRepository: InvoicesChartRepository,
  ) { }

  get chartType(): string {
    return this._chartType;
  }

  get chartBackgroundcolorDataset(): string[] {
    return this._chartBackgroundcolorDataset;
  }

  get title(): string {
    return this._title;
  }

  get repository(): InvoicesChartRepository {
    return this.invoicesChartRepository;
  }

  get enableLegend(): boolean {
    return this._enableLegends;
  }

  get chartId(): string {
    return this._chartId;
  }

  get interval(): DateInterval {
    return this._interval;
  }
}