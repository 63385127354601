import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { AuthGuard, AuthManagerService, AuthService } from '@financial/arch';


@Injectable({
  providedIn: 'root'
})
export class FinancialGuard implements CanActivate, CanActivateChild {

  constructor(
    private authGuard: AuthGuard,
    private router: Router,
    private authService: AuthService,
    private authManagerService: AuthManagerService
  ) {
  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    if (this.authGuard.canActivate(next, state)) {
      if (this.authService.hasToken) {
        return true;
      }
    }
    return false;
  }

  async canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    return this.canActivate(next, state);
  }
}
