import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@financial/arch';
import { Client, ClientsRepository, ClientSummary, InvoicesRepository } from '@financial/domain';
import { Item } from 'libs/domain/src/lib/invoices/item';
import { lastValueFrom } from 'rxjs';
import { Invoice } from './../../../../../../../libs/domain/src/lib/invoices/invoice';
import { ItemsByClientRepository } from './../../../../../../../libs/domain/src/lib/invoices/items-by-client/items-by-client.repository';
import { Location } from '@angular/common';

@Component({
  selector: 'app-items-client',
  templateUrl: './items-client.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemsClientComponent implements OnInit {

  repository: ItemsByClientRepository

  title: string;

  columns = [
    { name: 'billableName', label: 'Faturável' },
    { name: 'protocolNumber', label: 'Nº Protocolo' },
    { name: 'signed', label: 'Assinado' },
    { name: 'appliedDiscount', label: 'Desconto' },
    { name: 'totalValue', label: 'Valor Total' }
  ];

  @Output() submit = new EventEmitter<Invoice>();

  private _items: Item[] = [];

  private _client: Client;

  private _idClient = ''

  private _invoice = new Invoice();

  constructor(
    api: ApiService,
    private activatedroute: ActivatedRoute,
    private clientRepository: ClientsRepository,
    private changeRef: ChangeDetectorRef,
    private invoiceRepository: InvoicesRepository,
    private router: Router,
    private location: Location
  ) {
    this._idClient = this.activatedroute.snapshot.paramMap.get('id');
    this.repository = new ItemsByClientRepository(this._idClient, api);
  }

  async ngOnInit() {
    this._client = await lastValueFrom(this.clientRepository.find(this._idClient));
    this.title = `Cliente: ${this._client.formattedName}`;
    this.changeRef.markForCheck();
  }

  async save() {
    this.invoiceRepository.insert(this.createInvoice()).subscribe(async (invoice) => {
      this.location.replaceState('/invoices')
      this.router.navigate([`/invoices/${invoice.id}/actions`])
    });
  }

  setItems(items: Item[]) {
    this._items = items;
  }

  private createInvoice(): Invoice {
    this._invoice.client =
      new ClientSummary(
        this._client.id,
        this._client.name,
        this._client.code,
        this._client.documents.cnp
      );
    this._invoice.recipient = this._client.name;
    this._invoice.items = this._items;
    this._invoice.expiration = null;
    return this._invoice;
  }
}