<section *ngIf="showSaveButton">
  <button mat-button (click)="updateItems()">Salvar</button>
  <button mat-button (click)="cancelItems()">Cancelar</button>
</section>
<mat-card>
  <section class="header">
    <span *ngIf="!searchBox.searchVisible">
      <span class="mat-title">{{ title }}</span>
    </span>
    <app-search-box
      #searchBox
      backIcon="arrow_back"
      class="searchBox"
      placeholder="Buscar"
      (searchDebounce)="search($event)"
      flex
    ></app-search-box>
  </section>
  <table mat-table [dataSource]="items">
    <ng-container *ngFor="let col of columns" [matColumnDef]="col.name">
      <ng-container *ngIf="verifyColumnName(col, 0)">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? selectAllOptions() : null" [checked]="selectAllOptionsIsChecked()"
            [indeterminate]="isIndeterminateState()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null; removeItem(row)" [checked]="isChecked(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container *ngIf="isPipeNotNeeded(col)">
        <td mat-cell *matCellDef="let element">{{ element[col.name] }}</td>
      </ng-container>
      <ng-container *ngIf="verifyColumnName(col, 4)">
        <td mat-cell *matCellDef="let element">{{ element[col.name] | date: 'shortDate' }}</td>
      </ng-container>
      <ng-container *ngIf="showDiscountValue(col)">
        <td mat-cell *matCellDef="let element">{{ element[col.name] | currency: 'BRL' }}</td>
      </ng-container>
      <ng-container *ngIf="currencyColumns(col)">
        <td mat-cell *matCellDef="let element">{{ element[col.name] | currency: 'BRL' }}</td>
      </ng-container>
      <section *ngIf="showDiscountButton(col)">
        <td class="discount-column" mat-cell *matCellDef="let row">
          <button mat-button (click)="openUpdateDiscountDialog(row.appliedDiscount, row)"> {{ row.appliedDiscount | currency: 'BRL' }}</button>
        </td>
      </section>
      <th mat-header-cell *matHeaderCellDef>{{ col.label }}</th>
      <td mat-cell *matCellDef="let element">{{ element[col.name] }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="table-row"
      *matRowDef="let row; columns: displayedColumns">
    </tr>
  </table>
  <section>
    <mat-paginator #pagingBar [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons [length]="totalOfItems"
      (page)="paginate($event)">
    </mat-paginator>
  </section>
  <button *ngIf="showNextButton" class="floatDefault" mat-fab (click)="saveSelectedItems()"> 
    <mat-icon>arrow_forward</mat-icon>  
  </button>
</mat-card>