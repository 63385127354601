import { Injectable } from "@angular/core";
import { ApiService, RepositoryImpl } from "@financial/arch";
import { EceosOperatable } from "./eceos-operatable";


@Injectable({ providedIn: 'root' })
export class EceosOperatablesRepository extends RepositoryImpl<EceosOperatable, EceosOperatable> {
    constructor(api: ApiService) {
        super(api.root.path('eceosOperatables'), EceosOperatable, EceosOperatable);
    }
}