import { Component, OnInit, ViewChild } from '@angular/core';
import { Functionality } from '@financial/arch';
import { EntityCrudComponent, EntityCrudType, EntityFabDialOption, FilterDescription, FilterType, SortDescription } from '@financial/common-components';
import { Discount, DiscountRepository, DISCOUNTS, PerAmountDiscount, PerClientDiscount } from '@financial/domain';

@Component({
  selector: 'app-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.scss']
})
export class DiscountsComponent implements OnInit {

  @ViewChild(EntityCrudComponent, { static: true }) crud: EntityCrudComponent;

  functionality: Functionality = DISCOUNTS;

  entityTypes: EntityCrudType[] = [
    {
      type: Discount.PER_CLIENT_TYPE,
      title: 'Desconto',
      creator: () => new PerClientDiscount,
      match: (entity: Discount) => entity.isPerClientDiscount,
      showHelpButton: false
    },
    {
      type: Discount.PER_AMOUNT_TYPE,
      title: 'Desconto',
      creator: () => new PerAmountDiscount,
      match: (entity: Discount) => entity.isPerAmountDiscount,
      showHelpButton: false
    }
  ];

  fabOptions: EntityFabDialOption[] = [
    {
      value: Discount.PER_CLIENT_TYPE,
      label: 'Desconto Por Cliente',
      icon: 'local_offer',
      backgroundColor: 'darkgoldenrod'
    },
    {
      value: Discount.PER_AMOUNT_TYPE,
      label: 'Desconto Por Quantidade',
      icon: 'discount',
      backgroundColor: 'darkgreen'
    }
  ];

  allowedFilters = [
    new FilterDescription('name', 'Nome', FilterType.TEXTUAL)
  ];

  allowedSorts = [
    new SortDescription('name', 'Nome')
  ];

  fabOpen = false;

  constructor(public repository: DiscountRepository) { }

  ngOnInit(): void { }

  hasUnsavedState() {
    return this.crud.hasUnsavedState();
  }

  classByTypeOrExpiration(entity: Discount): string {
    if(entity.isExpired){
      return 'isExpired'
    } else if(entity.isPerAmountDiscount) {
      return 'perAmount'
    } else {
      return 'perClient'
    }
  }

}
