import { ModuleWithProviders } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ChangeAlertGuard } from "@financial/arch";
import { FinancialGuard } from "../../core/guards/financial/financial.guard";
import { DiscountsComponent } from "./discounts.component";

const BASE_PATH = 'discounts';

export const DiscountRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild([
  { path: BASE_PATH, redirectTo: `${BASE_PATH}/`, pathMatch: 'full' },
  {
    path: `${BASE_PATH}/:id`,
    component: DiscountsComponent,
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    canActivate: [FinancialGuard],
    canDeactivate: [ChangeAlertGuard]
  }
]);