import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@financial/arch';
import { Product } from './product';

@Injectable({providedIn:'root'})
export class ProductsRepository extends RepositoryImpl<Product, Product> {
  constructor(api: ApiService) {
    super(api.root.path('products'), Product, Product);
  }
}
