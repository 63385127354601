<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-tab-group>
        <mat-tab label="Dados Cadastrais" class="general">
            <section class="content">
                <mat-form-field class="name">
                    <mat-label>Nome</mat-label><input matInput formControlName="name"/>
                </mat-form-field>
                <mat-form-field class="value">
                    <mat-label *ngIf="entity.isPerClientDiscount">Desconto (R$)</mat-label>
                    <mat-label *ngIf="entity.isPerAmountDiscount">Desconto (%)</mat-label>
                    <input matInput formControlName="value" />
                </mat-form-field>
                <ng-container *ngIf="entity.isPerClientDiscount">
                    <app-data-selector class="client" label="Cliente" [repository]="clientsRepository"
                        formControlName="client">
                    </app-data-selector>
                </ng-container>
                <ng-container *ngIf="entity.isPerAmountDiscount">
                    <mat-form-field class="amount">
                        <mat-label>Quantidade</mat-label>
                        <input matInput #numberInput formControlName="amount" />
                    </mat-form-field>
                </ng-container>
                <mat-form-field>
                    <mat-label>Data de Expiração</mat-label>
                    <input matInput [matDatepicker]="datePicker" formControlName="expiration" [matDatepickerFilter]="onlyFutureDaysDateFilter"/>
                    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                    <mat-datepicker #datePicker></mat-datepicker>
                </mat-form-field>
            </section>
        </mat-tab>
    </mat-tab-group>
    <button type="submit" class="hidden"></button>
</form>