import { Functionality } from '@financial/arch';
import { BILLABLES, CLIENTS, DISCOUNTS, INVOICES, PRODUCTS, SERVICES } from '@financial/domain';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faBook, faBoxes, faCog, faReceipt, faThLarge,
  faWrench
} from '@fortawesome/free-solid-svg-icons';
export class MenuItem {
  readonly icon = this.iconRef.iconName;

  constructor(
    readonly feature: Functionality = null,
    readonly name: string,
    readonly iconRef: IconDefinition,
    readonly path: string,
    readonly details: string = null,
    readonly title: string = name
  ) { }

  get icons(): IconDefinition[] {
    return [this.iconRef];
  }
}
export class SubMenu {
  readonly icon = this.iconRef.iconName;

  constructor(
    readonly name: string,
    readonly iconRef: IconDefinition,
    readonly details: string = null,
    readonly title: string = name,
    readonly childs: (MenuGroup | MenuItem)[]
  ) { }

  get icons(): IconDefinition[] {
    return [
      this.iconRef,
      ...this.childs.map((c) => c.icons).reduce((v, acc) => [...v, ...acc], [])
    ];
  }

  getItem(matcher: (item: MenuItem) => boolean): MenuItem {
    for (const child of this.childs) {
      if (child instanceof MenuItem) {
        if (matcher(child)) return child;
      } else {
        const result = child.getItem(matcher);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }
}
export class MenuGroup {
  constructor(readonly name: string, readonly childs: (MenuItem | SubMenu)[]) { }

  get icons(): IconDefinition[] {
    return this.childs
      .map((c) => c.icons)
      .reduce((v, acc) => [...(Array.isArray(v) ? v : [v]), ...acc], []);
  }

  getItem(matcher: (item: MenuItem) => boolean): MenuItem {
    for (const child of this.childs) {
      if (child instanceof SubMenu) {
        const result = child.getItem(matcher);
        if (result) {
          return result;
        }
      } else if (child instanceof MenuItem) {
        if (matcher(child)) {
          return child;
        }
      }
    }
    return null;
  }

  getSubMenu(matcher: (item: SubMenu) => boolean): SubMenu {
    for (const child of this.childs) {
      if (child instanceof SubMenu) {
        if (matcher(child)) {
          return child;
        }
      }
    }
    return null;
  }
}
export class Menu {
  constructor(readonly childs: MenuGroup[]) { }

  get icons(): IconDefinition[] {
    return this.childs.map((c) => c.icons).reduce((v, acc) => [...v, ...acc], []);
  }

  getByFunctionality(feature: Functionality): MenuItem {
    return this.getItem((i) => i.feature === feature);
  }

  getByPath(path: string): MenuItem {
    return this.getItem((i) => i.path === path);
  }

  getItem(matcher: (item: MenuItem) => boolean): MenuItem {
    for (const group of this.childs) {
      const result = group.getItem(matcher);
      if (result) {
        return result;
      }
    }
    return null;
  }

  getSubMenu(matcher: (item: SubMenu) => boolean): SubMenu {
    for (const group of this.childs) {
      const result = group.getSubMenu(matcher);
      if (result) {
        return result;
      }
    }
    return null;
  }
}

export const MAIN_MENU = new Menu([
  new MenuGroup('Geral', [
    new MenuItem(
      CLIENTS,
      'Clientes',
      faBook,
      '/clients',
      'Gerencie os cadastros dos seus clientes'
    ),
    new MenuItem(
      DISCOUNTS,
      'Descontos',
      faThLarge,
      '/discounts',
      'Gerencie os descontos que podem ser aplicados'
    ),
    new MenuItem(
      PRODUCTS,
      'Produtos',
      faBoxes,
      '/products',
      'Gerencie os seus produtos'
    ),
    new MenuItem(
      SERVICES,
      'Serviços',
      faWrench,
      '/services',
      'Gerencie os cadastros dos seus Servico'
    ),
    new MenuItem(
      SERVICES,
      'Configurações',
      faCog,
      '/settings',
      'Gerencie os cadastros das configurações'
    ),
    new MenuItem(
      INVOICES,
      "Faturas",
      faReceipt,
      '/invoices',
      'Gerencie suas faturas'
    ),
    new MenuItem(
      BILLABLES,
      "Faturáveis",
      faReceipt,
      '/billables',
      'Visualize seus faturaveis'
    )
  ])
]);
