import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { InvoiceDetails } from '@financial/domain';
import { InvoiceReport } from './../../../../../../../../libs/domain/src/lib/invoices/invoice-report';

@Component({
  selector: 'app-invoice-total-print-table',
  templateUrl: './invoice-total-print-table.component.html',
  styleUrls: ['./invoice-total-print-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceTotalPrintTableComponent implements OnInit {

  @Input() entity: InvoiceDetails | InvoiceReport;

  private _isInvoiceDetails = false;

  get isInvoiceDetails() {
    return this._isInvoiceDetails;
  }

  ngOnInit(): void {
    this.checkInvoiceType();
  }

  checkInvoiceType(): void {
    if (this.entity instanceof InvoiceDetails) {
      this._isInvoiceDetails = true;
    }
  }
}
