import { Item } from './item';
import { Actives, ApiService, Filter, InterceptorConfig, PageData, RepositoryImpl, RequestMetadata, RequestMetadataUtils, SortOrder, UrlSearchParamsBuilder } from '@financial/arch';
import { retryExceptOnClientError } from '@financial/common-utils';
import { map, Observable, retryWhen } from 'rxjs';
import { Invoice } from './invoice';

export class ReleasedInvoicesRepository extends RepositoryImpl<Invoice, Invoice> {
  constructor( 
    api: ApiService
  ) {
    super(api.root.path('invoices').path('released'), Invoice, Invoice);
  }

  size(query: string = '', metadata: RequestMetadata =
    { autoCatch: InterceptorConfig.NO_INTERCEPT }): Observable<number> {
    const search = new UrlSearchParamsBuilder().query(query).build()
    return this.api
      .path("size")
      .getJson({ params: search, metadata: metadata }).pipe(map((r: number) => r));
  }

  override page(
    pageData: PageData,
    query: string = '',
    filters: Filter[] = [],
    sortOrders: SortOrder[] = [],
    actives = Actives.TRUE,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<Invoice[]> {
    const search = new UrlSearchParamsBuilder()
      .page(pageData)
      .query(query)
      .filters(filters)
      .sortOrders(sortOrders)
      .actives(actives)
      .build();
    return this.api
      .getJson<Invoice[]>({
        params: search,
        metadata: RequestMetadataUtils.merge(metadata, this.metadata)
      })
      .pipe(
        map((r) => r.map((j: Invoice) => Invoice.fromJson(j))),
        retryWhen(retryExceptOnClientError())
      );
  }

  override count(
    query: string = '',
    filters: Filter[] = [],
    actives = Actives.TRUE,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<number> {
    const search = new UrlSearchParamsBuilder()
      .query(query)
      .filters(filters)
      .actives(actives)
      .build();
    return this.api
      .path('')
      .getText({ params: search, metadata: RequestMetadataUtils.merge(metadata, this.metadata) })
      .pipe(
        map((r) => r || '0'),
        map((text) => Number.parseInt(text, 10)),
        retryWhen(retryExceptOnClientError())
      );
  }
}