import { PermissionService } from './permission.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FunctionalityValidator } from '@financial/arch';

@NgModule({
  declarations: [],
  providers: [PermissionService, { provide: FunctionalityValidator, useClass: PermissionService }],
  imports: [CommonModule]
})
export class PermissionModule {}
