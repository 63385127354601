import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-update-discount-dialog',
  templateUrl: './update-discount-dialog.component.html',
  styleUrls: ['./update-discount-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateDiscountDialogComponent {

  form: FormGroup;

  private _justification = '';

  constructor(
    private dialogRef: MatDialogRef<UpdateDiscountDialogComponent>,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private _discountValue: number
  ) {
    this.form = fb.group({
      discount: [this._discountValue, [Validators.required]],
      justification: [this._justification, [Validators.required]]
    })
  }

  get discountValue() {
    return this._discountValue;
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onYesClick() {
    if (this.form.untouched || this.form.invalid) {
      this.snackbar.open("Um desconto e uma justifica devem ser inseridos para atualizar o desconto", null, { duration: 4000 })
    } else {
      this.dialogRef.close(this.form.value);
    }
  }

}
