import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@financial/arch';
import { Discount } from './discount';

@Injectable({providedIn:'root'})
export class DiscountRepository extends RepositoryImpl<Discount, Discount> {
  constructor(api: ApiService) {
    super(api.root.path('discounts'), Discount, Discount);
  }
}
