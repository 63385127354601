export enum InvoiceActions {
    RELEASE_INVOICE = "releaseInvoice",
    CANCEL_INVOICE = "cancelInvoice",
    GENERATE_BANK_SLIP = "generateBankSlip",
    CANCEL_BANK_SLIP = "cancelBankSlip",
    UPDATE_EXPIRATION = "updateExpiration",
    UPDATE_ITEMS = "updateItems",
    UPDATE_DISCOUNTS = "updateDiscounts",
    UPDATE_CATEGORY = "updateEceosCategory"
}   
