import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Filter, FilterOp, PageData } from '@financial/arch';
import { SimpleEntityListPerspective } from '@financial/common-components';
import { saveAs } from 'file-saver';
import { InvoiceDataClifor } from 'libs/domain/src/lib/export/clifor/InvoiceDataClifor';
import { Moment } from 'moment';
import { lastValueFrom } from 'rxjs';
import { EntityFabDialOption } from './../../../../../../../libs/common-components/src/lib/entity-fab-dial/entity-fab-dial-option';
import { IntervalInvoiceType } from './../../../../../../../libs/domain/src/lib/export/clifor/IntervalInvoiceType';
import { ExportRepository } from './../../../../../../../libs/domain/src/lib/export/export.repository';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-clifor',
  templateUrl: './clifor.component.html',
  styleUrls: ['./clifor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CliforComponent {

  form: FormGroup;

  readonly FINANCIAL_MOVEMENT = 'FINANCIAL_MOVEMENT';
  readonly CLIFOR = 'CLIFOR';

  readonly fabOptions: EntityFabDialOption[] = [
    {
      value: this.CLIFOR,
      label: this.CLIFOR,
      icon: 'person',
    },
    {
      value: this.FINANCIAL_MOVEMENT,
      label: 'Movimentação Financeira',
      icon: 'receipt',
    }
  ];

  readonly columns: TableColumn[] = [
    { name: 'cliforCode', label: this.CLIFOR },
    { name: 'formattedCnp', label: 'CPF/CNPJ' },
    { name: 'nfseValue', label: 'Valor NF' },
    { name: 'nfseEmission', label: 'Data Emissão' },
    { name: 'bankSlipNumber', label: 'Boleto' },
    { name: 'bankSlipValue', label: 'Valor' },
    { name: 'funapeSentStatus', label: 'Enviado FUNAPE' },
    { name: 'details', label: 'Detalhes' },
  ];

  readonly dateOptions = IntervalInvoiceType.values();

  private readonly _columnsWithPipe = [2, 3, 5, 7];

  private readonly _currencyColumns = [2, 5];

  private readonly PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

  private readonly LABEL_POSITION = 'left';

  private readonly DATE_FORMATTER = 'yyyy-MM-DD';

  private readonly CNP = 'cnp';
  private readonly NFSE_NUMBER = 'nfseNumber';
  private readonly OUR_NUMBER = 'ourNumber';
  private readonly NFSE_STATUS = 'nfseStatus';

  private page = new PageData(0, 10);

  private _currentPage = this.page;

  private _displayedColumns = this.columns.map(column => column.name);

  private _perspective: SimpleEntityListPerspective = new SimpleEntityListPerspective();

  private _total = 0;

  private _clifor: InvoiceDataClifor[];

  private _filters: Filter[] = [];

  private _cnp = '';
  private _nfseNumber = '';
  private _ourNumber = '';
  private _nfseCanceledCheckbox = false;
  private _dateIntervalType = '';
  private _dateRange: DateRangeInterval;

  private _nfseCanceledStatus = 'CANCELED';

  private _fabOpen = false;

  private _noCliforsFound = true;

  constructor(
    private _formBuilder: FormBuilder,
    private changeRef: ChangeDetectorRef,
    private repository: ExportRepository,
    private snackbar: MatSnackBar
  ) {
    this.form = _formBuilder.group({
      cnp: [this._cnp],
      nfseNumber: [this._nfseNumber],
      ourNumber: [this._ourNumber],
      dateIntervalType: [this._dateIntervalType],
      dateRange: this._formBuilder.group({
        start: new FormControl(),
        end: new FormControl(),
      }),
      nfseStatus: this._nfseCanceledCheckbox
    });
  }

  get noCliforsFound() {
    return this._noCliforsFound;
  }

  get labelPosition(): string {
    return this.LABEL_POSITION;
  }

  get pageSizeOptions(): number[] {
    return this.PAGE_SIZE_OPTIONS;
  }

  get fabOpen(): boolean {
    return this._fabOpen;
  }

  get displayedColumns(): string[] {
    return this._displayedColumns;
  }

  get cliforData(): InvoiceDataClifor[] {
    return this._clifor;
  }

  get totalOfClifors(): number {
    return this._total;
  }

  set fabOpen(value: boolean) {
    this._fabOpen = value;
  }

  private get _cnpFormControl(): AbstractControl {
    return this.form.controls[this.CNP];
  }

  private get _nfseNumberFormControl(): AbstractControl {
    return this.form.controls[this.NFSE_NUMBER];
  }

  private get _ourNumberFormControl(): AbstractControl {
    return this.form.controls[this.OUR_NUMBER];
  }

  private get _nfseStatusFormControl(): AbstractControl {
    return this.form.controls[this.NFSE_STATUS];
  }

  private get _nfseStatusFormControlValue(): boolean {
    return this.form.controls[this.NFSE_STATUS].value;
  }

  private get _dateIntervalTypeFormControl(): AbstractControl {
    return this.form.controls['dateIntervalType'];
  }

  private get _dateIntervalTypeFormControlValue(): string {
    return this._dateIntervalTypeFormControl.value;
  }

  private get _dateRangeFormControl(): AbstractControl {
    return this.form.controls['dateRange'];
  }

  private get _dateRangeFormControlValue(): DateRangeFilter {
    return this._dateRangeFormControl.value;
  }

  async loadClifors() {
    if (this.form.untouched) {
      this.repository.listCliforAndFinancialMovement(this._currentPage).subscribe(async invoiceDataClifors => {
        this._total = invoiceDataClifors.length;
        this._clifor = invoiceDataClifors;
        this._noCliforsFound = this._total <= 0;
        this.changeRef.markForCheck();
      });
    } else {
      if (this._dateRangeFormControlValue.start !== null
        && this._dateRangeFormControlValue.end !== null
        && this._dateIntervalTypeFormControlValue !== ''
      ) {
        this._dateRange = {
          intervalStart: this._dateRangeFormControlValue.start.format(this.DATE_FORMATTER),
          intervalEnd: this._dateRangeFormControlValue.end.format(this.DATE_FORMATTER),
          intervalType: this._dateIntervalTypeFormControlValue
        };
      }

      if (this._cnpFormControl.value !== '') {
        this._filters.push(new Filter(this.CNP, FilterOp.EQ, this._cnpFormControl.value));
      }

      if (this._nfseNumberFormControl.value !== '') {
        this._filters.push(new Filter(this.NFSE_NUMBER, FilterOp.EQ, this._nfseNumberFormControl.value));
      }

      if (this._ourNumberFormControl.value !== '') {
        this._filters.push(new Filter(this.OUR_NUMBER, FilterOp.EQ, this._ourNumberFormControl.value));
      }

      if (this._nfseStatusFormControlValue) {
        this._filters.push(new Filter(this.NFSE_STATUS, FilterOp.EQ, this._nfseCanceledStatus))
      }

      this._perspective.filters = this._filters;
      this.repository.listCliforAndFinancialMovement(
        this._currentPage,
        this._perspective.filters,
        this._dateRange
      ).subscribe(async invoiceDataClifors => {
        this._total = invoiceDataClifors.length;
        this._clifor = invoiceDataClifors;
        this._noCliforsFound = this._total <= 0;
        this.changeRef.markForCheck();
      });

      this._dateRange = null;
      this._filters = [];
      this._perspective.filters = [];
    }
  }

  cleanForm() {
    this.resetForm();
    this.changeRef.markForCheck();
  }

  private resetForm() {
    this._cnpFormControl.setValue('');
    this._nfseNumberFormControl.setValue('');
    this._ourNumberFormControl.setValue('');
    this._nfseStatusFormControl.setValue(false);
    this._dateIntervalTypeFormControl.setValue('');
    this._dateRangeFormControl.get('start').setValue('');
    this._dateRangeFormControl.get('end').setValue('');
  }

  paginate(pagingEvent: PageEvent) {
    pagingEvent.pageIndex = pagingEvent.pageIndex * pagingEvent.pageSize;
    this._currentPage = new PageData(pagingEvent.pageIndex, pagingEvent.pageSize);
    this.loadClifors();
    this.changeRef.markForCheck();
  }

  isPipeNotNeeded(column: TableColumn): boolean {
    return this._columnsWithPipe.every(columnWithpipe => !this.verifyColumnName(column, columnWithpipe));
  }

  verifyColumnName(column: TableColumn, index: number): boolean {
    return column?.name === this.columns[index]?.name;
  }

  currencyColumns(column: TableColumn): boolean {
    return !this._currencyColumns.every(currencyColumn => !this.verifyColumnName(column, currencyColumn));
  }

  compareDateType(option: DateTypeOption, selectedOption: DateTypeOption) {
    return option?.value === selectedOption?.value;
  }

  trackByDateType(index: any, dateTypeoption: IntervalInvoiceType) {
    return dateTypeoption.value;
  }

  trackByColumn(index: any, entity: TableColumn) {
    return entity.name;
  }

  trackByOption(index: any, option: EntityFabDialOption) {
    return option.label;
  }

  onOpenFabChange(event: boolean) {
    this._fabOpen = event;
  }

  downloadDocuments(option: EntityFabDialOption) {
    switch (option.value) {
      case this.CLIFOR: return this.downloadClifors();
      case this.FINANCIAL_MOVEMENT: return this.downloadFinancialMovements();
      default: throw Error("Opção inválida")
    }
  }

  async downloadFinancialMovements() {
    const financialMovementLabel = "Movimentações Financeiras";
    await lastValueFrom(this.repository.downloadFinancialMovements())
      .then(
        financialMovement => saveAs(financialMovement, financialMovementLabel),
        error => this.showSnackbarMessage(financialMovementLabel)
      );
  }

  async downloadFinancialMovementBy(invoiceDataClifor: InvoiceDataClifor) {
    const financialMovementLabel = `Movimentação Financeira - ${invoiceDataClifor.formattedCnp}`;
    await lastValueFrom(this.repository.downloadFinancialMovementBy(invoiceDataClifor.idInvoice))
      .then(
        financialMovement => saveAs(financialMovement, financialMovementLabel),
        error => this.showSnackbarMessage(financialMovementLabel));
  }

  async downloadClifors() {
    await lastValueFrom(this.repository.downloadClifors())
      .then(
        cliforTxt => saveAs(cliforTxt, this.CLIFOR),
        error => this.showSnackbarMessage(this.CLIFOR));
  }

  async downloadCliforBy(invoiceDataClifor: InvoiceDataClifor) {
    const clifor = `${this.CLIFOR} - ${invoiceDataClifor.cliforCode}`;
    await lastValueFrom(this.repository.downloadCliforBy(invoiceDataClifor.idClient))
      .then(
        cliforTxt => saveAs(cliforTxt, clifor),
        error => this.showSnackbarMessage(clifor)
      );
  }

  async searchClifors(event: SimpleEntityListPerspective) {
    this._perspective = event;
    this.loadClifors();
  }

  private showSnackbarMessage(message: string) {
    this.snackbar.open(`Não foi possível baixar - ${message}`, null, { duration: 4000 });
  }

}

interface TableColumn {
  name: string,
  label: string
}

interface DateTypeOption {
  label: string,
  value: string
}

interface DateRangeFilter {
  start: Moment,
  end: Moment,
}

interface DateRangeInterval {
  intervalStart: string,
  intervalEnd: string,
  intervalType: string
}