import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { InvoiceDetails, LegalEntityDocuments } from '@financial/domain';

@Component({
  selector: 'app-invoice-clients-content',
  templateUrl: './invoice-clients-content.component.html',
  styleUrls: ['./invoice-clients-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceClientsContentComponent implements OnInit {

  @Input() entity: InvoiceDetails

  private _phone: string;

  private _legalEntityDocument: LegalEntityDocuments;

  constructor() { }

  get ie() {
    return this._legalEntityDocument.ie;
  }

  get phoneNumber() {
    return this._phone;
  }

  ngOnInit(): void {
    this._phone = this.entity.client.lastPhoneNumber(this.entity.client.phones);
    if (this.entity.client.documents.isLegalPerson) {
      this._legalEntityDocument = this.entity.client.documents as LegalEntityDocuments
    }
  }

}
