import { ItemsClientComponent } from './items-client/items-client.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { CancelBankslipDialogComponent } from './invoice-actions/cancel-bankslip-dialog/cancel-bankslip-dialog.component';
import { InvoiceActionsComponent } from './invoice-actions/invoice-actions.component';
import { ItemsClientTableComponent } from './invoice-actions/items-client-table/items-client-table.component';
import { InvoiceReportComponent } from './invoice-report/invoice-report.component';
import { InvoiceClientsContentComponent } from './invoices-details-dialog/invoice-clients-content/invoice-clients-content.component';
import { InvoiceDetailsDialogBillableContentComponent } from './invoices-details-dialog/invoice-details-dialog-billable-content/invoice-details-dialog-billable-content.component';
import { InvoiceDetailsDialogPrintTableComponent } from './invoices-details-dialog/invoice-details-dialog-print-table/invoice-details-dialog-print-table.component';
import { InvoiceDetailsDialogViewTableFormComponent } from './invoices-details-dialog/invoice-details-dialog-view-table-form/invoice-details-dialog-view-table-form.component';
import { InvoiceDetailsDialogComponent } from './invoices-details-dialog/invoice-details-dialog.component';
import { InvoicesComponent } from './invoices.component';
import { InvoicesRoutes } from './invoices.routes';
import { InvoiceTotalPrintTableComponent } from './invoices-details-dialog/invoice-total-print-table/invoice-total-print-table.component';
import { InvoicesReleasedTableComponent } from './invoices-released-table/invoices-released-table.component';
import { ClientsWithOverdueInvoices } from './clients-with-overdue-or-partially-paid-invoices/clients-with-overdue-or-partially-paid-invoices.component';
import { OverdueInvoicesTableComponent } from './overdue-invoices-table/overdue-invoices-table.component';
import { UpdateDiscountDialogComponent } from './invoice-actions/items-client-table/update-discount-dialog/update-discount-dialog.component';
import { InvoicesExportTableComponent } from './invoices-export-table/invoices-export-table.component';
import { RegenerateBankSlipDialogComponent } from './regenerate-bank-slip-dialog/regenerate-bank-slip-dialog.component';

@NgModule({
  imports: [CommonModule, SharedModule, InvoicesRoutes],
  declarations: [
    InvoicesComponent,
    InvoiceDetailsDialogComponent,
    InvoiceDetailsDialogPrintTableComponent,
    InvoiceDetailsDialogViewTableFormComponent,
    InvoiceDetailsDialogBillableContentComponent,
    InvoiceActionsComponent,
    CancelBankslipDialogComponent,
    ItemsClientTableComponent,
    InvoiceReportComponent,
    InvoiceClientsContentComponent,
    InvoiceTotalPrintTableComponent,
    ItemsClientComponent,
    InvoicesReleasedTableComponent,
    ClientsWithOverdueInvoices,
    OverdueInvoicesTableComponent,
    UpdateDiscountDialogComponent,
    InvoicesExportTableComponent,
    RegenerateBankSlipDialogComponent
  ]
})
export class InvoicesModule { }
