import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Actives, Filter, FilterOp } from '@financial/arch';
import { ConfirmDialogComponent, EntityCrudState } from '@financial/common-components';
import { FinancialValidators } from '@financial/common-utils';
import {
  Client,
  ClientsRepository,
  CNP,
  CnpInformations,
  EceosClientsRepository,
  financialPersonValidators,
  LegalEntityDocuments,
  NaturalPersonDocuments
} from '@financial/domain';
import { lastValueFrom } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-clients-details',
  templateUrl: './clients-details.component.html',
  styleUrls: ['./clients-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientsDetailsComponent {

  form: FormGroup;

  hasChargingFlow = false;

  @Output() entityChange = new EventEmitter<Client>();

  @Output() submit = new EventEmitter<Client>();

  @Output() validChange = new EventEmitter<boolean>();

  @Output() dirtyChange = new EventEmitter<boolean>();

  @Output() clientChange = new EventEmitter<Client>();

  private _entity: Client;

  private _state: EntityCrudState;

  private _valid = true;

  private _dirty = false;

  private currentCnp: CnpInformations;

  constructor(
    private fb: FormBuilder,
    private ref: ChangeDetectorRef,
    private personValidator: financialPersonValidators,
    public clientsRepository: ClientsRepository,
    private dialog: MatDialog,
    public eceosClientsRepository: EceosClientsRepository
  ) { }

  @Input()
  set entity(entity: Client) {
    if (this._entity === entity) {
      return;
    }

    this.currentCnp = new CnpInformations(this._entity?.documents?.cnp);
    this._entity = entity;
    this.createFormFromEntity();
    this.setEceosClient();
  }

  get entity() {
    return this._entity;
  }

  @Input()
  set state(value: EntityCrudState) {
    this._state = value;
    this.syncFromState();
  }

  get isNaturalPerson(): boolean {
    return this.entity && this.entity.isNaturalPerson;
  }

  get isLegalEntity(): boolean {
    return this.entity && this.entity.isLegalEntity;
  }

  get state(): EntityCrudState {
    return this._state;
  }

  get codFunapeMessage(): string {
    if (this._state === EntityCrudState.INSERT) {
      return "O 'Código Funape' será gerado automáticamente ao salvar";
    }
  }

  private get eceosClientControl(): AbstractControl {
    return this.form.get('eceosClient');
  }

  async setEceosClient() {
    if (this._entity?.eceosClientId) {
      this.eceosClientControl.setValue(
        await lastValueFrom(this.eceosClientsRepository.find(this._entity.eceosClientId))
      );
      this.dirtyChange.emit((this._dirty = false));
    }
  }

  addFormValueChange(mapper: (f: any) => Client) {
    this.form.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(300), map(mapper))
      .subscribe((value) => {
        this.entityChange.emit((this._entity = value));
      });
  }

  onSubmit() {
    this.submit.emit(this.entity);
  }

  async onInvalidCnp(event: FocusEvent) {
    const input = event?.target as HTMLInputElement;
    const value = input?.value.replace(/\D/g, '');
    const isValid = new CNP(value).isValid;

    if (isValid && value !== this.currentCnp.initialCnpNumber) {
      this.currentCnp.updateCnpType(value);
      const clients = await lastValueFrom(
        this.clientsRepository.listFull(
          '',
          [new Filter(`documents.${this.currentCnp.currentCnpType}`, FilterOp.EQ, value)],
          [],
          Actives.ALL
        )
      );
      if (clients.length > 0) {
        const client = clients[0];
        if (!client.isActive) {
          this.activateDialog(client);
        } else if (client.isActive) {
          this.nagivateToClientDialog(client);
        }
      }
    }
  }

  private createFormFromEntity() {
    this.form = null;
    this.ref.detectChanges();
    if (this.entity) {
      const baseForm = {
        name: [this.entity.name, Validators.required],
        phones: [this.entity.phones],
        emails: [this.entity.emails],
        address: [this.entity.address],
        eceosClient: [this.entity.eceosClient, Validators.required],
        cliforCode: [this.entity.cliforCode, Validators.required],
        generateBankSlip: [this.entity.generateBankSlip]
      };
      if (this.isNaturalPerson) {
        const npDocs = this.entity.documents as NaturalPersonDocuments;
        this.form = this.fb.group({
          ...baseForm,
          code: [this.entity.code, Validators.required],
          cpf: [
            npDocs.cpf,
            {
              validators: [FinancialValidators.cpf, Validators.required],
              asyncValidators: [
                financialPersonValidators
                  .cnpExists(this.clientsRepository, this.currentCnp)
                  .bind(this.personValidator)
              ],
              updateOn: 'blur'
            }
          ],
          rg: [npDocs.rg]
        });
        this.addFormValueChange(
          (client) =>
            new Client(
              this._entity.id,
              client.name,
              client.code,
              new NaturalPersonDocuments(client.cpf, client.rg),
              client.address,
              client.emails,
              client.phones,
              client.eceosClient,
              this._entity.version,
              this._entity.isActive,
              client.cliforCode,
              client.generateBankSlip
            )
        );
      } else if (this.isLegalEntity) {
        const leDocs = this.entity.documents as LegalEntityDocuments;
        this.form = this.fb.group({
          ...baseForm,
          code: [this.entity.code, Validators.required],
          corporateName: [leDocs.corporateName, Validators.required],
          cnpj: [
            leDocs.cnpj,
            {
              validators: [FinancialValidators.cnpj, Validators.required],
              asyncValidators: [
                financialPersonValidators
                  .cnpExists(this.clientsRepository, this.currentCnp)
                  .bind(this.personValidator)
              ],
              updateOn: 'blur'
            }
          ],
          ie: [leDocs.ie, FinancialValidators.ie],
          im: [leDocs.im]
        });
        this.addFormValueChange(
          (client) =>
            new Client(
              this._entity.id,
              client.name,
              client.code,
              new LegalEntityDocuments(client.cnpj, client.corporateName, client.ie, client.im),
              client.address,
              client.emails,
              client.phones,
              client.eceosClient,
              this._entity.version,
              this._entity.isActive,
              client.cliforCode,
              client.generateBankSlip
            )
        );
      }
    }
    if (this.form) {
      this.form.statusChanges.subscribe((s: any) => {
        if (this.form.dirty !== this._dirty) {
          this.dirtyChange.emit((this._dirty = this.form.dirty));
        }
        if (this.form.valid !== this._valid) {
          this.validChange.emit((this._valid = this.form.valid));
        }
      });
    }
    this.syncFromState();
  }

  private syncFromState() {
    if (this.form) {
      if (this._state === EntityCrudState.INSERT) {
        this.form.enable();
        this.form.controls['cliforCode'].disable();
      } else if (this._state === EntityCrudState.EDIT) {
        this.form.enable();
      } else {
        this.form.disable();
      }
    }
  }

  private async nagivateToClientDialog(client: Client) {
    if (client) {
      const accept = await lastValueFrom(
        this.dialog
          .open(ConfirmDialogComponent, {
            data: {
              title: 'Cliente já cadastrado',
              content: `Existe um cliente com o ${this.currentCnp.currentCnpType.toLocaleUpperCase()} ${
                client.documents.maskedCnp
              }. Deseja abrir o registro?`,
              confirm: 'Abrir registro'
            }
          })
          .afterClosed()
      );
      if (accept) {
        await this.navigateToRegister(client);
      }
    }
  }

  private async activateDialog(client: Client) {
    if (client) {
      const accept = await lastValueFrom(
        this.dialog
          .open(ConfirmDialogComponent, {
            data: {
              title: 'Cliente já cadastrado',
              content: `Existe um cliente inativo com o ${this.currentCnp.currentCnpType.toLocaleUpperCase()} ${
                client.documents.maskedCnp
              }. Deseja ativar o registro?`,
              confirm: 'Reativar cliente'
            }
          })
          .afterClosed()
      );
      if (accept) {
        const updatedClient = await lastValueFrom(this.clientsRepository.activate(client));
        await this.navigateToRegister(updatedClient);
      }
    }
  }

  private async navigateToRegister(client: Client) {
    this.clientChange.emit(client);
  }

}
